<template>
    <v-banner class="v-banner-alert rounded" :class="customClass">
        <h6 v-if="title" class="subtitle-2 font-weight-light">
            <span class="b-title">{{ title }}</span>
        </h6>
        <p class="caption">
            {{ text }}
        </p>
    </v-banner>
</template>

<script>
  export default {
    name: 'Banner',

    props: {
      title: String,
      text: String,
      customClass: {
        type: String,
        default: null
      }
    }
  }
</script>