export default class Key {
  constructor({ id = null, name = '', filterable= 1, icons = null, created = null, updated = null }) {
    this.id = id || null
    this.name = {
      en: name.en || '',
      pt: name.pt || '-'
    }
    this.filterable = filterable || 0
    this.icons = icons || ''
    this.created = created || ''
    this.updated = updated || ''
  }
}
