import axios from 'axios'
import { getFormData, getFormDataMultidimensional } from '@/utils/utils'

export default {
    getWhoWeAre(params) {
      return axios.get('/whoWeAre', {
        params
      })
    },
    saveWhoWeAre(params) {
      return axios.post(`/whoWeAre/update`, getFormDataMultidimensional(params.data),
        {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }
      )
    },
}