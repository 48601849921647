<template>
    <v-card class="v-card__user" flat>
        <div class="v-card__user-header">
            <div class="about pb-5">
                <v-avatar width="75" height="75" class="mt-6">
                    <img v-if="getSrc != null" :src="$mediaFile('users', getID, getSrc)"
                          :lazy-src="$mediaFile('users', getID, getSrc)"/>
                    
                    <v-img v-else
                            :src="`https://eu.ui-avatars.com/api/?font-size=0.25&bold=true&name=`+getInitials"
                            :lazy-src="`https://eu.ui-avatars.com/api/?font-size=0.25&bold=true&name=`+getInitials"
                    />
                </v-avatar>
                <p class="font-weight-medium black--text mt-3 mb-0 myPrincipalCaption">{{ getName }}</p>
                <p class="mb-0 myCaption">{{ getEmail }}</p>
            </div>
        </div>
        <v-card-actions class="light-bg py-6 px-0">
            <v-btn depressed color="secondary" form="type-form" to="/inbox" class="w-full">{{$t('DASHBOARD.VIEW_MESSAGES')}}
            </v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
  import { User } from '@/store/structs/user'
  export default {
    name: 'UserWelcome',
    data() {
      return {
        activeUser: User,
        user: {
          initials: 'LA',
          fullName: 'John Doe',
          email: 'john.doe@doe.com'
        }
      }
    },
    created(){
      this.activeUser = new User(this.$store.state.auth.user)
      //console.log(this.activeUser)
    },
    computed:{
      getName(){
        return this.activeUser.name
      },
      getEmail(){
        return this.activeUser.email
      },
      getInitials(){
        return this.activeUser.initials
      },
      getSrc(){
        return this.activeUser.avatar
      },
      getID(){
        return this.activeUser.id
      }
    },
  }
</script>
