<template>
    <v-toolbar-items>

      
      <VueCustomTooltip  :label="$t('TOOLTIPS.HELP_BUTTON')" position="is-top">
        <v-btn to="/instructionGuide"
            icon>
            <v-icon
                color="primary"
                class="ml-6 btn__avatar"
              >
                mdi-help
            </v-icon>  
        </v-btn>
      </VueCustomTooltip> 

        <LocaleChanger v-if="true" @language-changed="$emit('language-changed')"/>
        

        <Avatar />
     

        <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
                <v-btn
                        icon
                        v-bind="attrs"
                        v-on="on"
                        class="ml-4"
                        @click="userLogout"
                >
                    <i class="zwicon-sign-in"></i>
                </v-btn>
            </template>
        </v-menu>

    </v-toolbar-items>
</template>

<script>
  import Avatar from '@/components/core/toolbar/Avatar'
  import LocaleChanger from '@/components/core/LocaleChanger'
  export default {
    name: 'Items',

    components: { Avatar, LocaleChanger },

    methods: {
      userLogout() {
        this.$store.dispatch('userLogout')
      }
    }
  }
</script>
