<template>
    <div class="v-files-list">
        <v-row>
            <v-col
                    class="d-flex child-flex"
                    :cols="12"
            >
                <v-img                    
                        :src="$mediaFile(location, ID , myFile.file)"
                        :lazy-src="$mediaFile(location, ID, myFile.file)"
                        aspect-ratio="1"
                        class="grey darken-2 rounded"
                >
                    <template v-slot:default>
                        <div class="v-files-list-info">
                            <div class="header">
                                <!-- Information -->
                                <div class="pa-3 d-flex justify-space-between">
                                    <div>
                                        <p class="text-left white--text pb-0 mb-0"
                                           :class="'body-2'">{{ myFile.file }}</p>
                                        <p class="text-left" :class="'body-2'">
                                            <small class="grey--text darken-2">{{ myFile.extension }}</small>
                                        </p>
                                    </div>
                                    <div>
                                        <v-chip color="secondary" label>
                                            Cover
                                        </v-chip>
                                    </div>                                    
                                </div>
                                <!-- end -->
                                <!-- Overlay -->
                                <ImageOverlay dir="header"/>
                                <!-- end -->
                            </div>
                            <div class="footer pa-5">
                                <!-- Actions -->
                                <v-btn @click="launchDialog(true, location, myFile.file, myFile.id)" class="edit pa-0 mr-1">
                                    <i class="zwicon-pencil white--text"></i>
                                </v-btn>
                                <v-btn class="remove pa-0 ml-1" @click="removeImage(location, myFile.file, myFile.id)">
                                    <i class="zwicon-trash white--text"></i>
                                </v-btn>
                                <!-- end -->
                            </div>
                        </div>
                    </template>
                    <template v-slot:placeholder>
                        <v-row
                                class="fill-height ma-0"
                                align="center"
                                justify="center"
                        >
                            <v-progress-circular
                                    indeterminate
                                    color="grey lighten-5"
                            ></v-progress-circular>
                        </v-row>
                    </template>
                </v-img>
            </v-col>
        </v-row>

        <ImageEditor @generated-file="saveFileGenerated" v-model="dialogStatus" :image-data="selectedImage"></ImageEditor>

    </div>
</template>

<script>
  import ImageOverlay from '@/components/media/ImageOverlay'
  import '@/plugins/media'

  export default {
    name: 'CoverImg',

    components: {
      ImageOverlay
    },
    props: ['coverFile', 'ID', 'location'],

    data: () => ({
      dialogStatus: false,
      selectedImage: null,
      imageName: null,
      mediaID: null
    }),
       created() {
    },

    methods: {
      launchDialog(mode, path, file, media_id) {
        this.dialogStatus = mode
        this.imageName = file
        this.mediaID = media_id
        this.selectedImage = `${process.env.VUE_APP_MEDIA_URL}/` + path + `/` + this.$route.params.slug +`/`+file;
      },
      saveFileGenerated(file){
        this.$emit('generated-file', file, this.ID, this.mediaID, this.imageName)  
      },
      makePhotoAsCover(location, file, media_id){
        this.$emit('change-cover', file, this.ID, media_id, this.imageName) 
      },
      removeImage(location, fileName, media_id){
        this.$emit('remove-image', location, fileName, media_id) 
      }
    },
    computed: {
        myFile() {
            return this.coverFile
        }
    }

  }
</script>