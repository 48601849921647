function screenWidth() {
    let sw = screen.width
    let result = 'xl'
    if(sw < 600)
        result = 'xs';
    else if(sw >= 600 && sw < 960)
        result = 'sm'
    else if(sw >= 960 && sw < 1624)
        result = 'md'
    else if(sw >1264 && sw < 1904)
        result = 'lg'
    else
        result = 'xl'

    

    return result
  }
  
  export default screenWidth