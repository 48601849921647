export default [
    {
      path: '/users',
      name: 'Users',
      component: () => import('@/pages/Users'),
      meta: {
        layout: 'main',
        requiresAuth: true,
      },
      children: [
        {
          path: '/users/myprofile',
          name: 'AgentsListings',
          meta: {
            title: 'YOUR PROFILE',
            subtitle: 'Edit your profile',
            fluid: true
          },
          component: () =>
            import(/* webpackChunkName: "home" */ '@/pages/users/SetUser')
        },
      ]
    }
  ]
  