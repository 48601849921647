import { mapActions } from 'vuex'

const mixin = {

  created: function() {
    console.debug(`Properties action called.`)
  },

  methods: {
    ...mapActions(['propertyQuickChange', 'attributePlant', 'removingAllImages', 'removePermanentlyProperty', 'reorderImageProperty', 'removeMapMarkerProperty', 'saveMapMarkersProperty', 'unArchiveOneProperty', 'archiveOneProperty', 'getPropertyTags', 'removeImageProperty', 'changeCoverImageProperty', 'saveFileGeneratedProperty', 'saveMediaFilesProperty', 'removeAttachmentProperty', 'saveAttachmentProperty', 'getSelectListCondominiums', 'getPropertiesFromCondominium', 'getProperties','getPropertyBlock', 'editProperty', 'saveProperty', 'savePropertyBlock']),

    async save(form) {
      try {
        // Updating item
        if (form.id !== null) {
          await this.editProperty({ 'data': form })
          // await this.getAgents()
        } else {
          // Creating new item
          await this.saveProperty({ 'data': form })
          // await this.getAgents()
        }
      } catch (error) {
        this.dataTableLoading = false
      }
    },

    async saveBlock(form, block) {
      try {
        // Updating item
        await this.savePropertyBlock({ 'data': form, 'block': block })
      } catch (error) {
        this.dataTableLoading = false
      }
    },
    async saveMapMarkers(id, data, gmapRoutes){
      try{
          // SAVE MARKERS
          await this.saveMapMarkersProperty({'id': id, 'data': data, 'gmapRoutes': gmapRoutes})
        }catch(error){
          this.dataTableLoading = false
        }
    },
    async removeMapMarker(id, condo_id){
      try{
          // SAVE MARKERS
          await this.removeMapMarkerProperty({'id': id, 'condo_id': condo_id})
        }catch(error){
          this.dataTableLoading = false
        }
    },
    async reorderImage(list){
      try{
        // SAVE MARKERS
        await this.reorderImageProperty({'list': list})
      }catch(error){
        this.dataTableLoading = false
      }
    },
    
    async saveAttachmentFromProperty(form, block){
      
      try{
      // Change Cover
        await this.saveAttachmentProperty({'data': form})
      }catch(error){
        this.dataTableLoading = false
      }
    },
    async removeAttachmentFromProperty(form, block){
      
      try{
        // Change Cover
        await this.removeAttachmentProperty({'data': form})
      }catch(error){
        this.dataTableLoading = false
      }
    },
    async saveMediaFilesFromProperty(form, block){
      
      try{
        // Change Cover
        await this.saveMediaFilesProperty({'data': form})
      }catch(error){
        this.dataTableLoading = false
      }
    },
    async saveFileGeneratedFromProperty(form, block){
      
      try{
        // Change Cover
        await this.saveFileGeneratedProperty({'data': form})
      }catch(error){
        this.dataTableLoading = false
      }
    },

    async changeCoverImage(form, block){
      try{
        // Change Cover
        await this.changeCoverImageProperty({'data': form})
      }catch(error){
        this.dataTableLoading = false
      }
    },
    async removeImageFromProperty(form, block){
      try{
        // Change Cover
        await this.removeImageProperty({'data': form})
      }catch(error){
        this.dataTableLoading = false
      }
    },
    async removeAllImagesFromProperty(id){
      try{
        // Delete all images
        await this.removingAllImages({'id': id})
      }catch(error){
        this.dataTableLoading = false
      }
    },
    async archiveProperty(id){
      try{
        await this.archiveOneProperty({'data': id})
      }catch(error){
        this.dataTableLoading = false
      }
    },
    async unArchiveProperty(id){
      try{
        await this.unArchiveOneProperty({'data': id})
      }catch(error){
        this.dataTableLoading = false
      }
    },
    async deleteProperty(id){
      try{
        await this.removePermanentlyProperty({'data': id})
      }catch(error){
        console.log(error)
        this.dataTableLoading = false
      }
    }
    


    
  }
}

export default mixin