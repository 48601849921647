<template>
    <v-expansion-panels flat class="my-7" :disabled="disabled">
        <v-expansion-panel>
            <v-expansion-panel-header class="v-expansion-panel-header-box">
                <div class="d-block">
                    <v-card-title class="subtitle-2 font-weight-bold pa-0 pb-4">{{$t('DEVELOPMENTS.MANAGEMENT')}}</v-card-title>
                    <v-card-subtitle class="caption font-weight-light text-left mb-0 pa-0" :class="disabled ? 'darkgray--text' : 'black--text'">{{$t('DEVELOPMENTS.MANAGE_AGENT')}}
                    </v-card-subtitle>
                </div>

            </v-expansion-panel-header>
            <v-expansion-panel-content class="v-expansion-panel-body-box">
                <v-item-group active-class="primary">

                    <v-progress-circular v-if="dataLoading"
                                         indeterminate
                                         color="primary"
                    ></v-progress-circular>

                    <v-row>
                        <v-col
                                v-for="(agent, index) in items" :key="index"
                                cols="12"
                                md="4"
                                @click="selectAgent(parseInt(agent.id))"
                        >
                            <v-item v-slot="{ active, toggle }">
                                <v-card
                                        class="d-flex align-center flex-column justify-center v-card__team"
                                        :style="selected === parseInt(agent.id) ? 'background-color: #7289DA !important;' : 'background-color: #f7f7f7 !important;'"
                                        dark
                                        flat
                                        height="200"
                                        @click="toggle"
                                        :class="selected === parseInt(agent.id) ? 'primary' : ''"
                                >
                                    <span>&nbsp;</span>
                                    <!-- Basic Information -->
                                    <v-avatar width="70" height="70">
                                        <v-img
                                                :src="$mediaFile('agents', agent.id, agent.avatar)"
                                                :lazy-src="$mediaFile('agents', agent.id, agent.avatar)"
                                        />
                                    </v-avatar>

                                    <v-card-title class="subtitle-2 justify-center font-weight-light text-uppercase black--text">
                                        {{ agent.name }}
                                    </v-card-title>
                                    <v-card-subtitle class="caption justify-center font-weight-light pb-3 black--text">
                                        {{ agent.email }}
                                    </v-card-subtitle>
                                    <!-- end -->
                                    <!-- Properties Number -->
                                    <v-chip
                                            class="ma-1"
                                            small
                                            :color="active ? 'white' : 'primary'"
                                            :class="active ? 'black--text' : ''"
                                    >
                                       {{agent.total_properties}} {{$t('AGENTS.PROPERTIES')}}
                                    </v-chip>
                                    <!-- end -->
                                </v-card>
                            </v-item>
                        </v-col>
                    </v-row>
                </v-item-group>
            </v-expansion-panel-content>
        </v-expansion-panel>
    </v-expansion-panels>
</template>

<script>
  import mixin from '@/models/agents/AgentsModel'
  import { Agent } from '@/store/structs/agent'

  export default {
    name: 'Agents',

    mixins: [mixin],

    props: {
      form: [String, Number],
      disabled: {
        type: Boolean,
        default: false
      }
    },

    data: () => ({
      dataLoading: true,
      selected: null,
      agentLoaded: false
    }),

    watch: {
      form(val) {
        this.selected = val;
      },
      agents: {
        async handler() {
          try {
            this.dataLoading = true
            await this.getAgents()
            this.dataLoading = false
          } catch (error) {
            this.dataLoading = false
          }
        },
        immediate: true,
        deep: true
      },
      selected(val) {
        if(this.agentLoaded)
          this.$emit('form-agents', val)
        else
          this.agentLoaded = true
      }
    },

    computed: {
      items() {
        let retrievedData = this.$store.state.agentsModule.list
        return Object.keys(retrievedData).map(k =>
          new Agent(retrievedData[k])
        )
      }
    },
    methods:{
      selectAgent(id){
        if(this.selected == parseInt(id))
          this.selected = null
        else{
          this.agentLoaded = true
          this.selected = parseInt(id)
        }
      }
    }

  }
</script>
