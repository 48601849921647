import * as types from '@/store/mutation-types'
import api from '@/services/api/administration/administration'
import { buildSuccess, handleError } from '@/utils/utils.js'

const getters = {
    csv_contacts: (state) => state.csv_contacts,
}

const actions = {
  getCSV({ commit }, payload) {
    return new Promise((resolve, reject) => {
      api
        .getCSV(payload)
        .then((response) => {
          if (response.status === 200) {
          console.log(response)
            commit(types.CSV_CONTACTS, response.data.csv_contacts)
            resolve()
          }
        })
        .catch((error) => {
          handleError(error, commit, reject)
        })
    })
  }
}

const mutations = {
  [types.CSV_CONTACTS](state, csv_contacts) {
    state.csv_contacts = csv_contacts
  }
}

const state = {
  csv_contacts: []
}

export default {
  state,
  getters,
  actions,
  mutations
}