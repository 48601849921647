<template>
    <v-window
            v-model="window"
            vertical
    >
    
        <v-window-item
                v-for="language in languages"
                :key="language.slug"
        >           

            
            <!-- Title -->
             <div 
              v-bind:style="language.slug=='pt' ? 'border-color:#7289da;border-top-style:solid;border-radius: 5px;' : ''">
            <input-text
                    name="title"
                    :label="$t('DEVELOPMENTS.TITLE')"
                    id="title"
                    v-model="formData.title[language.slug]" :fill="form.title[language.slug]"
                    @blur="onBlurAction"
            >
            </input-text>
            </div>
            <!-- end -->

            <!-- Slogan -->
             <div v-show="false"
              v-bind:style="language.slug=='pt' ? 'border-color:#7289da;border-top-style:solid;border-radius: 5px;' : ''">
            <input-text
                    name="slogan"
                    label="New development slogan"
                    id="slogan"
                    v-model="formData.slogan[language.slug]" :fill="form.slogan[language.slug]"
                    @blur="onBlurAction"
            >
            </input-text>
            </div>
            <!-- end -->

            <!-- Description -->
             <div 
              v-bind:style="language.slug=='pt' ? 'border-color:#7289da;border-top-style:solid;border-radius: 5px;' : ''">
            <input-textarea
                    name="description"
                    :label="$t('DEVELOPMENTS.DESCRIPTION')"
                    :rows="6"
                    class="pb-1"
                    v-model="formData.description[language.slug]" :fill="form.description[language.slug]"
                    @blur="onBlurAction"
            >
            </input-textarea>
            </div>
            <!-- end -->

        </v-window-item>
    </v-window>
</template>

<script>
  import { CondominiumForm } from '@/store/structs/condominium'

  export default {
    name: 'CondominiumsTranslatable',

    props: {
      locale: {
        type: Number,
        default: 0
      },
      form: {}
    },

    data: () => ({
      formData: {},
      window: 0,
      languages: [
        {
          name: 'English',
          slug: 'en'
        },
        {
          name: 'Portuguese',
          slug: 'pt'
        }
      ]
    }),

    created() {
      this.formData = new CondominiumForm({}).i18n
    },
    methods:{
      onBlurAction(){
        this.$emit('blur')
      }
    },

    watch: {
      form: {
        handler: function(val) {
          this.formData = val
        },
        deep: true
      },
      formData: {
        handler: function(val) {
          this.$emit('form-condominium-translatable', val)
        },
        deep: true
      },
      locale: function() {
        this.window = this.locale
      }
    },
  }
</script>