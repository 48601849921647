import api from '@/services/api/exporter/exporter'
import * as types from '@/store/mutation-types'
import { buildSuccess, handleError } from '@/utils/utils.js'

const getters = {
  exporters: (state) => state.imovirtualCode,
}

const actions = {  
  imovirtualGetAuthorizationCode({ commit }, payload) {
    return new Promise((resolve, reject) => {
      api
        .imovirtualGetAuthorizationCode(payload)
        .then((response) => {
          
          if (response.status === 201) {
            commit(types.IMOVIRTUAL_CODE, response.data.request_authorization_url)
            buildSuccess(
              {
                msg: 'common.SAVED_SUCCESSFULLY'
              },
              commit,
              resolve
            )
          }else{
            handleError(null, commit, reject)
          }

        })
        .catch((error) => {
          handleError(error, commit, reject)
        })
    })
  }, 
  exportToPlatform({ commit }, payload) {
      return new Promise((resolve, reject) => {
        api
          .exportToPlatform(payload)
          .then((response) => {
            //console.log(response)
            if(response.data.is_url_request)
              window.location.href = response.data.request_authorization_url

            if (response.status === 201) {
              buildSuccess(
                {
                  msg: 'common.SAVED_SUCCESSFULLY'
                },
                commit,
                resolve
              )
            }else{
              handleError(null, commit, reject)
            }

          })
          .catch((error) => {
            handleError(error, commit, reject)
          })
      })
    },    
    updateToPlatform({ commit }, payload) {
      return new Promise((resolve, reject) => {
        api
          .updateToPlatform(payload)
          .then((response) => {
            //console.log(response)
            if(response.data.is_url_request)
              window.location.href = response.data.request_authorization_url

            if (response.status === 201) {
              buildSuccess(
                {
                  msg: 'common.SAVED_SUCCESSFULLY'
                },
                commit,
                resolve
              )
            }else{
              handleError(null, commit, reject)
            }

          })
          .catch((error) => {
            handleError(error, commit, reject)
          })
      })
    }, 
    downloadErrorFile({ commit }, payload) {
      return new Promise((resolve, reject) => {
        api
          .downloadErrorFile(payload)
          .then((response) => {
            if (response.status === 200) {
              commit(types.EXPORTER_LOG, response.data)
              buildSuccess(
                {
                  msg: 'common.FILE_GENERATED'
                },
                commit,
                resolve
              )
            }else{
              handleError(null, commit, reject)
            }

          })
          .catch((error) => {
            handleError(error, commit, reject)
          })
      })
    }, 

    
      deleteFromPlatform({ commit }, payload) {
        return new Promise((resolve, reject) => {
          api
            .deleteFromPlatform(payload)
            .then((response) => {
              if (response.status === 201) {
                if(response.data.is_url_request)
                  window.location.href = response.data.request_authorization_url
                  
                buildSuccess(
                  {
                    msg: 'common.SAVED_SUCCESSFULLY'
                  },
                  commit,
                  resolve
                )
              }else{
                handleError(null, commit, reject)
              }

            })
            .catch((error) => {
              handleError(error, commit, reject)
            })
        })
      },  
      checkInsertion({ commit }, payload) {
        return new Promise((resolve, reject) => {
          api
            .checkInsertion(payload)
            .then((response) => {
              console.log("RESPOSTA")
              console.log(response)
              if (response.status === 201) {
                commit(types.CHECK_INSERTION, response.data.view_data.result)
                if(response.data.view_data.result == "error")
                  commit(types.INSERTION_LOG, response.data.view_data.log)
                  
                buildSuccess(
                  {
                    msg: 'common.SAVED_SUCCESSFULLY'
                  },
                  commit,
                  resolve
                )
              }else{
                handleError(null, commit, reject)
              }

            })
            .catch((error) => {
              handleError(error, commit, reject)
            })
        })
      },  
  }

  const mutations = {
    [types.IMOVIRTUAL_CODE](state, imovirtualCode) {
      state.imovirtualCode = imovirtualCode
    },
    [types.EXPORTER_LOG](state, exporter_log) {
      state.exporter_log = exporter_log
    },
    [types.CHECK_INSERTION](state, insertion) {
      state.insertion = insertion
    },
    [types.INSERTION_LOG](state, log) {
      state.log = log
    },
  }

  const state = {
    imovirtualCode: '',
    exporter_log: [],
    insertion: null,
    log: null
  }

export default {
  state,
  mutations,
  getters,
  actions
}