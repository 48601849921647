<template>
    <v-card flat class="pb-1">
        <v-card-title class="subtitle-2 font-weight-bold">{{$t('INSTRUCTIONS.Title')}}</v-card-title>
        <v-card-subtitle class="caption font-weight-light text-left mb-2 black--text">{{$t('INSTRUCTIONS.subTitle')}}</v-card-subtitle>

        <v-card
            class="mx-auto"
            :elevation="0"
            max-width="500"
        >
            <v-card-text>
                <v-treeview
                    :items="items"
                    activatable
                    rounded
                    hoverable
                    :open.sync="open"
                    @update:active="clickGuide"
                    open-on-click
                >
                    <template v-slot:prepend="{ item }" >
                        <v-icon
                            v-text="`mdi-${item.icon}`"
                        ></v-icon>
                    </template>
                </v-treeview>
            </v-card-text>
        </v-card>
    </v-card>
</template>

<script>
  export default {
    name: 'InstructionFilters',
    data(){
        return{
            open: []
      }
    },
    methods:{
        clickGuide(a){
            let getValue = a[0]

            
            
            this.$emit('change-guide', getValue)
        },
    },
    computed:{
        items(){
            let data = [
                        {
                            id: this.$t('INSTRUCTIONS.Title'), 
                            name: this.$t('INSTRUCTIONS.Title'),
                            icon: 'home-variant',
                            children: [
                                {
                                    id: this.$t('INSTRUCTIONS.Introduction'),
                                    name: this.$t('INSTRUCTIONS.Introduction'),
                                    icon: 'arrow-expand-right'
                                },
                                {
                                    id: this.$t('INSTRUCTIONS.Cache'),
                                    name: this.$t('INSTRUCTIONS.Cache'),
                                    icon: 'folder-network',
                                    children:[
                                        {
                                            id: 'Windows',
                                            name: "Windows",
                                            icon: "arrow-expand-right"
                                        },
                                        {
                                            id: 'Mac',
                                            name: "Mac",
                                            icon: "arrow-expand-right"
                                        }
                                    ]                        
                                },
                                {
                                    id: this.$t('INSTRUCTIONS.INSERT_PROPERTIES'),
                                    name: this.$t('INSTRUCTIONS.INSERT_PROPERTIES'),
                                    icon: 'folder-network',
                                    children:[
                                        {
                                            id: 'New Property',
                                            name: "New Property",
                                            icon: "arrow-expand-right"
                                        }
                                    ]                        
                                }
                            ]
                        }
                    ]
            return data
        }
    }
   
  }
</script>