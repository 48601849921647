<template>
    <v-file-input
            v-model="files"
            color="primary"
            counter
            :label="label"
            multiple
            :placeholder="placeholder"
            prepend-icon=""
            outlined
            :show-size="1000"
            :disabled="true"
    >
        <template v-slot:selection="{ index, text }">
            <v-chip
                    v-if="index < 2"
                    color="primary"
                    dark
                    label
                    small
            >
                {{ text }}
            </v-chip>
        </template>
    </v-file-input>
</template>

<script>
  export default {
    name: 'InputFile',

    props: {
      placeholder: {
        type: String,
        default: "File(s)"
      },
      label: {
        type: String,
        default: "Select your file(s)"
      }
    },

    data: () => ({
      files: [],
    })
  }
</script>
