<template>
    <file-pond
            name="test"
            ref="pond"
            :label-idle="$t('PROPERTIES.DROP_FILES')"
            allow-multiple="true"
            instantUpload="true" 
            allowImagePreview="true"   
            allowRevert="false"
            checkValidity="true"
            imagePreviewHeight="200"
            imageCropAspectRatio="1:1"
            imageResizeTargetWidth="200"
            imageResizeTargetHeight="200"
            allowFileSizeValidation="true"
            :maxFileSize="getMaxFileSize"
            :labelMaxFileSizeExceeded="$t('POND_DRAG.TOO_LARGE')"
            imageEditInstantEdit="false"
            v-on:init="handleFilePondInit"
            v-on:addfile="addFile"
            :server="this.server"
            v-bind:files="myFiles" class="inside-attachments"
    />
</template>
<script>
  // Import Vue FilePond
  import vueFilePond from 'vue-filepond'

  // Import FilePond styles
  import 'filepond/dist/filepond.min.css'
  import '@/assets/scss/plugins/file.pond.css'

  // Import image preview plugin styles
  import 'filepond-plugin-image-edit/dist/filepond-plugin-image-edit.css'
  import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css'

  // Import image preview and file type validation plugins
  import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type'
  import FilePondPluginImagePreview from 'filepond-plugin-image-preview'

  // Create component
  const FilePond = vueFilePond(
    FilePondPluginFileValidateType,
    FilePondPluginImagePreview
  )

 export default {
    name: 'BrowseFilesAll',

    components: {
      FilePond
    },
    methods: {
      handleFilePondInit: function () {
        // File pond init
      },
      async showAlertBox(iconType, message){
        let fileString = ''
        this.exceedFiles.forEach(element => {
          fileString += "- " + element + "\n\n"
        })
        await swal("Files Exceeded Limit (" + this.limitSize+ "MB)", message + fileString, iconType)
      },
      addFile: async function(){
        let e = this.$refs.pond.getFiles();
        if(this.quantityFilesExceedLimit() == e.length){
          await this.showAlertBox("error", "All files exceeded the limit\n\n")
          this.$emit('reload-me') 
        }

        this.$emit('files', e)        
      },
      quantityFilesExceedLimit(){
        let e = this.$refs.pond.getFiles();
        let counter = 0
        let exceedNames = []
        e.forEach(element => {
          if((element.file.size / 1000000) >= this.limitSize){
            counter++
            this.exceedFilesFlag = true
            exceedNames.push(element.filename)
            console.log(exceedNames)
          }
        });
        this.exceedFiles = exceedNames
        
        return counter
      }
    },
    computed:{
      getMaxFileSize(){
        return this.limitSize + 'MB'
      }
    },

    data: function() {
      return {
        myFiles: [],
        uploadedFiles: 0,
        limitSize: 2,
        exceedFiles: [],
        exceedFilesFlag: false,
        server:{
          process: async (fieldName, file, metadata, load, error, progress, abort) => {
            let stopLoading = false
            if(this.uploadedFiles == 0){
              this.uploadedFiles = this.quantityFilesExceedLimit()
            }
              
            this.uploadedFiles+=1
            
            if(this.uploadedFiles == this.$refs.pond.getFiles().length){
              this.uploadedFiles = 0
              stopLoading = true
              if(this.exceedFilesFlag)
                await this.showAlertBox("info", "There are files that exceeded the limit:\n\n")

              console.log(this.exceedFiles)
            }
            let res = await this.$emit('process-server', file, stopLoading) 
            
            if(res){
              load(file.name);
            }else{
              console.log("A problem occured");
            }
                      
          }       
        }
      }
    }
  }
</script>

