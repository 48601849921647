<template>
    <v-window
            v-model="window"
            vertical
    >
        <v-window-item
                v-for="language in languages"
                :key="language.slug"
                
        >


            <!-- Title -->
            <div 
              v-bind:style="language.slug=='pt' ? 'border-color:#7289da;border-top-style:solid;border-radius: 5px;' : ''">
              <input-text
                      name="title"
                      v-bind:label="$t('PROPERTIES.TITLE')"
                      id="title"
                      v-model="formData.title[language.slug]" :fill="form == undefined ? '' : form.title[language.slug]"
                      @blur="onBlurAction"
              >
              </input-text>
            </div>
            <!-- end -->
            

           

            <!-- Description -->
            <div 
              v-bind:style="language.slug=='pt' ? 'border-color:#7289da;border-top-style:solid;border-radius: 5px;' : ''">
              <input-textarea
                    name="description"
                    :label="$t('PROPERTIES.DESCRIPTION')"
                    :rows="6"
                    class="pb-1"
                    v-model="formData.description[language.slug]" :fill="form == undefined ? '' : form.description[language.slug]"
                    @blur="onBlurAction"
            >
            </input-textarea>
            </div>
            <!-- end -->
            
            
           <!-- Slogan last color #4CAF50 -->
            <div v-if="getCollectionPrivee==1 ? true : false" 
              v-bind:style="language.slug=='pt' ? 'border-color:#7289da;border-top-style:solid;border-radius: 5px;' : ''">
              <input-text
                    name="slogan"
                    v-bind:label="$t('PROPERTIES.SLOGAN')"
                    id="slogan"
                    v-model="formData.slogan[language.slug]" :fill="form.slogan[language.slug]"
                    @blur="onBlurAction"
            >
            </input-text>
            </div>
            <!-- end -->
        </v-window-item>
    </v-window>
</template>

<script>
  import { PropertyForm } from '@/store/structs/properties'

  export default {
    name: 'Translatable',

    props: {
      locale: {
        type: Number,
        default: 0,
      },
      form: {},
      formPrivee: {}
    },

    data: () => ({
      formData: {},
      toggleCollectionKey: 0,
      listTags: null,
      sloganShow: false,
      window: 0,
      languages: [
        {
          name: 'English',
          slug: 'en'
        },
        {
          name: 'Portuguese',
          slug: 'pt'
        }
      ]
    }),

    created() {
      this.formData = new PropertyForm({}).i18n
    },
    methods:{
      collectionToggle(e){
        this.sloganShow = e
        this.$emit('collection-toggle', e)
      },
      onBlurAction(){
        this.$emit('blur')
      }
    },
    watch: {
      form: {
        handler: function(val) {
          this.formData = val
          this.toggleCollectionKey++
        },
        deep: true
      },
      formData: {
        handler: function(val) {
          this.$emit('form-translatable', val)
        },
        deep: true
      },
      locale: function() {
        this.window = this.locale
      }
    },
    computed:{
      getCollectionPrivee(){
        return this.formPrivee.collection
      }
    }
  }
</script>