
const state = {
  // your options object is predefined in the store so Vue knows about its structure already
  options: {
    tab: 'general'
  }
}

const mutations = {
  // the mutation handler assigning the new value
  setIsCheck(state, payload) {
    state.options.tab = payload
  }
}

export default {
  state,
  mutations
}