<template>
    <v-expansion-panels flat :disabled="disabled">
        <v-expansion-panel>
            <v-expansion-panel-header class="v-expansion-panel-header-box">
                <div class="d-block">
                    <v-card-title class="subtitle-2 font-weight-bold pa-0 pb-4">{{$t('DEVELOPMENTS.SEARCH')}}</v-card-title>
                    <v-card-subtitle class="caption font-weight-light text-left mb-0 pa-0" :class="disabled ? 'darkgray--text' : 'black--text'">{{$t('DEVELOPMENTS.SOCIAL')}}
                    </v-card-subtitle>
                </div>
            </v-expansion-panel-header>
            <v-expansion-panel-content class="v-expansion-panel-body-box">
                <v-row>
                    <v-col cols="8" class="text-left">
                        <!-- Media Share -->
                        <label class="d-block v-label theme--light mb-4">{{$t('DEVELOPMENTS.MEDIA_SHARE')}}</label>
                        <input-text
                                name="youtube"
                                placeholder="Youtube ID"
                                id="youtube"
                                prefix="yt.com"
                                v-model="formData['youtube_id']" :fill="form['youtube_id']"
                                @blur="onBlurAction"
                        >
                        </input-text>
                        <input-text
                                name="website"
                                placeholder="Website URL"
                                id="website"
                                prefix="https://"
                                v-model="formData['website']" :fill="form['website']"
                                @blur="onBlurAction"
                        >
                        </input-text>
                        <!-- end -->
                        <!-- Locations -->
                        <label class="d-block v-label theme--light mb-2 font-weight-bold black--text">{{$t('DEVELOPMENTS.LOCATION_DISTRICTS')}}</label>
                        <v-item-group active-class="primary">

                            <v-progress-circular v-if="dataLoading"
                                                 indeterminate
                                                 color="primary"
                            ></v-progress-circular>

                            <v-row :key="reloadRow">
                                <v-col
                                        v-for="(district, index) in items"
                                        :key="index"
                                        cols="12"
                                        @click="processDistrictAction(district.id)"
                                >
                                    <v-item v-slot="{ toggle }">
                                        <v-card
                                                class="d-flex align-center"
                                                dark
                                                flat
                                                height="90"
                                                @click="toggle"
                                                :color="getformDistrict == parseInt(district.id) ? '#7289DA !important' : ''"
                                        >
                                            <v-img
                                                    :src="$mediaFile('districts', district.id, district.cover)"
                                                    :lazy-src="$mediaFile('districts', district.id, district.cover)"
                                                    height="82"
                                                    max-width="200"
                                                    class="rounded ml-1"
                                            ></v-img>
                                            <div class="pl-3 black--text">
                                                <v-card-title
                                                        class="subtitle-2 font-weight-bold text-uppercase text-left">
                                                    {{ district.name }}
                                                </v-card-title>
                                                <v-card-subtitle
                                                        class="caption justify-center font-weight-light text-left black--text">
                                                    {{ $processLanguage(district.description).substring(0, 100) }}...
                                                </v-card-subtitle>
                                                <!-- end -->
                                            </div>
                                        </v-card>
                                    </v-item>
                                </v-col>
                            </v-row>
                        </v-item-group>
                        <!-- end -->
                    </v-col>
                    <v-col cols="4" class="text-left pl-10">
                        <!-- Listings -->
                        <Listings @get-listings="getListings" :form="form['listing_id']"/>
                        <!-- end -->
                        <!-- Lifestyle -->
                        <Lifestyles @get-lifestyles="getLifestyles" :form="form['lifestyles_id']"/>
                        <!-- end -->
                    </v-col>
                </v-row>
            </v-expansion-panel-content>
        </v-expansion-panel>
    </v-expansion-panels>
</template>

<script>
  import Listings from '@/components/properties/form/general/Keys/Listings'
  import Lifestyles from '@/components/properties/form/general/Keys/Lifestyles'

  import { mapActions } from 'vuex'
  import { DistrictTranslated } from '@/store/structs/district'
  import { PropertyForm } from '@/store/structs/properties'

  export default {
    name: 'Search',

    props: {
      form: {},
      district: [String, Number],
      disabled: {
        type: Boolean,
        default: false
      }
    },

    components: { Lifestyles, Listings },

    data: () => ({
      dataLoading: true,
      formData: PropertyForm,
      formDistrict: null,
      dataLoaded: false,
      reloadRow: 0
    }),

    created() {
      this.formData = new PropertyForm({}).search
    },

    methods: {
      ...mapActions(['getDistricts']),

      getListings(e) {
        this.formData['listing_id'] = e
        this.onBlurAction()
      },
      getLifestyles(e) {
        this.formData['lifestyles_id'] = e
        if(this.dataLoaded)
          this.onBlurAction()
        else
          this.dataLoaded = true
      },
      onBlurAction(){
        this.$emit('blur')
      },
      processDistrictAction(id){
        if(this.formDistrict == id)
          this.formDistrict = null
        else
          this.formDistrict = id

        this.onBlurAction()

        this.reloadRow++
      }
    },

    watch: {
      form: {
        handler: function(val) {
          this.formData = val
        },
        deep: true
      },
      district(val) {
        this.formDistrict = val;
      },
      sync: {
        async handler() {
          try {
            this.dataLoading = true
            await this.getDistricts()
            this.dataLoading = false
            // eslint-disable-next-line no-unused-vars
          } catch (error) {
            this.dataLoading = false
          }
        },
        immediate: true,
        deep: true
      },
      formData: {
        handler: function(val) {
          this.$emit('form-search', val)
        },
        deep: true
      },
      formDistrict: {
        handler: function(val) {
          this.$emit('form-district', val)
        },
        deep: true
      }
    },

    computed: {
      items() {
        let retrievedData = this.$store.state.districtsModule.districts
        console.log("ASD")
        console.log(retrievedData)
        return Object.keys(retrievedData).map(k =>
          new DistrictTranslated(retrievedData[k])
        )
      },
      getformDistrict(){
        return this.formDistrict
      }
    }
  }
</script>