import axios from 'axios'
import { getFormData } from '@/utils/utils'

export default {
  getUsers(params) {
    return axios.get('/users', {
      params
    })
  },
  getUser(params) {
    return axios.get(`/users/${params}`, {
      params
    })
  },
  saveUser(params) {
    return axios.post(`/users/add`, getFormData(params.data),
      {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }
    )
  },
  editUser(params) {
    return axios.post(`/users/${params.data.id}/update`, getFormData(params.data),
      {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }
    )
  },
  deleteUser(id) {
    return axios.delete(`/users/${id}/delete`)
  }
}
