import Vue from 'vue'
import Heading from '@/components/common/Heading.vue'
import Description from '@/components/common/Description.vue'
import ErrorMessage from '@/components/common/ErrorMessage.vue'
import SuccessMessage from '@/components/common/SuccessMessage.vue'
import SubmitButton from '@/components/common/SubmitButton.vue'
import ProjectDescription from '@/components/common/ProjectDescription.vue'
import RightMenu from '@/components/core/RightMenu'
import DialogFormKeys from '@/components/dialogs/DialogFormKeys'
import DialogFormFeatureKeys from '@/components/dialogs/DialogFormFeatureKeys'
import InboxModal from '@/components/Modals/InboxModal'
import AgentCard from '@/components/agents/AgentCard'
import FloatAction from '@/components/core/FloatAction'
import LastByDistricts from '@/components/properties/LastByDistricts'
import KPIS from '@/components/districts/KPIS'
import PropertyCard from '@/components/properties/PropertyCard'
import PropertiesFilters from '@/components/properties/PropertiesFilters'
import InstructionsFilters from '@/components/instructions/InstructionsFilters'
import QuickAction from '@/components/common/QuickAction'
import InfoMessage from '@/components/common/InfoMessage'
import DialogImageEditor from '@/components/dialogs/DialogImageEditor'
import Banner from '@/components/common/Banner'
import DistrictCard from '@/components/districts/DistrictCard'
import NotAvailable from '@/components/common/NotAvailable'
import InboxMenuLabel from '@/components/inbox/menus/Label'
import InboxMenuAgents from '@/components/inbox/menus/Agents'
import ChartProperties from '@/components/dashboard/ChartProperties'
import ChartDistricts from '@/components/dashboard/ChartDistricts'
import UserWelcome from '@/components/dashboard/UserWelcome'
import WidgetsBadges from '@/components/dashboard/WidgetsBadges'
import LastProperties from '@/components/dashboard/LastProperties'
import DialogFormUser from '@/components/dialogs/DialogFormUser'
import NewDistrictBtn from '@/components/districts/NewDistrictBtn'
import CondominiumCard from '@/components/condominiums/CondominiumsCard'

Vue.component('Heading', Heading)
Vue.component('Description', Description)
Vue.component('FloatAction', FloatAction)
Vue.component('ErrorMessage', ErrorMessage)
Vue.component('SuccessMessage', SuccessMessage)
Vue.component('InfoMessage', InfoMessage)
Vue.component('SubmitButton', SubmitButton)
Vue.component('ProjectDescription', ProjectDescription)
Vue.component('QuickAction', QuickAction)
Vue.component('Banner', Banner)
Vue.component('NotAvailable', NotAvailable)

// Properties
Vue.component('LastPropertiesByDistrict', LastByDistricts)
Vue.component('PropertiesFilters', PropertiesFilters)
Vue.component('InstructionsFilters', InstructionsFilters)
Vue.component('PropertyCard', PropertyCard)



// Districts
Vue.component('DistrictKPI', KPIS)
Vue.component('NewDistrictBtn', NewDistrictBtn)

// Navigation
Vue.component('RightMenu', RightMenu)

// Modals
Vue.component('InboxModal', InboxModal)
Vue.component('FormKeys', DialogFormKeys)
Vue.component('FormFeatureKeys', DialogFormFeatureKeys)
Vue.component('FormUser', DialogFormUser)
Vue.component('ImageEditor', DialogImageEditor)

// Cards
Vue.component('CardAgent', AgentCard)
Vue.component('CardDistrict', DistrictCard)
Vue.component('CardCondominium', CondominiumCard)

// Inbox
Vue.component('AgentsMenuInbox', InboxMenuAgents)
Vue.component('LabelMenuInbox', InboxMenuLabel)


// Dashboard
Vue.component('ChartProperties', ChartProperties)
Vue.component('ChartDistricts', ChartDistricts)
Vue.component('UserWelcome', UserWelcome)
Vue.component('WidgetsBadges', WidgetsBadges)
Vue.component('LastProperties', LastProperties)
