<template>
    <v-expansion-panels flat :disabled="disabled">
        <v-expansion-panel>
            <v-expansion-panel-header class="v-expansion-panel-header-box">
                <div class="d-block">
                    <v-card-title class="subtitle-2 font-weight-bold pa-0 pb-4">{{$t('DEVELOPMENTS.FEATURES')}}
                    </v-card-title>
                    <v-card-subtitle class="caption font-weight-light text-left mb-0 pa-0" :class="disabled ? 'darkgray--text' : 'black--text'">{{$t('DEVELOPMENTS.SECONDARY')}}
                    </v-card-subtitle>
                </div>

            </v-expansion-panel-header>
            <v-expansion-panel-content class="v-expansion-panel-body-box">
                <v-row>
                    <v-col cols="4" class="text-left">
                      <!-- Bedrooms -->
                      <special-input-number  v-model="formData['bedrooms']" :fill="String(form.bedrooms)"
                                    :label="$t('DEVELOPMENTS.BEDROOMS')"
                                      @on-blur-event="onBlurAction"
                      ></special-input-number >
                      <!-- end -->

                      <!-- Bathrooms -->
                      <special-input-number  v-model="formData['bathrooms']" :fill="String(form.bathrooms)"
                                    :label="$t('DEVELOPMENTS.BATHROOMS')"
                                    @on-blur-event="onBlurAction"></special-input-number >
                      <!-- end -->

                                       
                    </v-col>
                    <v-col cols="4" class="text-right">
                      <!-- Gross Area -->
                      <special-input-number 
                              name="gross_area"
                              :label="$t('DEVELOPMENTS.GROSS') + $t('DEVELOPMENTS.SQ_FT')"
                              id="gross_area"
                              v-model="formData['gross_area']" :fill="String(form.gross_area)"
                              @on-blur-event="onBlurAction"
                      >
                      </special-input-number >
                      <!-- end -->

                      <!-- Living Area -->
                      <special-input-number 
                              name="living_area"
                              :label="$t('DEVELOPMENTS.LIVING') + $t('DEVELOPMENTS.SQ_FT')"
                              id="living_area"
                              v-model="formData['living_area']" :fill="String(form.living_area)"
                              @on-blur-event="onBlurAction"
                      >
                      </special-input-number >
                      <!-- end -->

                      
                    </v-col>
                    <v-col cols="4" class="text-left">
                      <!-- Parking -->
                      <special-input-number  v-model="formData['parking']" :fill="String(form.parking)"
                                    label="Parking"
                                    @on-blur-event="onBlurAction"></special-input-number >
                      <!-- end -->     
                      <!-- Exterior Area -->
                      <special-input-number 
                              name="exterior_area"
                              :label="$t('DEVELOPMENTS.EXTERIOR') + $t('DEVELOPMENTS.SQ_FT')"
                              id="exterior_area"
                              v-model="formData['exterior_area']" :fill="String(form.exterior_area)"
                              @on-blur-event="onBlurAction"
                      >
                      </special-input-number >
                      <!-- end -->
                    </v-col>
                    <v-col cols="8" class="text-left">
                        <!-- Types -->
                        <Types @get-types="getTypes" :form="form['type_id']" @on-blur-event="onBlurAction"/>
                        <!-- end -->
                        <!-- Features -->
                        <Features @get-features="getFeatures" :form="form['features_id']" @on-blur-event="onBlurAction"/>
                        <!-- end -->
                    </v-col>
                    <v-col cols="4" class="text-left">
                        <!-- Floor -->
                        <special-input-number  v-model="formData['floor']" :fill="String(form['floor'])"
                                      :label="$t('DEVELOPMENTS.FLOOR')"
                                      @on-blur-event="onBlurAction"
                        ></special-input-number >
                        <!-- end -->
                        <!-- Construction -->
                        <special-input-number 
                                name="construction"
                                :label="$t('DEVELOPMENTS.CONSTRUCTION') + $t('DEVELOPMENTS.SQ_FT')"
                                id="construction"
                                v-model="formData['construction']" :fill="String(form['construction'])"
                                @on-blur-event="onBlurAction"
                        >
                        </special-input-number >
                        <!-- end -->
                        <!-- Construction Type -->
                        <input-select :width="170"
                                v-model="formData['construction_area_type']" 
                                :fill="form['construction_area_type']"
                                :label="$t('DEVELOPMENTS.CONSTRUCTION_UNIT') + $t('DEVELOPMENTS.SQ_FT')"
                                @input="onBlurAction"
                                :items="allConstructionType">
                        </input-select>
                        <!-- end -->
                        <!-- Lote Size -->
                        <special-input-number 
                                name="lote_size"
                                :label="$t('DEVELOPMENTS.LOTE_SIZE') + $t('DEVELOPMENTS.SQ_FT')"
                                id="lote_size"
                                v-model="formData['lote_size']" :fill="String(form['lote_size'])"
                                @on-blur-event="onBlurAction"
                        >
                        </special-input-number >
                        <!-- end -->
                        <!-- Lote Type -->
                        <input-select :width="170"
                                v-model="formData['lote_size_type']" :fill="form['lote_size_type']"
                                @input="onBlurAction"
                                :items="allConstructionType" :label="$t('DEVELOPMENTS.LOTE_SIZE_UNIT') + $t('DEVELOPMENTS.SQ_FT')">
                        </input-select>
                        <!-- end -->
                        <!-- Land Size -->
                        <special-input-number 
                                name="land_size"
                                :label="$t('DEVELOPMENTS.LAND_SIZE') + $t('DEVELOPMENTS.SQ_FT')"
                                id="land_size"
                                v-model="formData['land_size']" :fill="String(form['land_size'])"
                                @on-blur-event="onBlurAction"
                        >
                        </special-input-number >
                        <!-- end -->
                        <!-- Lote Type -->
                        <input-select :width="170"
                                v-model="formData['land_size_type']" :fill="form['land_size_type']"
                                @input="onBlurAction"
                                :items="allConstructionType" :label="$t('DEVELOPMENTS.LAND_SIZE_UNIT') + $t('DEVELOPMENTS.SQ_FT')">
                        </input-select>
                        <!-- end -->
                        <!-- Year -->
                        <special-input-number 
                                name="year"
                                :label="$t('DEVELOPMENTS.YEAR')"
                                id="year"
                                v-model="formData['year']" :fill="String(form['year'])"
                                @on-blur-event="onBlurAction"
                        >
                        </special-input-number >
                        <!-- end -->
                        <!-- Energy -->
                        <input-select
                                v-model="formData['energy']" :fill="form['energy']"
                                @input="onBlurAction"
                                :label="$t('DEVELOPMENTS.ENERGY')" :items="getEnergy">
                        </input-select>
                        <!-- end -->
                        <!-- Elevator -->
                        <label class="d-block v-label theme--light mb-2 black--text font-weight-bold">{{$t('DEVELOPMENTS.ELEVATOR')}}:</label>
                        <input-radio
                                v-model="formData['elevator']" :fill="form['elevator']"
                                :items="[
                                  {
                                    id: $t('DEVELOPMENTS.ND'), 
                                    name: { 
                                      en: $t('DEVELOPMENTS.ND'),
                                      pt: $t('DEVELOPMENTS.ND')
                                    } 
                                  },
                                  {
                                    id: $t('DEVELOPMENTS.YES'), 
                                    name: { 
                                      en: $t('DEVELOPMENTS.YES'),
                                      pt: $t('DEVELOPMENTS.YES')
                                    }
                                  },
                                  {
                                    id: $t('DEVELOPMENTS.NO'),
                                    name: { 
                                      en: $t('DEVELOPMENTS.NO'),
                                      pt: $t('DEVELOPMENTS.NO')
                                    }
                                  }
                                ]"
                                :mandatory="true"
                                @blur="onBlurAction"
                        ></input-radio>
                        <!-- end -->
                    </v-col>
                </v-row>
            </v-expansion-panel-content>
        </v-expansion-panel>
    </v-expansion-panels>
</template>

<script>
  import EnergyEfficiency from '@/data/energy-efficiency'
  import Features from '@/components/condominiums/form/general/Keys/CondominiumsFeatures'
  import Types from '@/components/condominiums/form/general/Keys/CondominiumsTypes'
  import { CondominiumForm } from '@/store/structs/condominium'

  let options = ["m2", "a", "ha"];
  export default {

    name: 'Keys',

    props: {
      form: {},
      disabled: {
        type: Boolean,
        default: false
      }
    },

    components: { Types, Features },

    data: () => ({
      formData: CondominiumForm,
      allEnergy: EnergyEfficiency
    }),

    created() {
      this.formData = new CondominiumForm({}).types
    },

    methods: {
      getTypes(e) {
        this.formData['type_id'] = e;
      },
      getFeatures(e) {
        this.formData['features_id'] = e;
      },
      onBlurAction(){
        this.$emit('blur')
      }
    },

    watch: {
      form: {
        handler: function(val) {
          this.formData = val
        },
        deep: true
      },
      formData: {
        handler: function(val) {
          this.$emit('form-types', val)
        },
        deep: true
      }
    },
     computed: {
        allConstructionType() {
          return options;
      },
      getEnergy(){
        let isento = {
          pt: 'Isento',
          en: 'Exempt'
        }
        let processo = {
          pt: "Em Processo",
          en: "In Process"
        }
        
        let returner = ['A+','A','B','B-','C','D','E','F']

        

        returner.push(this.$processLanguage(isento))
        returner.push(this.$processLanguage(processo))
        return returner
      }
     }

  }
</script>
