export default [
    {
      path: '/instructionGuide',
      name: 'Instructions',
      component: () => import('@/pages/InstructionGuide'),
      meta: {
        layout: 'main',
        requiresAuth: true
      },
      children: [
        {
          path: '/',
          name: 'Introduction',
          meta: {
            title: 'ACTIVE INSTRUCTIONS',
            subtitle: 'Instructions of your website',
            fluid: true
          },
          component: () =>
            import(/* webpackChunkName: "home" */ '@/pages/instructions/Instructions')
        },
        ]
    }
  ]
  