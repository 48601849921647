import * as types from '@/store/mutation-types'
import api from '@/services/api/services/services'
import { buildSuccess, handleError } from '@/utils/utils.js'
import router from '@/router'


const getters = {
    services: (state) => state.services,
    service: (state) => state.service,
    totalServices: (state) => state.totalServices
  }
  
  const actions = {
    saveServiceDescriptionForm({ commit }, payload) {
      return new Promise((resolve, reject) => {
        api
          .saveServiceDescriptionForm(payload)
          .then((response) => {
            if (response.status === 200) {
              buildSuccess(
                {
                  msg: 'common.SAVED_SUCCESSFULLY'
                },
                commit,
                resolve()
              )
            }
          })
          .catch((error) => {
            handleError(error, commit, reject)
          })
      })
    },
    getServiceDescription({ commit }, payload) {
      return new Promise((resolve, reject) => {
        api
          .getServiceDescription(payload)
          .then((response) => {
            if (response.status === 200) {
              commit(types.SERVICES_DESCRIPTION, response.data.service_description)
              resolve()
            }
          })
          .catch((error) => {
            handleError(error, commit, reject)
          })
      })
    },
    getServices({ commit }, payload) {
      return new Promise((resolve, reject) => {
        api
          .getServices(payload)
          .then((response) => {
            if (response.status === 200) {
              commit(types.SERVICES, response.data.services)
              commit(types.TOTAL_SERVICES, response.data.total)
              resolve()
            }
          })
          .catch((error) => {
            handleError(error, commit, reject)
          })
      })
    },
    saveService({ commit }, payload) {
      return new Promise((resolve, reject) => {
        api
          .saveService(payload)
          .then((response) => {
            if (response.status === 201) {
              buildSuccess(
                {
                  msg: 'common.SAVED_SUCCESSFULLY'
                },
                commit,
                resolve,
                router.push({
                  path:`/services/edit/${response.data.district_id}`
                })
              )
            }
          })
          .catch((error) => {
            handleError(error, commit, reject)
          })
      })
    },
    editService({ commit }, payload) {
      return new Promise((resolve, reject) => {
        api
          .editService(payload)
          .then((response) => {
            if (response.status === 202) {
              buildSuccess(
                {
                  msg: 'common.SAVED_SUCCESSFULLY'
                },
                commit,
                resolve
              )
            }
          })
          .catch((error) => {
            handleError(error, commit, reject)
          })
      })
    },
    deleteService({ commit }, payload) {
      return new Promise((resolve, reject) => {
        api
          .deleteService(payload)
          .then((response) => {
            if (response.status === 200) {
              buildSuccess(
                {
                  msg: 'common.DELETED_SUCCESSFULLY'
                },
                commit,
                resolve,
              )
            }
          })
          .catch((error) => {
            handleError(error, commit, reject)
          })
      })
    }
}

const mutations = {
    [types.SERVICE](state, service) {
      state.service = service
    },
    [types.SERVICES](state, services) {
      state.services = services
    },
    [types.TOTAL_SERVICES](state, value) {
      state.totalServices = value
    },
    [types.SERVICES_DESCRIPTION](state, value) {
      state.service_description = value
    }
  }
  
  const state = {
    service_description: [],
    services: [],
    totalServices: 0,
    service: [],
  }
  
  export default {
    state,
    getters,
    actions,
    mutations
  }
  