<template>
  <div>
    <v-row no-gutters>
      <v-col  cols="12"
        :md="addRemoveOption ? 11 : 12">
        <v-select
                :items="items"
                :item-text="itemText"
                :item-value="itemValue"
                :label="label"
                :multiple="multiple"
                :chips="chips"
                :hint="hint"
                :value="fillData"
                :persistent-hint="persistentHint"
                :small-chips="true"
                outlined
                v-model="fillData"
                @input="updateValue"
                @change="updateValue"
        ></v-select>
      </v-col>
      <v-col cols="12" v-if="addRemoveOption"
        md="1">
        <div class="my-2 ml-2">
          <v-btn
            color="primary"
            fab
            x-small
            light
            @click="removeOption"
          >
            <v-icon>mdi-close-box-outline</v-icon>
          </v-btn>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
  export default {
    name: 'InputSelect',
    props: {
      items: {
        type: Array,
        default: null
      },
      itemText: {
        type: String,
        default: null
      },
      itemValue: {
        type: String,
        default: null
      },
      multiple: {
        type: Boolean,
        default: false
      },
      chips: {
        type: Boolean,
        default: false
      },
      persistentHint: {
        type: Boolean,
        default: false
      },
      hint: {
        type: String,
        default: null
      },
      label: {
        type: String,
        default: 'Label'
      },
      fill: [Number, String],
      addRemoveOption:{
        type: Boolean,
        default: false
      }
    },
    data(){
      return{
        reloadInput: 0
      }
    },
    computed: {
      fillData: {
        get() {
          return this.fill
        },
        set(value) {
          this.$emit('update:prop', value)
        }
      }
    },

    methods: {
      updateValue(e) {
        this.$emit('input', e)
      },
      removeOption(){
        this.$emit('input', null)
      }
    }
  }
</script>