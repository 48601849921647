import Vue from 'vue'
import Router from 'vue-router'
import Meta from 'vue-meta'
import routes from '@/router/routes'
import { store } from '@/store'
import { checkIfTokenNeedsRefresh } from '@/utils/utils'
import { checkForUpdates } from '@/utils/updates'
import { setLayout } from '@/utils/layout'
import * as types from '@/store/mutation-types'

Vue.use(Router)
Vue.use(Meta)

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [...routes]
})

router.beforeEach((to, from, next) => {
  checkForUpdates()
  const isTokenSet = store.getters.isTokenSet
  if (!isTokenSet && to.name !== 'login') {
    return next('/login')
  }else if (isTokenSet && to.name === 'Auth'){
    return next('/home')
  }

  checkIfTokenNeedsRefresh()
  store.commit(types.SUCCESS, null)
  store.commit(types.ERROR, null)

  setLayout(to)

  return next()
})

export default router
