<template>
    <v-card flat rounded class="v-card__property">
        <!-- Tag (sale, new, reserved, etc...) -->
        <Tag :tag="property.tag"/>
        <!-- end -->
        <div  style="position: absolute;" v-on:click="archiveThisProperty(property.id)">
            <v-chip color="secondary" label>
                Archive
            </v-chip>            
        </div>
        <div v-if="property.archived===1" style="position: absolute;" v-on:click="unArchiveThisProperty(property.id)">
            <v-chip color="secondary" label>
                Unarchive
            </v-chip>            
        </div>        
        <!-- Media -->
        <Media @refresh-media="reloadMedia" :key="mediaReloadKey" :agent="property.agent" :media="property.media"/>
        <!-- end -->

        <!-- Information -->
        <Information :information="property.information"/>
        <!-- end -->

        <!-- KPIS -->
        <KPIS :kpis="property.kpis"/>
        <!-- end -->

        <v-card-actions style="display: block;">
            <div class="font-weight-medium secondary--text">
              
              <currency-input class="v-input-text font-weight-medium secondary--text" 
              :value="property.price"
              disabled
              prefix="€"/>
            </div>            
        </v-card-actions>

        <v-card-actions style="display: grid;">
          <v-btn small depressed color="secondary" form="type-form" type="submit" class="px-0"
                   @click="demoShow(property.disabled)"
                   :to="property.disabled ? '' : '/properties/edit/' + property.id + '/general'">View more
          </v-btn>
          
          <v-btn @click="confirmModal" color="danger" label style="position: absolute; top:0; right: 0">
            <i class="zwicon-trash"></i>
          </v-btn>
        </v-card-actions>
        <ModalYesNo v-show="showModal" 
          @yes="removeProperty"
          @no="closeModal"
          message="You are about to delete a Property. What do I do?"/>
        </v-card>
</template>

<script>
  import Tag from '@/components/properties/card/Tag'
  import Media from '@/components/properties/card/Media'
  import Information from '@/components/properties/card/Information'
  import KPIS from '@/components/properties/card/KPIS'
  import { handleInfo } from '@/utils/utils'
  import mixin from '@/models/properties/PropertiesModel'
  import ModalYesNo from '@/components/Modals/ModalYesNo'

  export default {
    name: 'PropertyCard',

    components: { KPIS, Information, Media, Tag, ModalYesNo },
    mixins: [mixin],
    props: {
      property: {
        default: null,
        type: Object
      }
    },
    data(){
      return {
        showModal: false,
        mediaReloadKey: 0
      }
    },

    methods: {
      reloadMedia(){
        console.log("UPLOAD")
        this.mediaReloadKey += 1
      },
      demoShow(state) {
        if (state) {
          handleInfo(
            {
              msg: 'errors.DEMO',
              params: [],
              timeout: 5000
            }
          )
        }
      },
      archiveThisProperty: async function (id){
        await this.archiveProperty({id: id})
        this.$emit('update-view')
      },
      unArchiveThisProperty: async function(id){
        await this.unArchiveProperty({id: id})
        this.$emit('update-view')
      },
      confirmModal(){
        this.showModal = true
      },
      closeModal(){
        this.showModal = false
      },
      removeProperty: async function(){
        await this.deleteProperty({id: this.property.id})
        this.closeModal()
        this.$emit('update-view')
      }
    }
  }
</script>
