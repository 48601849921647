<template>
  <v-container v-show="showInfoMessage">
    <v-layout>
      <v-flex>
        <v-snackbar
          v-model="showInfoMessage"
          color="#ffffff"
          bottom
          :elevation="0"
          content-class="v-snack__info"
          :timeout="infoMessageTimeout"
        >
          <span class="black--text font-weight-bold">{{ infoMessage }}</span>
          <v-btn text @click="showInfoMessage = false" style="background-color: #9D420D !important;">
            <span class="white--text">{{ $t('common.CLOSE') }}</span>
          </v-btn>
        </v-snackbar>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import * as types from '@/store/mutation-types'

export default {
  name: 'InfoMessage',
  computed: {
    showInfoMessage: {
      get() {
        return this.$store.state.info.showInfoMessage
      },
      set(value) {
        this.$store.commit(types.SHOW_INFO, value)
      }
    },
    infoMessage() {
      if (this.$store.state.info.infoMessageParams) {
        return this.$i18n.t(this.$store.state.info.infoMessage, [
          ...this.$store.state.info.infoMessageParams
        ])
      }
      return this.$i18n.t(this.$store.state.info.infoMessage)
    },
    infoMessageTimeout() {
      return this.$store.state.info.infoMessageTimeout
    }
  },
  watch: {
    infoMessage() {
      setTimeout(() => {
        this.showInfoMessage = this.infoMessage !== ''
      }, 100)
    }
  }
}
</script>
