<template>
    <v-card
            flat
            class="mb-10"
    >
        <v-img
                height="250"
                :src="this.$mediaFile('districts', districtData.id, districtData.cover)"
                :lazy-src="this.$mediaFile('districts', districtData.id, districtData.cover)"
                class="align-end text-left pl-3 pb-3"
        >
            <template v-slot:placeholder>
                <v-row
                        class="fill-height ma-0"
                        align="center"
                        justify="center"
                >
                    <v-progress-circular
                            indeterminate
                            color="grey lighten-5"
                    ></v-progress-circular>
                </v-row>
            </template>
            <!-- District KPI -->
            <DistrictKPI :kpis="districtData.kpis"/>
            <!-- end -->
        </v-img>
        <div class="px-5">
            <v-row>
                <v-col cols="6" class="py-3">
                    <v-card-title class="font-weight-light text-uppercase px-0 pt-0">{{ districtData.name }}
                    </v-card-title>
                    <v-card-subtitle class="caption font-weight-light text-left px-0 pb-0 text-limit-liner">
                        {{districtData.description}}
                    </v-card-subtitle>
                </v-col>
                <v-col cols="6" class="d-flex justify-end align-center">
                    <v-btn depressed color="secondary" form="type-form" type="submit" :to="'/districts/edit/' + districtData.id">View more</v-btn>
                </v-col>
            </v-row>
        </div>
    </v-card>
</template>

<script>
  export default {
    name: 'DistrictCard',

    props: {
      districtData: {
        type: Object,
        default: null
      }
    }
  }
</script>