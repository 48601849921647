<template>
    <v-card class="top-header custom-color" flat color="#ffffff">
        <v-card-title class="subtitle-2 font-weight-light">Lastest Properties</v-card-title>
        <v-card-subtitle class="caption font-weight-light text-left">All added properties in that districts
        </v-card-subtitle>

        <!-- List -->
        <v-list flat color="transparent">
            <v-list-item class="d-flex mb-5" v-for="(property, index) in properties" :key="index">
                <v-img
                        :src="property.media.cover"
                        :lazy-src="property.media.cover"
                        height="50"
                        max-width="50"
                        class="grey lighten-2 d-block rounded-sm"
                >
                    <template v-slot:placeholder>
                        <v-row
                                class="fill-height ma-0"
                                align="center"
                                justify="center"
                        >
                            <v-progress-circular
                                    indeterminate
                                    color="grey lighten-5"
                            ></v-progress-circular>
                        </v-row>
                    </template>
                </v-img>
                <div class="information ml-5 text-left">
                    <p class="font-weight-light mb-0 caption">{{ property.name }}</p>
                    <h6 class="font-weight-light">{{ property.agent }} <span class="grey--text lighten-4">|</span> <span
                            class="primary--text">{{ property.district }}</span></h6>
                    <p class="font-weight-bold mb-0 caption secondary--text">€ {{ property.price }}</p>
                </div>
            </v-list-item>
        </v-list>
    </v-card>
</template>

<script>
  export default {
    name: 'LastByDistricts',
    data: () => ({
      properties: [
        {
          name: 'Village Cascais Resort',
          agent: 'Ana Graça',
          district: 'Cascais',
          price: '1.600.00',
          media: {
            cover: 'https://images.unsplash.com/photo-1580587771525-78b9dba3b914?ixlib=rb-1.2.1&ixid=MXwxMjA3fDB8MHxleHBsb3JlLWZlZWR8MXx8fGVufDB8fHw%3D&w=1000&q=80'
          }
        },
        {
          name: 'Village Cascais Resort',
          agent: 'Ana Graça',
          district: 'Cascais',
          price: '1.600.00',
          media: {
            cover: 'https://images.adsttc.com/media/images/5e1d/02c3/3312/fd58/9c00/06e9/large_jpg/NewHouse_SA_Photo_01.jpg?1578959519'
          }
        },
        {
          name: 'Village Cascais Resort',
          agent: 'Ana Graça',
          district: 'Cascais',
          price: '1.600.00',
          media: {
            cover: 'https://cdn.houseplansservices.com/product/jbo1rrf0j7s4ebo15acfa89ar/w800x533.jpg?v=3'
          }
        },
        {
          name: 'Village Cascais Resort',
          agent: 'Ana Graça',
          district: 'Cascais',
          price: '1.600.00',
          media: {
            cover: 'https://www.primoris.co.uk/wp-content/uploads/2020/01/park-house-banner.jpg'
          }
        },
        {
          name: 'Village Cascais Resort',
          agent: 'Ana Graça',
          district: 'Cascais',
          price: '1.600.00',
          media: {
            cover: 'https://cdn.jhmrad.com/wp-content/uploads/new-modern-homes-designs-zealand-home-design_659017.jpg'
          }
        }
      ]
    })
  }
</script>
