function currency(n) {
    n = parseFloat(n)
    n = n.toLocaleString('en-' + new String(this.$i18n.locale).toUpperCase(), { style: 'currency', currency: 'EUR' });
  
    n = n.replaceAll('.', '!')
    n = n.replaceAll(',', '.')
    n = n.replaceAll('!', ',')
    n = n.split('€')[1]
    n = n.split(',00')[0]
    n = n + ' €'
    return n
  }
  
  export default currency