<template>
    <div v-if="dataLoaded">
      <apexchart height="175px" type="area" :options="options" :series="series"></apexchart>
    </div>
</template>

<script>
  import VueApexCharts from 'vue-apexcharts'

  export default {
    name: 'ChartProperties',
    components: {
      'apexchart': VueApexCharts
    },
    props:{
      lastProperties:{
        type: Array,
        default: null
      }
    },
    data: function() {
      return {
        dataLabels: [],
        dataValues: [],
        dataLoaded: false,
        options: {
         chart: {
            type: 'area',
            height: 0,
            toolbar: {
              show: true
            },
            zoom: {
              enabled: true
            },
            sparkline: {
              enabled: false
            }
          },
          plotOptions: {},
          legend: {
            show: true
          },
          dataLabels: {
            enabled: true
          },
          fill: {
            type: 'gradient',
            opacity: 1,
            gradient: {
              shadeIntensity: 1,
              opacityFrom: 0.7,
              gradientToColors: ['#D3BF77'],
              opacityTo: 0,
              stops: [0, 90, 100],
              colorStops: [
                {
                  offset: 0,
                  color: '#DFB1A0',
                  opacity: 1
                },
                {
                  offset: 100,
                  color: "#ffffff",
                  opacity: 0.6
                }
              ]
            }
          },
          
          stroke: {
            curve: 'smooth',
            show: true,
            width: 2.5,
            colors: ['#BE5E3B']
          },
          xaxis: {
            categories: [],
            axisBorder: {
              show: false
            },
            axisTicks: {
              show: true
            },
            labels: {
              show: true,
              style: {
                colors: '#BE5E3B',
                fontSize: '12px',
                fontFamily: 'inherit'
              }
            },
            crosshairs: {
              show: false,
              position: 'front',
              stroke: {
                color: '#BE5E3B',
                width: 1,
                dashArray: 3
              }
            },
            tooltip: {
              enabled: false,
              formatter: undefined,
              offsetY: 0,
              style: {
                fontSize: '12px',
                fontFamily: 'inherit'
              }
            }
          
          },
          states: {
            normal: {
              filter: {
                type: 'none',
                value: 0
              }
            },
            hover: {
              filter: {
                type: 'none',
                value: 0
              }
            },
            active: {
              allowMultipleDataPointsSelection: false,
              filter: {
                type: 'none',
                value: 0
              }
            }
          },
          tooltip: {
            style: {
              fontSize: '12px',
              fontFamily: 'inherit'
            },
            y: {
              formatter: function(val) {
                return val + ' properties'
              }
            }
          },
          color: ['#BE5E3B'],
          markers: {
            colors: ['#BE5E3B'],
            strokeColor: ['#BE5E3B'],
            strokeWidth: 2
          },
          padding: {
            top: 0,
            bottom: 0
          }
        },
        series: [{
          name: '',
          data: []
        }]
      }
    },
    created(){
      this.lastProperties.forEach(element => {
        this.dataLabels.push(this.getExtensionMonth(element.month) + ' ' + String(element.year)[2] + String(element.year)[3])
        this.dataValues.push(parseInt(element.counter))
      });
      
      this.dataLabels = this.dataLabels.reverse()
      this.dataValues = this.dataValues.reverse()

      this.series = [{
        data: this.dataValues
      }]
      this.options.xaxis.categories = this.dataLabels

      this.dataLoaded = true
    },
    methods:{
      getExtensionMonth(n){
        let monthsArray = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
        return monthsArray[n - 1]
      }
    }
   
  }
</script>
