import axios from 'axios'
import { getFormData, getFormDataMultidimensional } from '@/utils/utils'


export default {
  getDistricts(params) {
    return axios.get('/districts', {
      params
    })
  },
  getDistrict(params) {
    return axios.get(`/districts/${params}`, {
      params
    })
  },
  saveDistrict(params) {
    return axios.post(`/districts/add`, getFormDataMultidimensional(params.data),
      {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }
    )
  },
  editDistrict(params) {
    return axios.post(`/districts/${params.data.id}/update`, getFormDataMultidimensional(params.data),
      {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }
    )
  },
  deleteDistrict(id) {
    return axios.delete(`/districts/${id}/delete`)
  }
}
