<template>
    
        <v-dialog
                :value="value" @input="$emit('input')"
                persistent
                max-width="600px"
        >
        <v-card>
          <v-toolbar flat class="mode-header" style="background-color: #BE5E3B !important;">
              <v-toolbar-title
                      v-for="(language, index) in $t('LANGUAGES')"
                      :key="index"
                      class="cursor-pointer mr-5"
                      :class="language.id === locale ? 'white--text font-weight-medium' : 'darkgray--text font-weight-light unselected-language-style'"
                      @click="processClickLanguage(language.id)"
              >
                  {{ language.name }}
              </v-toolbar-title>
          </v-toolbar>
            <v-card-title class="d-flex flex-column align-content-start align-start pb-5">
                <span class="title font-weight-bold">{{ title }}</span>
                <span class="body-2 font-weight-medium light-grey--text">{{ formTitle }}</span>
            </v-card-title>
            
            
            


            <v-card-text>
              
              
                <v-container class="mb-0">
                    <v-row>
                        <v-col cols="12" class="pb-0">
                            <ValidationObserver v-slot="{ handleSubmit }" ref="observer">
                                <form id="type-form" @submit.prevent="handleSubmit(submit)">
                                  
                                    <!-- Name -->
                                    <v-col cols="12" class="pa-0" v-bind:style="locale_slug=='pt' ? 'border-color:#4CAF50;border-top-style:solid;border-radius: 5px;' : ''">
                                        <i18n-input-text
                                                name="name"
                                                :label="$t('KEYS.NAME')"
                                                id="name"
                                                rules="required|min:5"
                                                v-model="form.name[locale_slug]"
                                                :fill="typeData.name[locale_slug]"
                                                @blur="blurAction"
                                                :key="ReloadLanguage"
                                        >
                                        </i18n-input-text>
                                    </v-col>
                                    <!-- end -->


                                    <!-- File -->
                                    <v-col cols="12" class="pa-0 position-relative" v-if="false">
                                        <not-available custom-class="d-flex align-center justify-end position-absolute"
                                                       height="46"/>
                                        <input-file placeholder="Select your icon" label="Icon"></input-file>
                                    </v-col>
                                    <!-- end -->

                                    <!-- Checkbox -->
                                    <v-col cols="12" class="pa-0">
                                        <input-checkbox :status="typeData.filterable" v-model="form.filterable"
                                                        :label="$t('KEYS.IS_FILTERABLE')"
                                                        custom-class="mt-0"></input-checkbox>
                                    </v-col>
                                    <!-- end -->
                                </form>
                            </ValidationObserver>
                        </v-col>
                    </v-row>
                </v-container>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn depressed color="transparent" @click.native="$emit('input')">Close</v-btn>
                <v-btn depressed color="primary" form="type-form" type="submit">Save</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
  import Key from '@/store/structs/key'

  export default {
    name: 'DialogFormKeys',

    props: {
      value: Boolean,
      title: {
        type: String,
        default: 'Key'
      },
      typeData: {
        type: Key,
        default: null
      }
    },

    data(){
      return{
        files: [],
        form: Key,
        locale: 0,
        locale_slug: this.$t('LANGUAGE_CHOOSER.en_slug'),
        window: 0,
        ReloadLanguage: 0
      }
    },

    watch: {
      value(val) {
        if (val) {
          if (this.typeData !== null) {
            this.form = { ...this.typeData }
          }
        }
      },
      typeData: function() {
        console.log("DATA")
        console.log(new Key(this.typeData))
        return new Key(this.typeData)
      }
    },

    methods: {
      processClickLanguage(id){
        this.locale = id
        let parent = this
        this.$t('LANGUAGES').forEach(element => {
          if(element.id == id)
            parent.locale_slug = element.slug
        });
        this.ReloadLanguage++
      },
      blurAction(){
        if(this.form.id !== null)
          this.submit()
      },
      async submit() {
        const isValid = await this.$refs.observer.validate()
        if (isValid) {
          this.$emit('form-data', this.form)
        }
      }
    },

    computed: {
      formTitle() {
        return this.typeData && this.typeData.name.en
          ? this.$t('KEYS.EDITING') + this.$processLanguage(this.typeData.name)
          : this.$t('KEYS.ADD_NEW')
      }
    }
  }
</script>
