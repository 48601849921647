export default [
  {
    path: '/inbox',
    name: 'Inbox',
    component: () => import('@/pages/inbox/Index'),
    meta: {
      title: 'INBOX',
      subtitle: 'All messages received form web system.',
      fluid: false,
      layout: 'main'
    }
  },
  {
    path: '/inbox/read/:id',
    name: 'readInbox',
    meta: {
      layout: 'main',
      title: 'ADD NEW AGENT',
      subtitle: 'Form to add new agent to your platform',
      fluid: false
    },
    component: () =>
      import(/* webpackChunkName: "home" */ '@/pages/inbox/Read')
  }
]
