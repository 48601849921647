<template>
    <div>
        <v-navigation-drawer
                v-model="drawerStatus"
                fixed
                bottom
                width="356"
                color="#ffffff"
                class="v-menu-users"
                temporary
                height="100vh"
        >
            <v-card flat class="mt-0 h-full">
                <div class="py-3 px-4" >
                    <v-card-title class="subtitle-2 font-weight-bold">{{$t('SYSTEM.TITLE')}}</v-card-title>
                    <v-card-subtitle class="caption text-left font-weight-light black--text">
                        {{$t('SYSTEM.DESCRIPTION')}}
                    </v-card-subtitle>
                </div>

                <div class="d-flex flex-column mx-8 justify-space-between" style="height: calc(100% - 125px)">
                    <v-row class="h-full" style="flex: 0">
                        <v-col
                                v-for="(user, index) in items"
                                :key="index"
                                cols="12"
                                class="pb-1"
                        >
                            <v-item-group active-class="primary">
                                <v-item>
                                    <v-card
                                            class="d-inline-flex align-center w-full"
                                            dark
                                            flat
                                            :elevation="24"
                                            height="55"
                                            min-width="235"
                                    >
                                        <v-img
                                                :src="$mediaFile('users', user.id, `https://eu.ui-avatars.com/api/?font-size=0.25&bold=true&name=${user.id}`)"
                                                :lazy-src="$mediaFile('users', user.id, `https://eu.ui-avatars.com/api/?font-size=0.25&bold=true&name=${user.id}`)"
                                                height="40"
                                                max-width="40"
                                                class="rounded ml-2"
                                        ></v-img>
                                        <div class="w-full">
                                            <v-card-title
                                                    class="caption font-weight-bold text-left black--text">
                                                {{ user.name }}
                                            </v-card-title>
                                            <v-card-subtitle
                                                    class="caption justify-center font-weight-light text-left black--text">
                                                {{ user.email }}
                                            </v-card-subtitle>
                                            <!-- end -->
                                        </div>
                                        <v-btn class="secondary pa-0 v-btn--no-padding" depressed @click="launchDialog(true, user)">
                                            <i class="zwicon-pencil"></i>
                                        </v-btn>
                                    </v-card>
                                </v-item>
                            </v-item-group>
                            <v-divider class="my-3"></v-divider>
                        </v-col>
                    </v-row>
                    <v-btn class="secondary" @click="launchDialog(true, 0)">
                        {{$t('SYSTEM.ADD')}}
                    </v-btn>
                </div>
            </v-card>
        </v-navigation-drawer>

        <!-- Success -->
        <SuccessMessage />
        <!-- end -->

        <!-- Dialog -->
        <FormUser v-model="dialogStatus" :type-data="selectedItem" title="Users" @delete-data="deleteData" @form-data="formData" />
        <!-- end -->
    </div>
</template>

<script>
  import mixin from '@/models/system/UserModel'
  import  UserForm from '@/store/structs/user_form'
  import  { User } from '@/store/structs/user'

  export default {
    name: 'DrawerUsers',

    mixins: [mixin],

    props: {
      drawer: {
        type: [String, Boolean],
        default: false
      }
    },

    data: () => ({
      dialogStatus: false,
      selectedItem: null,
    }),

    methods: {
      formData: function(form) {
        this.save(form)
      },
      deleteData: function(form) {
        this.deleteItem(form.id)
      },
      launchDialog(mode, object) {
        document.getElementsByClassName('v-overlay__scrim')[0].click();
        this.dialogStatus = mode
        this.selectedItem = new UserForm(object)
      },
      close() {
        this.dialogStatus = false
      }
    },

    watch: {
      users: {
        async handler() {
          try {
            await this.getUsers()
          } catch (error) {
            console.error('Not possible to load users')
          }
        },
        immediate: true,
        deep: true
      }
    },

    computed: {
      drawerStatus: {
        set(value) {
          return value
        },
        get() {
          return this.drawer
        }
      },
      items() {
        let retrievedData = this.$store.state.systemUsersModule.users
        return Object.keys(retrievedData).map(k =>
          new User(retrievedData[k])
        )
      }
    }
  }
</script>
