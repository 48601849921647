<template>
    <v-card
            flat
            rounded
            class="v-card__property"
    >
        <div v-if="condominiumData.archived == 0" style="position: absolute;" v-on:click="archiveThisCondominium(condominiumData.id)">
            <v-chip color="secondary" label>
                Archive
            </v-chip>            
        </div>
        <div v-if="condominiumData.archived == 1" style="position: absolute;" v-on:click="unArchiveThisCondominium(condominiumData.id)">
            <v-chip color="secondary" label>
                Unarchive
            </v-chip>            
        </div>
            <v-img
                    height="250"
                    :src="this.$mediaFile('condominiums', condominiumData.id, condominiumData.cover)"
                    :lazy-src="this.$mediaFile('condominiums', condominiumData.id, condominiumData.cover)"
                    class="align-end text-left pl-3 pb-3"
            >
            <template v-slot:placeholder>
                <v-row
                        class="fill-height ma-0"
                        align="center"
                        justify="center"
                >
                    <v-progress-circular
                            indeterminate
                            color="grey lighten-5"
                    ></v-progress-circular>
                </v-row>
            </template>
        </v-img>
        <div class="px-5">
            <v-row>
                <v-col cols="12" class="py-3">
                    <v-card-title class="font-weight-light text-uppercase px-0 pt-0" style="word-break: normal;">
                        {{condominiumData.information.title['en'] }}
                    </v-card-title>
                    <v-card-subtitle class="caption font-weight-light text-left px-0 pb-0 text-limit-liner"  style="word-break: normal;">
                        {{condominiumData.information.slogan['en'] }}
                    </v-card-subtitle>
                     <v-card-subtitle class="caption font-weight-light text-left px-0 pb-0 text-limit-liner"  style="word-break: normal;">
                        {{condominiumData.condo_where}}
                    </v-card-subtitle>                     
                </v-col>                
            </v-row>            
        </div>
        <v-card-actions style="display: block;">
            <div class="font-weight-medium secondary--text" style="text-align: left;">
              
              <currency-input class="v-input-text font-weight-medium secondary--text" 
              
              :value="condominiumData.starting_price"
              disabled
              prefix="€"/>              
              </div>
        </v-card-actions>
        <v-card-actions style="display: grid;">
            <v-btn small depressed color="secondary" form="type-form" type="submit" class="px-0"
                  @click="demoShow(condominiumData.disabled)"
                  :to="condominiumData.disabled ? '' : '/condominiums/edit/' + condominiumData.id + '/general'">View more
              </v-btn>
          <v-btn @click="confirmModal" color="danger" label style="position: absolute; top:0; right: 0">
            <i class="zwicon-trash"></i>
          </v-btn>
        </v-card-actions>
        <ModalYesNo v-show="showModal" 
          @yes="removeCondominium"
          @no="closeModal"
          message="You are about to delete a Condominium. What do I do?"/>
    </v-card>
</template>

<script>
  import mixin from '@/models/condominium/CondominiumsModel'
  import ModalYesNo from '@/components/Modals/ModalYesNo'
  export default {
    name: 'CondominiumsCard',
    mixins: [mixin],
    props: {
      condominiumData: {
        type: Object,
        default: null
      }
    },
    components: { ModalYesNo },
    data(){
      return {
        showModal: false
      }
    },
    methods: {
      demoShow(state) {
        if (state) {
          handleInfo(
            {
              msg: 'errors.DEMO',
              params: [],
              timeout: 5000
            }
          )
        }
      },
      archiveThisCondominium: async function (id){
        await this.archiveCondominium({id: id})
        this.$emit('update-view')
      },
      unArchiveThisCondominium: async function(id){
        await this.unArchiveCondominium({id: id})
        this.$emit('update-view')
      },
      confirmModal(){
        this.showModal = true
      },
      closeModal(){
        this.showModal = false
      },
      removeCondominium: async function(){
        await this.deleteCondominium({id: this.condominiumData.id})
        this.closeModal()
        this.$emit('update-view')
      }
    }
  }
</script>