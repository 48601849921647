<template>
    <file-pond
            name="test"
            ref="pond"
            :label-idle="$t('PROPERTIES.DROP_FILES')"
            allow-multiple="true"
            accepted-file-types="image/jpeg, image/png"
            instantUpload="true" 
            allowImagePreview="true"   
            allowRevert="false"
            checkValidity="true"
            imagePreviewHeight="200"
            imageCropAspectRatio="1:1"
            imageResizeTargetWidth="200"
            imageResizeTargetHeight="200"
            allowFileSizeValidation="true"
            :maxFileSize="getMaxFileSize"
            :labelMaxFileSizeExceeded="$t('POND_DRAG.TOO_LARGE')"
            imageEditInstantEdit="false"
            v-on:init="handleFilePondInit"
            v-on:addfile="addFile"
            :server="this.server"
            v-bind:files="myFiles"
    />
</template>


<script>
import swal from 'sweetalert';
  // Import Vue FilePond
  import vueFilePond from 'vue-filepond'

  // Import the plugin code
  import FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size';

  // Import FilePond styles
  import 'filepond/dist/filepond.min.css'
  import '@/assets/scss/plugins/file.pond.css'

  // Import image preview plugin styles
  import 'filepond-plugin-image-edit/dist/filepond-plugin-image-edit.css'
  import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css'

  // Import image preview and file type validation plugins
  import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type'
  import FilePondPluginImagePreview from 'filepond-plugin-image-preview'
  import FilePondPluginImageEdit from 'filepond-plugin-image-edit'
  import VImageInput from 'vuetify-image-input';

  import FilepondPluginDragReorder from 'filepond-plugin-drag-reorder';
  
  // Create component
  const FilePond = vueFilePond(
    FilePondPluginFileValidateType,
    FilePondPluginImageEdit,
    FilePondPluginImagePreview,
    FilepondPluginDragReorder ,
    FilePondPluginFileValidateSize
  )

  async function removeFile(id, uniqueFileId){
    const formData = new FormData()
    formData.append('file_name', uniqueFileId)  
    axios.post('/property/'+id+'/media/remove', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
    .then(res => {        
      })    
   }
  //https://sweetalert.js.org/guides/
  export default {
    name: 'BrowseFiles',
    components: {
      FilePond,
      [VImageInput.name]: VImageInput
    },
    props:
      ['counterItems']
    ,
    methods: {
      handleFilePondInit: function () {
        // File pond init
      },
      async showAlertBox(iconType, message){
        let fileString = ''
        this.exceedFiles.forEach(element => {
          fileString += "- " + element + "\n\n"
        })
        await swal("Files Exceeded Limit (" + this.limitSize+ "MB)", message + fileString, iconType)
      },
      addFile: async function(){
        let e = this.$refs.pond.getFiles();
        if(this.quantityFilesExceedLimit() == e.length){
          await this.showAlertBox("error", "All files exceeded the limit\n\n")
          this.$emit('reload-me') 
        }
        this.$emit('files', e)        
      },
      quantityFilesExceedLimit(){
        let e = this.$refs.pond.getFiles();
        let counter = 0
        let exceedNames = []
        e.forEach(element => {
          if((element.file.size / 1000000) >= this.limitSize){
            counter++
            this.exceedFilesFlag = true
            exceedNames.push(element.filename)
            console.log(exceedNames)
          }
        });
        this.exceedFiles = exceedNames
        
        return counter
      }
    },
    computed:{
      getMaxFileSize(){
        return this.limitSize + 'MB'
      }
    },

    data: function() {
      return {
        myFiles: [],
        uploadedFiles: 0,
        limitSize: 2,
        exceedFiles: [],
        exceedFilesFlag: false,
        server:{
          // Processar o ficheiro
          process: async (fieldName, file, metadata, load, error, progress, abort) => {
            let stopLoading = false
            if(this.uploadedFiles == 0){
              this.uploadedFiles = this.quantityFilesExceedLimit()
            }
              
            this.uploadedFiles+=1
            
            if(this.uploadedFiles == this.$refs.pond.getFiles().length){
              this.uploadedFiles = 0
              stopLoading = true
              if(this.exceedFilesFlag)
                await this.showAlertBox("info", "There are files that exceeded the limit:\n\n")

              console.log(this.exceedFiles)
            }

            let orderFiles = this.$refs.pond.getFiles();
            let i = 0
            let filePos = 0
            orderFiles.forEach(element => {
              if(file.name == element.file.name)
                filePos = i
              
              i++
            });

            //filePos = filePos + this.counterItems
            //console.log("TAMANHO")
            //console.log(filePos)
            let res = await this.$emit('process-server', file, stopLoading, filePos) 
            
            if(res){
              load(file.name);
            }else{
              console.log("A problem occured");
            }
          },
          // Remover ficheiro carregado
          revert: (uniqueFileId, load, error) => {
            removeFile(this.$route.params.slug, uniqueFileId);
            load() 
          }
        }
      }
    }
  }
</script>

