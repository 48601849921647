import axios from 'axios'
//import { getFormData, getFormDataMultidimensional } from '@/utils/utils'


export default {
    getStatistics(params) {
    return axios.get('/dashboard/statistics', {
      params
    })
  },  
}
