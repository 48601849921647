export default [
    {
      path: '/administration',
      name: 'Administration',
      component: () => import('@/pages/Administration'),
      meta: {
        layout: 'main',
        requiresAuth: true,
      },
      children: [
        {
          path: '/download/csv',
          name: 'CSVContacts',
          meta: {
            title: 'CSV File',
            subtitle: 'Download your file',
            fluid: true
          },
          component: () =>
            import(/* webpackChunkName: "home" */ '@/pages/administration/DownloadContacts')
        },
      ]
    }
  ]
  