import * as types from '@/store/mutation-types'
import api from '@/services/api/dashboard/dashboard'
import { buildSuccess, handleError } from '@/utils/utils.js'

const getters = {
    statistics: (state) => state.statistics,
}

const actions = {
    getStatistics({ commit }, payload) {
    return new Promise((resolve, reject) => {
      api
        .getStatistics(payload)
        .then((response) => {
          if (response.status === 200) {
            commit(types.STATISTICS, response.data.statistics)
            resolve()
          }
        })
        .catch((error) => {
          handleError(error, commit, reject)
        })
    })
  },
}

const mutations = {
  [types.STATISTICS](state, statistics) {
    state.statistics = statistics
  }
}

const state = {
  statistics: []
}

export default {
  state,
  getters,
  actions,
  mutations
}