import axios from 'axios'
import { getFormDataMultidimensional } from '@/utils/utils'

export default {
  getAgents(params) {
    return axios.get('/agents', {
      params
    })
  },
  getAgent(params) {
    return axios.get(`/agents/${params}`, {
      params
    })
  },
  saveAgent(params) {
    return axios.post(`/agents/add`, getFormDataMultidimensional(params.data),
      {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }
    )
  },
  editAgent(params) {
    return axios.post(`/agents/${params.data.id}/update`, getFormDataMultidimensional(params.data),
      {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }
    )
  },
  deleteAgent(id) {
    return axios.delete(`/agents/${id}/delete`)
  }
}
