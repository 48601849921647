<template>
    <!-- Tag Option -->

      <!-- NAO ESQUECER DE VALIDAR ISTO -->
      <select-chips :key="reloadChips" @update-tag-id="updateTagID" class="mb-7" :chips="items" :tagID="form.tag_id == null ? 0 : form.tag_id"></select-chips>
    <!-- end -->
</template>

<script>
  import mixin from '@/models/properties/PropertiesModel'
  import { TagsForm } from '@/store/structs/tags'

  export default {
    name: 'PropertyTags',

    mixins: [mixin],

    data: () => ({
      dataLoading: true,
      formData: TagsForm,
      listTags: null,
      reloadChips: 0
    }),

    props: {
      form: {}
    },


    created() {
      this.formData = TagsForm
      
    },

    watch: {
      sync: {
        async handler() {
          try {
            this.dataLoading = true
            await this.getPropertyTags()
            this.dataLoading = false
            // eslint-disable-next-line no-unused-vars
          } catch (error) {
            console.log(error);
            this.dataLoading = false
          }
        },
        immediate: true,
        deep: true
      }
    },

    computed: {
      items() {
        let retrievedData = this.$store.state.propertiesModule.tags

        if(this.form.condominium_id == null){
            return Object.keys(retrievedData).map(k =>
              new TagsForm(retrievedData[k])
            )   
        }else{
          let finalArray = []
          retrievedData.forEach(element => {
            //console.log(element)
            if(element.id != 1)
              finalArray.push(element)
          });

          return finalArray
        }       
      }
    },
    methods: {
      updateTagID: function(id){
        this.$emit('update-tag-id', id, this.form.id)
      }
    }
  }
</script>
