<template>
    <v-footer
            light
            padless
    >
        <v-card
                flat
                class="white--text text-center w-full"
        >
            <v-card-text class="myFooter">
                {{ new Date().getFullYear() }} — {{ $t('navigationMenu.FOOTER_BUILT.software_built') }} <a target="_blank" href="https://icebergconsulting.io" class="primary--text text-decoration-none font-weight-bold">IcebergConsulting</a>
            </v-card-text>
        </v-card>
    </v-footer>
</template>

<script>
  export default {
    name: 'Footer'
  }
</script>