<template>
    <v-text-field
            class="v-input-text type-number"
            filled
            v-model.number="fillData"
            :label="label"
            append-outer-icon="mdi-plus"
            @click:append-outer="number = number + 1; $emit('input', number)"
            prepend-icon="mdi-minus"
            @click:prepend="number = number - 1; $emit('input', number)"
            @input="updateValue"
            @change="updateValue"
            @blur="onBlurAction"
    >
    </v-text-field>
</template>

<script>
  export default {
    name: 'InputNumber',

    props: {
      label: {
        type: String,
        default: 'Number'
      },
      rules: String,
      fill: Number
    },

    data: () => ({
      number: null
    }),

    watch: {
      fillData(val) {
        this.number = val
      }
    },

    computed: {
      fillData: {
        get() {
          return this.fill
        },
        set(value) {
          this.$emit('update:prop', value)
        }
      }
    },

    methods: {
      updateValue(e) {
        this.$emit('input', parseInt(e))
      },
      onBlurAction(){
        this.$emit('blur')
      }
    }
  }
</script>