<template>
    <v-img
            :src="$mediaFile('properties', media.id, media.cover)"
            :lazy-src="$mediaFile('properties', media.id, media.cover)"
            height="175"
            class="grey lighten-2 d-block rounded v-card__property-cover"
    >
              

        <!-- Agent -->
        <Agent :agent="agent" v-if="agent"/>
        <!-- end -->

        <template v-slot:placeholder>
            <v-row
                    class="fill-height ma-0"
                    align="center"
                    justify="center"
            >
                <v-progress-circular
                        indeterminate
                        color="grey lighten-5"
                ></v-progress-circular>
            </v-row>
        </template>
    </v-img>
    
</template>
 
<script>
  import Agent from '@/components/properties/card/Agent'

  export default {
    name: 'Media',
    components: { Agent },
    props: {
      media: {
        type: Object,
        default: null
      },
      agent: {
        type: Object,
        default: null
      },
      id: Number,
    }
  }
</script>
