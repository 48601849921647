<template>
    <!-- Tag Option -->
      <select-chips @update-tag-id="updateTagID" class="mb-7" :chips="items" :tagID="form.tag_id == null ? 0 : form.tag_id"></select-chips>
    <!-- end -->
</template>

<script>
  import mixin from '@/models/condominium/CondominiumsModel'
  import { TagsForm } from '@/store/structs/tags'

  export default {
    name: 'CondominiumTags',

    mixins: [mixin],

    data: () => ({
      dataLoading: true,
      formData: TagsForm,
      listTags: null
    }),

    props: {
      form: {}
    },


    created() {
      this.formData = TagsForm
    },

    watch: {
      sync: {
        async handler() {
          try {
            this.dataLoading = true
            await this.getCondominiumTags()
            this.dataLoading = false
            // eslint-disable-next-line no-unused-vars
          } catch (error) {
            this.dataLoading = false
          }
        },
        immediate: true,
        deep: true
      }
    },

    computed: {
      items() {
        let retrievedData = this.$store.state.propertiesModule.tags
        
        
        return Object.keys(retrievedData).map(k =>
          new TagsForm(retrievedData[k])
        )   
      }
    },
    methods: {
      updateTagID: function(id){
        this.$emit('update-tag-id', id, this.form.id)
      }
    }
  }
</script>
