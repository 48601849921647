<template>
    <v-menu
            bottom
            min-width="200px"
            rounded
            offset-y            
    >
        <template v-slot:activator="{ on }">
            <v-btn
                    icon
                    x-large
                    v-on="on"
                    class="v-btn__avatar"
            >
                <v-avatar
                        color="primary"
                        size="45"
                >
                    <span class="white--text" style="position: absolute;">{{ getInitials }}</span>
                    <img v-if="getSrc != null" :src="$mediaFile('users', getID, getSrc)"
                          :lazy-src="$mediaFile('users', getID, getSrc)"/>
                </v-avatar>
            </v-btn>
        </template>
        <v-card>
            <v-list-item-content class="justify-center">
                <div class="mx-auto text-center">
                    <v-avatar
                            color="brown"
                    >
                        <span class="white--text headline" style="position: absolute;">{{ getInitials }}</span>
                        <img v-if="getSrc != null" :src="$mediaFile('users', getID, getSrc)"
                          :lazy-src="$mediaFile('users', getID, getSrc)"/>
                    </v-avatar>
                    <h3>{{ getName }}</h3>
                    <p class="caption mt-1">
                        {{ getEmail }}
                    </p>
                    <v-divider class="my-3"></v-divider>
                    
                    <v-btn  to="/users/myprofile"
                            depressed
                            rounded
                            text
                    >
                        {{ $t('navigationMenu.TOOLBAR.edit') }}
                    </v-btn>
                    <v-divider class="my-3"></v-divider>
                    <v-btn
                            depressed
                            rounded
                            text
                            @click="userLogout"
                    >
                        {{ $t('navigationMenu.TOOLBAR.disconnect') }}
                    </v-btn>
                </div>
            </v-list-item-content>
        </v-card>
    </v-menu>
</template>

<script>
  import { User } from '@/store/structs/user'

  export default {
    name: 'Avatar',
    data() {
      return {
        activeUser: User,
        user: {
          initials: 'LA',
          fullName: 'John Doe',
          email: 'john.doe@doe.com'
        }
      }
    },
    created(){
      this.activeUser = new User(this.$store.state.auth.user)
      //console.log(this.activeUser)
    },
    computed:{
      getName(){
        return this.activeUser.name
      },
      getEmail(){
        return this.activeUser.email
      },
      getInitials(){
        return this.activeUser.initials
      },
      getSrc(){
        return this.activeUser.avatar
      },
      getID(){
        return this.activeUser.id
      }
    },

    methods: {
      userLogout() {
        this.$store.dispatch('userLogout')
      }
    }
  }
</script>
