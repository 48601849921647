export default {
  getNavigationList(caller){
    // List begin
    var list = {
      'main': {
        id: 1,
        title: '',
        items: [
          { title: caller.$t('navigationMenu.MENU_LINKS.dashboard'), icon: 'home', color: 'secondary', link: 'home', sub: false, tag: 'v-list-item' },
          {
            title: caller.$t('navigationMenu.MENU_LINKS.properties'),
            icon: 'edit-square-feather',
            color: 'secondary',
            link: 'properties',
            sub: true,
            tag: 'v-list-group',
            items: [
              { title: caller.$t('navigationMenu.MENU_LINKS.active'), link: 'properties/listings' },
              { title: caller.$t('navigationMenu.MENU_LINKS.sold'), link: 'properties/sold' },
              { title: caller.$t('navigationMenu.MENU_LINKS.archived'), link: 'properties/archived'},
              { title: caller.$t('navigationMenu.MENU_LINKS.low_cost'), link: 'properties/low_cost'}
            ]
          },
          { 
            title: caller.$t('navigationMenu.MENU_LINKS.developments'), 
            icon: 'kanban-board', 
            color: 'secondary', 
            link: 'condominiums', 
            sub: true, 
            tag: 'v-list-group',
            items: [
              { title: caller.$t('navigationMenu.MENU_LINKS.active'), link: 'condominiums/listings' },
              { title: caller.$t('navigationMenu.MENU_LINKS.archived'), link: 'condominiums/archived' }
            ] 
          },
          { title: caller.$t('navigationMenu.MENU_LINKS.key_items'), icon: 'layout-2', color: 'secondary', link: 'key-items', sub: false, tag: 'v-list-item' },
          { title: caller.$t('navigationMenu.MENU_LINKS.agents'), icon: 'user-circle', color: 'secondary', link: 'agents/listings', sub: false, tag: 'v-list-item' },
          { title: caller.$t('navigationMenu.MENU_LINKS.frontpage'), icon: 'edit-square', color: 'secondary', link: 'frontpage', sub: false, tag: 'v-list-item' },
          { title: caller.$t('navigationMenu.MENU_LINKS.districts'), icon: 'image', color: 'secondary', link: 'districts/listings', sub: false, tag: 'v-list-item' },
          { 
            title: caller.$t('navigationMenu.MENU_LINKS.this_is_la'), 
            icon: 'tablet', 
            color: 'secondary', 
            link: 'thisisla', 
            sub: true, 
            tag: 'v-list-group',
            items: [
              { title: caller.$t('navigationMenu.MENU_LINKS.general_services'), link: 'services/description' },
              { title: caller.$t('navigationMenu.MENU_LINKS.all_services'), link: 'services/listings' },            
              { title: caller.$t('navigationMenu.MENU_LINKS.who_we_are'), link: 'whoweare/description' }
            ] 
          },
          {
            title: caller.$t('navigationMenu.MENU_LINKS.inbox'),
            icon: 'cursor-square',
            color: 'secondary',
            link: 'inbox',
            sub: false,
            tag: 'v-list-item'
          },
        ]
      },
      'administration': {
        id: 2,
        title: caller.$t('navigationMenu.MENU_LINKS.administration'),
        items: [
          { title: caller.$t('navigationMenu.MENU_LINKS.users'), icon: 'face-id', color: 'primary', link: 'users', sub: false, tag: 'v-list-item', emit: 'system-users' },
          { title: caller.$t('navigationMenu.MENU_LINKS.settings'), icon: 'repository', color: 'primary', link: 'users/myprofile', sub: false, tag: 'v-list-item' },
          { 
            title: caller.$t('navigationMenu.MENU_LINKS.download'), 
            icon: 'floppy', 
            color: 'secondary', 
            link: 'download', 
            sub: true, 
            tag: 'v-list-group',
            items: [
              { title: caller.$t('navigationMenu.MENU_LINKS.csv_download'), link: 'download/csv' },
            ] 
          },
        ]
      }
    }
    // List end
    return list
  }
}
