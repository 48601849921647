export default [
  {
    path: '/',
    name: 'Main',
    redirect: 'home',
    meta: {
      requiresAuth: true
    },
  },
  {
    path: '/home',
    name: 'home',
    meta: {
      requiresAuth: true,
      title: '',
      subtitle: '',
      fluid: false,
      layout: 'main',
    },
    component: () =>
      import(/* webpackChunkName: "home" */ '@/pages/Dashboard.vue')
  }
]
