<template>
    <div class="p-menu__right">
        <v-card class="top-header custom-color text-left" flat color="#ffffff">
            <v-card-title class="subtitle-2 font-weight-bold">{{$t('INBOX.LABEL.TITLE')}}</v-card-title>
            <v-card-subtitle class="caption font-weight-light text-left black--text">{{$t('INBOX.LABEL.DESCRIPTION')}}
            </v-card-subtitle>

            <div class="d-flex flex-column">
                <v-chip
                        class="mx-2 mb-1 font-weight-bold"
                        label
                        text-color="darkgray"
                        color="transparent"
                        v-for="(label, index) in labels"
                        :key="index"
                        @click="groupMethod(label.action)"
                        :class="label.action == selected ? 'special-blue' : ''"
                >
                    <v-icon left :class="label.class" class="subtitle-1">
                        mdi-label
                    </v-icon>
                    {{ $processLanguage(label.name) }}
                </v-chip>
            </div>
        </v-card>
    </div>
</template>

<script>
  export default {
    name: 'Label',
    created(){
      this.selected = "All"
    },
    data: () => ({
      selected: '',
      labels: [
        { name: {en: 'All', pt: "Todos"}, slug: '', class: 'black--text text--lighten-2', action: 'All' },
        { name: {en: 'Book Visit', pt: "Agendar visita"}, slug: '', class: 'yellow--text text--lighten-2', action: 'bookVisit' },
        { name: {en: 'Zoom us', pt:"Zoom us"}, slug: '', class: 'green--text text--lighten-2', action: 'zoomUs' },
        { name: {en: 'Sell with us', pt:'Vende connosco'}, slug: '', class: 'purple--text text--lighten-2', action: 'sellWithUs' },
        { name: {en: 'Vineyards Form', pt: 'Formulário Vineyards'}, slug: '', class: 'orange--text text--lighten-2', action: 'vineyardsForm' },
        { name: {en: 'Work with me', pt: 'Trabalha comigo'}, slug: '', class: 'red--text text--lighten-2', action: 'workWithMe' }
      ]
    }),
    methods:{
      groupMethod(action){
        this.selected = action
        this.$emit('label-action', action)
      }
    }
  }
</script>

