import { mapActions } from 'vuex'

const mixin = {

  created: function() {
    console.debug(`Condominiums action called.`)
  },

  methods: {
    ...mapActions(['condominiumQuickChange', 'attributeCondominiumPlant', 'removingAllImages', 'reorderImageCondominium', 'removeMapMarkerCondominium', 'saveMapMarkersCondominium', 'removePermanentlyCondominium', 'unArchiveOneCondominium', 'archiveOneCondominium', 'getCondominiumTags', 'removeAttachmentCondominium', 'saveAttachmentCondominium', 'saveMediaFilesCondominium', 'saveFileGeneratedCondominium', 'removeImageCondominium', 'changeCoverImageCondominium', 'getCondominiums','getCondominiumBlock', 'saveCondominium', 'saveCondominiumBlock', 'editCondominium']),
    
    async save(form) {
      try {
        // Updating item
        if (form.id !== null) {
          // TO DO TO UPDATE
          await this.editCondominium({ 'data': form })
          // await this.getAgents()
        } else {
          // Creating new item
          await this.saveCondominium({ 'data': form })
          // await this.getAgents()
        }
      } catch (error) {
        this.dataTableLoading = false
      }
    },
    async removeAllImagesFromCondominium(id){
      try{
        // Delete all images
        await this.removingAllImages({'id': id})
      }catch(error){
        this.dataTableLoading = false
      }
    },
    async reorderImage(list){
      try{
        // SAVE MARKERS
        await this.reorderImageCondominium({'list': list})
      }catch(error){
        this.dataTableLoading = false
      }
    },
    async saveBlock(form, block) {
      try {
        // Updating item
        await this.saveCondominiumBlock({ 'data': form, 'block': block })
      } catch (error) {
        this.dataTableLoading = false
      }
    },

    async changeCoverImage(form, block){
      try{
        // Change Cover
        await this.changeCoverImageCondominium({'data': form})
      }catch(error){
        this.dataTableLoading = false
      }
    },

    async removeImageFromCondominium(form, block){
      
      try{
        // Change Cover
        await this.removeImageCondominium({'data': form})
      }catch(error){
        this.dataTableLoading = false
      }
    },

    async saveFileGeneratedFromCondominium(form, block){
      
      try{
        // Change Cover
        await this.saveFileGeneratedCondominium({'data': form})
      }catch(error){
        this.dataTableLoading = false
      }
    },

    async saveMediaFilesFromCondominium(form, block){
      
      try{
        // Change Cover
        await this.saveMediaFilesCondominium({'data': form})
      }catch(error){
        this.dataTableLoading = false
      }
    },

    async saveAttachmentFromCondominium(form, block){
      
      try{
        // Change Cover
        await this.saveAttachmentCondominium({'data': form})
      }catch(error){
        this.dataTableLoading = false
      }
    },

    async removeAttachmentFromCondominium(form, block){
      
      try{
        // Change Cover
        await this.removeAttachmentCondominium({'data': form})
      }catch(error){
        this.dataTableLoading = false
      }
    },
    async archiveCondominium(id){
      try{
        await this.archiveOneCondominium({'data': id})
      }catch(error){
        this.dataTableLoading = false
      }
    },
    async unArchiveCondominium(id){
      try{
        await this.unArchiveOneCondominium({'data': id})
      }catch(error){
        this.dataTableLoading = false
      }
    },
    async deleteCondominium(id){
      try{
        await this.removePermanentlyCondominium({'data': id})
      }catch(error){
        console.log(error)
        this.dataTableLoading = false
      }
    },
    async saveMapMarkers(id, data, gmapRoutes){
      try{
          // SAVE MARKERS
          await this.saveMapMarkersCondominium({'id': id, 'data': data, 'gmapRoutes': gmapRoutes})
        }catch(error){
          this.dataTableLoading = false
        }
    },
    async removeMapMarker(id){
      try{
          // SAVE MARKERS
          await this.removeMapMarkerCondominium({'id': id})
        }catch(error){
          this.dataTableLoading = false
        }
    },

  }
  

  
}

export default mixin