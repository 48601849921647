<template>
    <v-dialog
            :value="value" @input="$emit('input')"
            persistent
            max-width="600px"
    >
        <v-card>
            <v-card-title class="d-flex flex-column align-content-start align-start pb-5">
                <span class="title font-weight-light">{{ $t('USERS.SET.TITLE') }}</span>
                <span class="body-2 font-weight-light light-grey--text">{{ formTitle }}</span>
            </v-card-title>
            <v-card-text>
                <v-container class="mb-0">
                    <v-row>
                        <v-col cols="12" class="pb-0">
                            <ValidationObserver v-slot="{ handleSubmit }" ref="observer">
                                <form id="type-form" @submit.prevent="handleSubmit(submit)">
                                    <!-- Name -->
                                    <v-col cols="12" class="pa-0">
                                        <input-text
                                                name="name"
                                                :label="$t('USERS.NAME')"
                                                id="name"
                                                rules="required|min:5"
                                                v-model="form.name"
                                                :fill="form.name"
                                        >
                                        </input-text>
                                    </v-col>
                                    <!-- end -->
                                    <!-- Email -->
                                    <v-col cols="12" class="pa-0">
                                        <input-text
                                                name="email"
                                                :label="$t('USERS.EMAIL')"
                                                id="email"
                                                rules="required|email"
                                                v-model="form.email"
                                                :fill="form.email"
                                        >
                                        </input-text>
                                    </v-col>
                                    <!-- end -->
                                    <!-- Initials -->
                                    <v-col cols="12" class="pa-0">
                                        <input-text
                                                name="initials"
                                                :label="$t('USERS.INITIALS')"
                                                id="initials"
                                                rules="required"
                                                v-model="form.initials"
                                                :fill="form.initials"
                                        >
                                        </input-text>
                                    </v-col>
                                    <!-- end -->
                                    <!-- Password -->
                                    <v-col cols="12" class="pa-0">
                                        <input-text
                                                name="password"
                                                :label="form.id ? $t('USERS.SET.NEWPWD') : $t('USERS.SET.PWD')"
                                                id="password"
                                                :rules="form.id ? 'min:8' : 'required|min:8'"
                                                v-model="form.password"
                                                :fill="form.password"
                                                type="password"
                                        >
                                        </input-text>
                                    </v-col>
                                    <!-- end -->
                                </form>
                            </ValidationObserver>
                        </v-col>
                    </v-row>
                </v-container>
            </v-card-text>
            <v-card-actions>
                <v-btn color="transparent" elevation="-1" class="pa-0 ma-0 red--text" v-if="form.id && parseInt(form.id) !== parseInt(userID)"
                       @click="processDelete">
                    {{$t('BUTTONS_GENERAL.DELETE')}}
                </v-btn>
                <v-spacer></v-spacer>
                <v-btn depressed color="transparent" @click.native="$emit('input')">{{$t('INBOX.MODAL.CLOSE')}}</v-btn>
                <v-btn depressed color="primary" form="type-form" type="submit">{{$t('USERS.SAVE')}}</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
  import UserForm from '@/store/structs/user_form'

  export default {
    name: 'DialogFormUser',

    props: {
      value: Boolean,
      title: {
        type: String,
        default: 'Key'
      },
      typeData: {
        type: UserForm,
        default: null
      }
    },

    data: () => ({
      files: [],
      form: UserForm
    }),

    created() {
        this.form = new UserForm({})
    },

    watch: {
      value(val) {
        if (val) {
          if (this.typeData !== null) {
            this.form = { ...this.typeData }
          }
        }
      },
      typeData: function() {
        return new UserForm(this.typeData)
      }
    },

    methods: {
      processDelete() {
        this.$emit('delete-data', this.form)
      },
      async submit() {
        const isValid = await this.$refs.observer.validate()
        if (isValid) {
          this.$emit('form-data', this.form)
        }
      }
    },

    computed: {
      userID() {
        return this.$store.state.auth.user.id
      },
      formTitle() {
        return this.typeData && this.typeData.name
          ? this.$t('USERS.SET.EDIT') + this.typeData.name
          : this.$t('USERS.SET.NEW')
      }
    }
  }
</script>
