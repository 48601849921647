<template>
    <ValidationProvider :rules="rules" v-slot="{ errors }">
        <v-text-field
                class="v-input-text"
                filled
                :id="id"
                :name="name"
                :type="type"
                :label="label"
                :hint="hint"
                autocomplete="off"
                v-model="fillData"
                :error="errors.length > 0"
                :error-messages="errors[0]"
                @input="updateValue"
                @change="updateValue"
                @blur="onbluraction"
        ></v-text-field>
    </ValidationProvider>
</template>

<script>
  export default {
    name: 'InputText',

    props: {
      id: String,
      name: String,
      label: String,
      type: {
        type: String,
        default: 'text'
      },
      rules: String,
      fill: String,
      hint: String
    },

    computed: {
      fillData: {
        get() {
          return this.fill
        },
        set(value) {
          this.$emit('update:prop', value)
        }
      }
    },

    methods: {
      updateValue(e) {
        this.$emit('input', e)
      },
      onbluraction(){
        this.$emit('blur')
      }
    }
  }
</script>
