<template>
    <div class="px-4 text-left mb-3">
        <v-chip
                class="my-1 mr-1 v-kpi"
                color="#2f3136"
                text-color="white"
                small
                label
                v-for="(kpi, index) in kpis" :key="index"
        >
            <v-avatar
                    left
                    color="#ffffff"
            >
                {{ kpi }}
            </v-avatar>
            <span class="text-capitalize">{{ index }}</span>
        </v-chip>
    </div>
</template>

<script>
  export default {
    name: 'KPIS',
    props: {
      kpis: {
        type: Object,
        default: null
      }
    }
  }
</script>