<template>
    <div>
        <label v-if="label" class="d-block v-label theme--dark mb-2">{{ label }}</label>
        <v-chip-group               
                column
                mandatory
        >
            <v-chip
                    outlined
                    v-for="(chip, index) in chips"
                    :key="index"
                    :active-class="chip.activeClass"
                    :style="chip.id == tagID ? 'background-color: #BE5E3B !important; border: none;' : ''"
                    :color="checkChip(chip.id)"
                    @click="updateTagID(chip.id)"
            > 
                {{ $processLanguage(chip.i18n.tag) }}
            </v-chip>
        </v-chip-group>
    </div>
</template>

<script>
  export default {
    name: 'SelectChips',

    props: {
      label: String,
      chips: {
        type: Array,
        default: null
      },
      tagID: Number
    },
    created(){
      //console.log(this.chips)
    },
    methods: {
      checkChip: function(id){
        if(id == this.tagID)
          return "white";
        
        return "";
      },
      updateTagID: function(id){
        this.$emit('update-tag-id', id)
      }
    }
  }
</script>