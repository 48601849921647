<template>
    <v-dialog
            :value="value" @input="$emit('input')"
            persistent
            content-class="v-dialog-toast-editor"
            max-width="1400px"
    >
        <v-card>
            <v-card-title class="d-flex flex-column align-content-start align-start pb-5">
                <span class="title font-weight-light"> Image Editor </span>
                <span class="body-2 font-weight-light light-grey--text">Editing image file: name</span>
            </v-card-title>
            <v-card-text>
                <tui-image-editor
                        ref="tuiImageEditor"
                        :options="options"
                ></tui-image-editor>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn depressed color="transparent" @click.native="closeReset()">Close</v-btn>
                <v-btn depressed color="primary" form="type-form" type="submit" @click="save">Save</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
  // Please note that you need to install these plugins separately
  import { ImageEditor } from '@toast-ui/vue-image-editor'

  // Load Style Code
  import 'tui-image-editor/dist/tui-image-editor.css'
  import '@/assets/scss/plugins/toast.editor.css'

  export default {
    name: 'ImageEditor',
    props: {
      value: Boolean,
      imageData: {
        type: String,
        default: null
      },
      imageID: {
        type: String,
        default: null
      },
      mediaID: {
        type: String,
        default: null
      }
    },

    components: {
      'tui-image-editor': ImageEditor
    },

    data: () => ({
      checkEditor: null,
      useDefaultUI: true,
      options: {
        // for tui-image-editor component's "options" prop
        cssMaxWidth: 700,
        includeUI: {
          loadImage: {
            path:
              '/media/editor_empty.jpg',
            name: 'SampleImage'
          },
          menu: ['crop', 'rotate', 'mask', 'filter'],
          menuBarPosition: 'left'
        }
      }
    }),

    watch: {
      imageData: {
        handler: function(val) {
          this.initEditor(val)
        },
        deep: true
      }
    },

    methods: {
      initEditor: function(img) {
        this.checkEditor = setInterval(function() {
          if (typeof this.$refs.tuiImageEditor !== 'undefined') {
            this.invokeImage(img)
          }
        }.bind(this), 10)
      },

      closeReset() {
        clearInterval(this.checkEditor)
        this.$emit('input')
      },

      invokeImage(img) {
        this.$refs.tuiImageEditor.invoke(
          'loadImageFromURL',
          img,
          'SampleImage'
        )
        setTimeout(() => {
          clearInterval(this.checkEditor)
        }, 10)
      },

      save() {
        let file = this.dataURLtoFile(
          this.$refs.tuiImageEditor.invoke("toDataURL"),
          "img.png"
        );

        //console.log(file) // this generates file to send request
        this.$emit('generated-file', file)   
      },

      dataURLtoFile(dataurl, filename) {
        let arr = dataurl.split(","),
          mime = arr[0].match(/:(.*?);/)[1],
          bstr = atob(arr[1]),
          n = bstr.length,
          u8arr = new Uint8Array(n);

        while (n--) {
          u8arr[n] = bstr.charCodeAt(n);
        }

        return new File([u8arr], filename, { type: mime });
      }
    }
  }
</script>