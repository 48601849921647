<template>
    <div>
        <v-card-title class="subtitle-2 font-weight-light text-uppercase" style="word-break: normal;">
          {{ information.title }}
        </v-card-title>
        <v-card-subtitle class="caption text-left font-weight-light">
            <span class="primary--text">{{ information.location.district }}</span> - {{ information.location.type }}
        </v-card-subtitle>

        <!--<p class="caption px-4 text-left mt-0 mb-2" style="height: 60px">{{ information.description }}</p>
        -->
    </div>
</template>

<script>
  export default {
    name: 'Information',

    props: {
      information: Object
    }
  }
</script>
