<template>
    <div class="d-flex">
        <div v-for="(kpi, index) in kpis" :key="index">
            <v-chip
                    class="v-kpi"
                    :class="index > 1 ? ' ml-2' : ''"
                    color="darkgray"
                    text-color="black"
                    small
                    label
                    v-if="kpi !== null && removePopulation(kpi)"
            >
                <v-avatar
                        left
                        color="#ffffff"
                >
                    {{ kpi.value }}
                </v-avatar>
                <div v-if="multiLanguage">{{ $processLanguage(kpi.name) }}</div>
                <div v-else>{{kpi.name}}</div>
            </v-chip>
        </div>
    </div>
</template>

<script>
  export default {
    name: 'KPIS',
    props: {
      kpis: {
        type: Array,
        default: null
      },
      multiLanguage:{
        type: Boolean,
        defautl: false
      }
    },
    methods:{
      removePopulation(item){
        console.log(this.$processLanguage(item.name))
        if(this.$processLanguage(item.name) == "População" || this.$processLanguage(item.name) == "Population")
          return false

        return true
      }
    }
  }
</script>
