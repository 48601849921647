import axios from 'axios'
import { getFormData, getFormDataMultidimensional } from '@/utils/utils'

export default {
  
    getServiceDescription(params) {
      return axios.get('/services/description', {
        params
      })
    },
    getServices(params) {
      return axios.get('/services', {
        params
      })
    },
    saveService(params) {
      return axios.post(`/services/add`, getFormDataMultidimensional(params.data),
        {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }
      )
    },
    editService(params) {
      return axios.post(`/services/${params.data.id}/update`, getFormDataMultidimensional(params.data),
        {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }
      )
    },
    deleteService(id) {
      return axios.delete(`/services/${id}/delete`)
    },
    saveServiceDescriptionForm(params) {
      return axios.post(`/services/update/description`, getFormDataMultidimensional(params.data),
        {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }
      )
    },
    

}