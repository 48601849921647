<template>
    <v-menu offset-y>
        <template v-slot:activator="{ on, attrs }">
            <v-btn
                    color="primary"
                    elevation="0"
                    dark
                    v-bind="attrs"
                    v-on="on"
                    class="pa-0 v-actions-menu"
            >
                <i class="zwicon-more-h"></i>
            </v-btn>
        </template>
        <v-list>
            <v-list-item>
                <v-btn depressed color="white" block class="px-0" :to="'/agents/edit/' + agentId">Edit Agent</v-btn>
            </v-list-item>
            <v-list-item>
                <v-btn depressed color="white" @click="prevDelete" block class="px-0">Delete</v-btn>
            </v-list-item>
        </v-list>
    </v-menu>
</template>

<script>
  export default {
    name: 'CardMenu',

    props: {
      agentId: String,
    },

    methods: {
      prevDelete() {
        this.$emit('delete-action', this.agentId)
      }
    }

  }
</script>
