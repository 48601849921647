import * as types from '@/store/mutation-types'
import api from '@/services/api/frontend/frontend'
import { buildSuccess, handleError } from '@/utils/utils.js'
import router from '@/router'

const getters = {
    frontend: (state) => state.frontend,
}

const actions = {
    setFrontend({ commit }, payload) {
        return new Promise((resolve, reject) => {
          api
            .setFrontend(payload)
            .then((response) => {
              if (response.status === 201) {
                buildSuccess(
                  {
                    msg: 'common.SAVED_SUCCESSFULLY'
                  },
                  commit,
                  resolve,
                  router.push({
                    path:`/frontend/edit`
                  })
                )
              }
            })
            .catch((error) => {
              handleError(error, commit, reject)
            })
        })
      },
}

const mutations = {
  [types.FRONTEND](state, frontend) {
    state.frontend = frontend
  }
}

const state = {
    frontend: [],
}

export default {
  state,
  getters,
  actions,
  mutations
}
