export default class FeatureKey {
    constructor({ id = null, name = '', filterable= 1, icons = null, created = null, updated = null, master = 0 }) {
      this.id = id || null
      this.name = {
        en: name.en || '',
        pt: name.pt || '-'
      }
      this.master = master || 0
      this.filterable = filterable || 0
      this.icons = icons || ''
      this.created = created || ''
      this.updated = updated || ''
    }
  }