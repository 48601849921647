import axios from 'axios'

export default {
  getKeyItems(params) {
    return axios.get(`/key-items/${params.extra}`, {
      params
    })
  },
  saveKey(params) {
    return axios.post(`/key-items/${params.target}/add`, params.data)
  },
  editKey(params) {
    return axios.post(`/key-items/${params.target}/${params.data.id}/update`, params.data)
  },
  deleteKey(params) {
    return axios.delete(`/key-items/${params.target}/${params.id}/delete`)
  }
}
