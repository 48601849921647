import axios from 'axios'
import { getFormData, getFormDataMultidimensional } from '@/utils/utils'


export default {
  getCondominiums(params) {
    return axios.get('/condominiums', {
      params
    })
  },
  getCondominiumBlock(params) {
    return axios.get(`/condominiums/${params.id}/${params.block}`, {
      params
    })
  },
  getCondominiumTags(params){
    return axios.get(`/condominiums/getTags`, {
      params
    })
  },
  removingAllImages(params){
    return axios.post(`/condominiums/removeAllMedia/${params.id}`,
       {
         headers: {
           'Content-Type': 'multipart/form-data'
         }
       }
     )
  }, 
  saveCondominium(params) {
    return axios.post(`/condominiums/add`, getFormDataMultidimensional(params.data),
      {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }
    )
  },
  saveCondominiumBlock(params) {
    return axios.post(`/condominiums/${params.data._id}/${params.block}`, getFormDataMultidimensional(params.data),
      {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }
    )
  },
  editCondominium(params) {
    return axios.post(`/condominiums/${params.data.id}/update`, getFormDataMultidimensional(params.data),
      {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }
    )
  },
  changeCoverImageCondominium(params) {
    return axios.get(`/condominiums/${params.data.id}/${params.data.media_id}/media/cover`, getFormDataMultidimensional(params.data),
      {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }
    )
  },
  attributeCondominiumPlant(params) {
    return axios.get(`/condominiums/${params.id}/${params.media_id}/media/plant`, getFormDataMultidimensional(params.data),
      {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }
    )
  },  
  removeImageCondominium(params) {
    //console.log(params);
    return axios.post(`/condominiums/${params.data.id}/media/remove`, params.data.data,
      {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }
    )
  },
  saveFileGeneratedCondominium(params) {
    //console.log(params);
    return axios.post(`/condominiums/${params.data.id}/${params.data.media_id}/media/replace`, params.data.data,
      {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }
    )
  },
  saveMediaFilesCondominium(params) {
    //console.log(params);
    return axios.post(`/condominiums/${params.data.id}/media/upload`, params.data.data,
      {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }
    )
  },
  reorderImageCondominium(params){
    return axios.post(`/condominiums/location/reorder`,getFormDataMultidimensional(params.list),
       {
         headers: {
           'Content-Type': 'multipart/form-data'
         }
       }
     )
  },
  saveAttachmentCondominium(params) {
    //console.log(params);
    return axios.post(`/condominiums/${params.data.id}/attachment/upload`, params.data.data,
      {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }
    )
  },
  removeAttachmentCondominium(params) {
    //console.log(params);
    return axios.post(`/condominiums/${params.data.id}/attachment/remove`, params.data.data,
      {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }
    )
  },
  archiveOneCondominium(params) {
    //console.log(params);
    return axios.post(`/condominiums/${params.data.id}/archive`, params.data.data,
      {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }
    )
  },
  unArchiveOneCondominium(params) {
    //console.log(params);
    return axios.post(`/condominiums/${params.data.id}/unArchive`, params.data.data,
      {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }
    )
  },
  removePermanentlyCondominium(params) {
    //console.log(params);
    return axios.delete(`/condominiums/${params.data.id}/delete`, 
      {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }
    )
  },
  condominiumQuickChange(params){
    const formData = new FormData()
    formData.append('id', params.id)
    formData.append('block', params.block)
    formData.append('value', params.value)

    return axios.post(`/condominiums/quickChange`, formData,
       {
         headers: {
           'Content-Type': 'multipart/form-data'
         }
       }
     )
  },
  saveMapMarkersCondominium(params){
    const formData = new FormData()
    formData.append('lat', params.data[0].position.lat)
    formData.append('lng', params.data[0].position.lng)
    formData.append('radius', params.data[0].radius)
    formData.append('concelho', params.gmapRoutes.concelho)
    formData.append('distrito', params.gmapRoutes.distrito)
    formData.append('political', params.gmapRoutes.political)
    formData.append('al2', params.gmapRoutes.administrative_level_2)
    formData.append('al3', params.gmapRoutes.administrative_level_3)
    formData.append('routeLocation', params.gmapRoutes.routeLocation)
    return axios.post(`/condominiums/${params.id}/location`, formData,
       {
         headers: {
           'Content-Type': 'multipart/form-data'
         }
       }
     )
  },
  removeMapMarkerCondominium(params){
    return axios.post(`/condominiums/${params.id.id}/location/remove/${params.id.condo_id}`, getFormDataMultidimensional(params.id.data),
       {
         headers: {
           'Content-Type': 'multipart/form-data'
         }
       }
     )
  },  

  

  
  
  

}
