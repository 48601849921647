<template>
    <v-row class="pt-3">
        <v-col cols="3">
            <v-card flat>
                <div class="py-3 px-4">
                    <v-card-title class="principalCardTitle font-weight-medium">{{$t('DASHBOARD.PROPERTIES')}}</v-card-title>
                    <v-card-subtitle class="caption text-left font-weight-medium secondaryCard">
                        {{$t('DASHBOARD.TOTAL_PROPERTIES')}}
                    </v-card-subtitle>
                    <div class="px-5 text-right">
                        <p class="primary--text d-flex justify-space-between align-center">
                            <i class="zwicon-arrow-top-right secondary--text" style="font-size: 60px"></i>
                            <span class="font-weight-bold text-h2">+{{properties_sold_archived.properties_amount}}</span>
                        </p>
                        <p class="text-left">
                            <span class="font-weight-medium caption">
                                {{$t('DASHBOARD.PROPERTIES_REGISTERED')}}</span>
                        </p>
                    </div>
                </div>
            </v-card>
        </v-col>
        <v-col cols="3">
            <v-card flat>
                <div class="py-3 px-4">
                    <v-card-title class="principalCardTitle font-weight-medium">{{$t('DASHBOARD.SOLD')}}</v-card-title>
                    <v-card-subtitle class="caption text-left font-weight-medium secondaryCard">
                        {{$t('DASHBOARD.TOTAL_SOLD')}}
                    </v-card-subtitle>
                    <div class="px-5 text-right">
                        <p class="primary--text d-flex justify-space-between align-center">
                            <i class="zwicon-arrow-bottom-left red--text text--lighten-2" style="font-size: 60px"></i>
                            <span class="font-weight-bold text-h2">{{properties_sold_archived.totalSoldArchived}}</span>
                        </p>
                        <p class="text-left">
                            <span class="font-weight-medium caption">
                                <span class="primary--text">{{properties_sold_archived.sold}}</span> {{$t('DASHBOARD.SOLO_SOLD')}} / <span class="primary--text">{{properties_sold_archived.totalSoldArchived - properties_sold_archived.sold}}</span> {{$t('DASHBOARD.SOLO_ARCHIVED')}}</span>
                        </p>                    
                    </div>
                </div>
            </v-card>
        </v-col>
        <v-col cols="3">
            <v-card flat>
                <div class="py-3 px-4">
                    <v-card-title class="principalCardTitle font-weight-medium">{{$t('DASHBOARD.NEW_DEVELOPMENTS')}}</v-card-title>
                    <v-card-subtitle class="caption text-left font-weight-medium secondaryCard">
                        {{$t('DASHBOARD.TOTAL_DEVELOPMETNS')}}
                    </v-card-subtitle>
                    <div class="px-5 text-right">
                        <p class="primary--text d-flex justify-space-between align-center">
                            <i class="zwicon-arrow-top-right secondary--text" style="font-size: 60px"></i>
                            <span class="font-weight-bold text-h2">+{{condominiums_sold_archived.condominiums_amount}}</span>
                        </p>
                        <p class="text-left">
                            <span class="font-weight-medium caption">
                                {{$t('DASHBOARD.DEVELOPMENTS_REGISTERED')}}</span>
                        </p>
                    </div>
                </div>
            </v-card>
        </v-col>
        <v-col cols="3">
            <v-card flat>
                <div class="py-3 px-4">
                    <v-card-title class="principalCardTitle font-weight-medium">{{$t('DASHBOARD.DEVELOPMENTS_ARCHIVED')}}</v-card-title>
                    <v-card-subtitle class="caption text-left font-weight-medium secondaryCard">
                        {{$t('DASHBOARD.TOTAL_ARCHIVED_DEV')}}
                    </v-card-subtitle>
                    <div class="px-5 text-right">
                        <p class="primary--text d-flex justify-space-between align-center">
                            <i class="zwicon-arrow-bottom-left red--text text--lighten-2" style="font-size: 60px"></i>
                            <span class="font-weight-bold text-h2">{{condominiums_sold_archived.archived}}</span>
                        </p>  
                        <p class="text-left">
                            <span class="font-weight-medium caption">
                                {{$t('DASHBOARD.TOTAL_ARCHIVED_DEV2')}}</span>
                        </p>                      
                    </div>
                </div>
            </v-card>
        </v-col>
    </v-row>
</template>

<script>
  export default {
    name: 'WidgetsBadges',
    props: ['properties_sold_archived', 'condominiums_sold_archived']
  }
</script>
