<template>
    <div id="myModal" class="confirmationModal">

    <!-- Modal content -->
    <div class="modal-content-inbox">
        <v-card>
            <v-card-title class="d-flex flex-column align-content-start align-start pb-5 inbox-modal-header">
                <span class="subtitle font-weight-bold white--text">{{$t('INBOX.MODAL.TITLE')}}</span>
                <span class="body-2 font-weight-medium white--text">{{$t('INBOX.MODAL.RECEIVED')}} {{item.timer}}</span>
            </v-card-title>
            <v-card-text>
                <v-container class="mb-0">
                    <v-row>
                        <v-col cols="2" class="pb-0">
                            <p class="font-weight-bold black--text mt-4 ml-0">{{$t('INBOX.MODAL.MSG_TITLE')}}</p>
                        </v-col>
                        <v-col cols="10" class="pb-0">
                            <p class="black--text mt-4 ml-0 modal-message-text">{{item.body.subject}}</p>
                        </v-col>

                        <v-col cols="2" class="pb-0">
                            <p class="font-weight-bold black--text ml-0">{{$t('INBOX.MODAL.FROM')}}</p>
                        </v-col>
                        <v-col cols="4" class="pb-0">
                            <p class="black--text ml-0 modal-message-text">{{item.sender.name}}
                            <span class="black--text ml-0 modal-message-email">({{ item.sender.email }})</span></p>
                        </v-col>

                        <v-col cols="2" class="pb-0">
                            <p class="font-weight-bold black--text ml-0">{{$t('INBOX.MODAL.TO')}}</p>
                        </v-col>
                        <v-col cols="4" class="pb-0">
                            <p class="black--text ml-0 modal-message-text">{{item.agent}}</p>
                        </v-col>

                        

                        <v-col cols="2" class="pb-0">
                            <p class="font-weight-bold black--text ml-0">{{$t('INBOX.MODAL.MESSAGE')}}</p>
                        </v-col>
                        <v-col cols="10" class="pb-0">
                            <p class="black--text ml-0 modal-message-text">{{item.body.text}}</p>
                        </v-col>

                        <v-col cols="12" class="pb-0">
                            <v-btn @click="$emit('close-me')" color="#D8D8D8" label class="px-6 py-6 backBTN" depressed small>
                                <i class="zwicon-sign-out"></i>{{$t('INBOX.MODAL.CLOSE')}}   
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-container>
            </v-card-text>
        </v-card>
    </div>

    </div>
</template>
<script>
export default {
    name: 'InboxModal',
    props: ['item'],
    created(){
        console.log(this.item)
    }
}
</script>