export default [
  {
    path: '/agents',
    name: 'Agents',
    component: () => import('@/pages/Agents'),
    meta: {
      layout: 'main',
      requiresAuth: true,
    },
    children: [
      {
        path: '/agents/listings',
        name: 'AgentsListings',
        meta: {
          title: 'LIST OF AGENTS',
          subtitle: 'All agents added to platform for sailing',
          fluid: true
        },
        component: () =>
          import(/* webpackChunkName: "home" */ '@/pages/agents/Listings')
      },
    ]
  },
  {
    path: '/agents/add',
    name: 'newAgent',
    meta: {
      layout: 'main',
      title: 'ADD NEW AGENT',
      subtitle: 'Form to add new agent to your platform',
      fluid: false
    },
    component: () =>
      import(/* webpackChunkName: "home" */ '@/pages/agents/SetAgent')
  },
  {
    path: '/agents/edit/:id',
    name: 'editAgent',
    meta: {
      layout: 'main',
      title: 'SAVE A AGENT',
      subtitle: 'Form to update an agent registered',
      fluid: false
    },
    component: () =>
      import(/* webpackChunkName: "home" */ '@/pages/agents/SetAgent')
  },
  {
    path: '/agents/profile/:slug',
    name: 'viewProfile',
    meta: {
      title: 'PROFILE AGENT',
      subtitle: 'View all information related to this agent',
      fluid: false
    },
    component: () =>
      import(/* webpackChunkName: "home" */ '@/pages/agents/ProfileAgent')
  }
]
