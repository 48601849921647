<template>
    <v-app>
        <v-main class="text-center">
            <Navigation :key="reloadMenuBar" @system-users="changeDrawerStatus" class="myNavigation"/>
            <div class="content__app_toolbar">
              <Toolbar @language-changed="reloadMenuBar++"/>
            </div>
            <Loading/>
            <div class="content__app">
                <Header v-if="false"/>
                <v-container fluid class="content-container">
                    <transition name="fade" mode="out-in">
                        <router-view></router-view>
                    </transition>
                </v-container>
            </div>
        </v-main>
        <!-- Action for add new row -->
        <FloatAction v-if="$route.meta.action" v-show="false"/>
        <!-- end -->
        <!-- Info Message -->
        <InfoMessage />
        <!-- end -->
        <div class="footer__content__app">
          <!-- Footer -->
          <Footer />
          <!-- end -->
        </div>
        <!-- System Users -->
        <DrawerUsers :drawer="drawerUsers" />
        <!-- end -->
    </v-app>
</template>

<script>
  import Toolbar from '@/components/core/Toolbar'
  import Loading from '@/components/core/Loading'
  import Navigation from '@/components/core/Navigation'
  import Header from '@/components/core/Header'
  import Footer from '@/components/core/Footer'
  import DrawerUsers from '@/components/system/DrawerUsers'

  export default {
    name: 'Main',

    components: {
      DrawerUsers,
      Footer,
      Header,
      Navigation,
      Toolbar,
      Loading,
    },

    data: () => ({
      drawerUsers: false,
      reloadMenuBar: 0
    }),
    
    methods: {
      changeDrawerStatus(e) {
        this.drawerUsers = new Date().getTime() + '_' + e
      }
    }
  }
</script>

<style>
    .fade-enter-active, .fade-leave-active {
        transition-property: opacity;
        transition-duration: .1s;
    }

    .fade-enter-active {
        transition-delay: .1s;
    }

    .fade-enter, .fade-leave-active {
        opacity: 0
    }
</style>
