export default [
    {
      path: '/frontpage',
      name: 'Frontpage',
      component: () => import('@/pages/frontpage/Frontpage'),
      meta: {
        layout: 'main',
        requiresAuth: true
      },
      children: [  ]
    },
  ]
  