<template>
    <v-radio-group v-model="fillData" :color="color" :mandatory="mandatory">
        <v-radio
                v-for="(item, index) in items"
                :key="index"
                :label="$processLanguage(item.name)"
                :value="item.id"
                :color="color"
                @click="updateValue"
        ></v-radio>
    </v-radio-group>
</template>

<script>
  export default {
    name: 'InputRadio',

    props: {
      items: {
        type: Array,
        default: null
      },
      label: {
        type: String,
        default: 'Radio'
      },
      color: {
        type: String,
        default: 'secondary'
      },
      status: {
        type: Boolean,
        default: false
      },
      mandatory: {
        type: Boolean,
        default: false
      },
      customClass: String,
      fill: [Number, String]
    },

    computed: {
      fillData: {
        get() {
          return this.fill
        },
        set(value) {
          this.$emit('input', value)
          this.$emit('update:prop', value)
        }
      }
    },

    methods: {
      updateValue(e) {
        // this.$emit('input', this.radioGroup)
        this.$emit('blur')
      }
    }
  }
</script>
