<template>
    <div>
        <!-- Listings -->
        <label class="d-block v-label theme--light mb-4 mt-10 black--text font-weight-bold">{{$t('PROPERTIES.LISTING_TYPE')}}:</label>

        <v-progress-circular v-if="dataLoading"
                             indeterminate
                             color="primary"
        ></v-progress-circular>

        <input-radio
                :items="items"
                v-model="formData" :fill="parseInt(form)"
        ></input-radio>
        <!-- end -->
    </div>
</template>

<script>
  import { buildPayloadPagination } from '@/utils/utils'
  import mixin from '@/models/keys/KeyModel'

  export default {
    name: 'Listings',

    mixins: [mixin],

    data: () => ({
      dataLoading: true,
      pagination: {},
      target: 'listing',
      formData: []
    }),

    props: {
      form: {}
    },

    methods: {
      buildSearch() {
        return this.search
          ? { query: this.search, fields: this.fieldsToSearch.join(',') }
          : {}
      }
    },

    watch: {
      form: {
        handler: function(val) {
          this.formData = val
        },
        deep: true
      },
      formData: {
        handler: function(val) {
          this.$emit('get-listings', val)
        },
        deep: true
      },
      pagination: {
        async handler() {
          try {
            this.dataLoading = true
            await this.getKeyItems(
              buildPayloadPagination({}, this.buildSearch(), this.target)
            )
            this.dataLoading = false
          } catch (error) {
            this.dataLoading = false
          }
        },
        deep: true,
        immediate: true
      }
    },

    computed: {
      items() {
        console.log(this.$store.state.keysModule.key.listing.data)
        return this.$store.state.keysModule.key.listing.data
      }
    }
  }
</script>
