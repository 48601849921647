import * as types from '@/store/mutation-types'
import api from '@/services/api/inbox/inbox'
//import { buildSuccess, handleError } from '@/utils/utils.js'
//import router from '@/router'

const getters = {
  inbox: (state) => state.inbox,
  totalInbox: (state) => state.totalInbox
}

const actions = {
    getInbox({ commit }, payload) {
    return new Promise((resolve, reject) => {
      api
        .getInbox(payload)
        .then((response) => {
          if (response.status === 200) {
            commit(types.INBOX, response.data.inbox)
            commit(types.TOTAL_INBOX, response.data.totalInbox)
            resolve()
          }
        })
        .catch((error) => {
          handleError(error, commit, reject)
        })
    })
  },
}


const mutations = {
    [types.INBOX](state, inbox) {
      state.inbox = inbox
    },
    [types.TOTAL_INBOX](state, value) {
      state.totalInbox = value
    }
  }
  
  const state = {
    inbox: [],
    totalInbox: 0,
  }
  
  export default {
    state,
    getters,
    actions,
    mutations
  }
  