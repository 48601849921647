<template>
    <v-text-field
            class="v-input-text type-number"
            filled
            v-model.number="fillData"
            :label="label"
            append-outer-icon="mdi-plus"
            @click:append-outer="addNumber()"
            prepend-icon="mdi-minus"
            @click:prepend="subtractNumber()"
            @input="updateValue"
            @change="updateValue"
            @blur="analyzeInput"
    >
    </v-text-field>
</template>

<script>
  export default {
    name: 'SpecialInputNumber',

    props: {
      label: {
        type: String,
        default: 'Number'
      },
      rules: String,
      fill: String
    },

    data: () => ({
      number: null,
      numberFlag: false
    }),
    created(){
      if(this.fill != null && this.fill != undefined)
        this.number = this.fill
    },

    watch: {
      fillData(val) {
        this.number = val
      }
    },

    computed: {
      fillData: {
        get() {
          return this.fill
        },
        set(value) {
          this.$emit('update:prop', value)
        }
      }
    },

    methods: {
      analyzeInput(){
        if(isNaN(+this.number))
          this.numberFlag = true
        else
          this.numberFlag = false

        this.$emit('on-blur-event', this.number)
      },
      updateValue(e) {
        this.$emit('input', e)
      },
      addNumber(){
          console.log(this.number)
          if(isNaN(+this.number))
            this.number = -1

          this.numberFlag = false

          this.number = +this.number + 1; 

          this.$emit('input', this.number)
          this.$emit('on-blur-event', this.number)
          return this.number
      },
      subtractNumber(){
          if(isNaN(+this.number))
            this.number = 1

          this.numberFlag = false

          this.number = +this.number - 1; 
          if(this.number < 0)
            this.number = 0
          
          this.$emit('input', this.number)
          this.$emit('on-blur-event', this.number)
          return this.number
      }
    }
  }
</script>