export default [
  {
    path: '/properties',
    name: 'Properties',
    component: () => import('@/pages/Properties'),
    meta: {
      layout: 'main',
      requiresAuth: true
    },
    children: [
      {
        path: '/properties/listings',
        name: 'PropertiesListings',
        meta: {
          title: 'ACTIVE LISTINGS',
          subtitle: 'All properties currently displayed on your website',
          fluid: true
        },
        component: () =>
          import(/* webpackChunkName: "home" */ '@/pages/properties/Listings')
      },
      {
        path: '/properties/sold',
        name: 'sold',
        meta: {
          title: 'PROPERTIES SOLD',
          subtitle: 'List of all properties sold at the moment',
          fluid: true
        },
        component: () =>
          import(/* webpackChunkName: "home" */ '@/pages/properties/Sold')
      },
      {
        path: '/properties/archived',
        name: 'archived',
        meta: {
          title: 'PROPERTIES ARCHIVED',
          subtitle: 'List of all properties archived',
          fluid: true
        },
        component: () =>
          import(/* webpackChunkName: "home" */ '@/pages/properties/Archived')
      },
      {
        path: '/properties/low_cost',
        name: 'low_cost',
        meta: {
          title: 'LOW COST PROPERTIES',
          subtitle: 'List of all low cost properties',
          fluid: true
        },
        component: () =>
          import(/* webpackChunkName: "home" */ '@/pages/properties/LowCost')
      }
    ]
  },
  {
    path: '/properties/new',
    meta: {
      layout: 'main',
      title: 'NEW PROPERTY',
      subtitle: 'Add new property to your platform',
      fluid: false
    },
    component: () =>
      import(/* webpackChunkName: "home" */ '@/pages/properties/actions/AddProperty'),
    children: [
      {
        path: '/',
        name: 'property-general',
        component: () =>
          import(/* webpackChunkName: "home" */ '@/components/properties/form/General')
      }
    ]
  },
  {
    path: '/properties/edit/:slug/',
    meta: {
      layout: 'main',
      title: 'EDIT PROPERTY',
      subtitle: 'Edit a property named ´Cascais Village House´ to your platform',
      fluid: false
    },
    component: () =>
      import(/* webpackChunkName: "home" */ '@/pages/properties/actions/AddProperty'),
    children: [
      {
        path: 'general/exporterlog',
        name: 'property-general-exportations-log',
        component: () =>
          import(/* webpackChunkName: "home" */ '@/components/properties/form/ExportationLogs')
      },
      {
        path: 'general/checkInsertion/:thePlace',
        name: 'property-general-insertion-checker',
        component: () =>
          import(/* webpackChunkName: "home" */ '@/components/properties/form/General')
      },
      {
        path: 'general',
        name: 'property-general-edit',
        component: () =>
          import(/* webpackChunkName: "home" */ '@/components/properties/form/General')
      },
      {
        path: 'media',
        name: 'property-media-edit',
        component: () =>
          import(/* webpackChunkName: "home" */ '@/components/properties/form/Media')
      },
      {
        path: 'location',
        name: 'property-location-edit/:slug',
        component: () =>
          import(/* webpackChunkName: "home" */ '@/components/properties/form/Location')
      },
      {
        path: 'seo',
        name: 'property-seo-edit/:slug',
        component: () =>
          import(/* webpackChunkName: "home" */ '@/components/properties/form/SEO')
      },
      {
        path: 'collection',
        name: 'property-collection-edit/:slug',
        component: () =>
          import(/* webpackChunkName: "home" */ '@/components/properties/form/Collection')
      },
      {
        path: 'attachments',
        name: 'property-attachments-edit/:slug',
        component: () =>
          import(/* webpackChunkName: "home" */ '@/components/properties/form/Attachments')
      }
    ]
  }
]
