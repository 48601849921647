import axios from 'axios'
import { getFormDataMultidimensional } from '@/utils/utils'

export default {
  getProperties(params) {
    return axios.get('/properties', {
      params
    })
  },
  getPropertyBlock(params) {
    return axios.get(`/property/${params.id}/${params.block}`, {
      params
    })
  },
  saveProperty(params) {
    return axios.post(`/property/add`, getFormDataMultidimensional(params.data),
      {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }
    )
  },
  propertyQuickChange(params){
    const formData = new FormData()
    formData.append('id', params.id)
    formData.append('block', params.block)
    formData.append('value', params.value)

    return axios.post(`/property/quickChange`, formData,
       {
         headers: {
           'Content-Type': 'multipart/form-data'
         }
       }
     )
  },
  savePropertyBlock(params) {
    return axios.post(`/property/${params.data._id}/${params.block}`, getFormDataMultidimensional(params.data),
      {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }
    )
  },
  editProperty(params) {
    return axios.post(`/property/${params.data.id}/update`, getFormDataMultidimensional(params.data),
      {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }
    )
  },
  getPropertiesFromCondominium(params) {
    return axios.get(`/properties/from_condominium/${params.id}`, {
      params
    })
  },
  getSelectListCondominiums(params){
    return axios.get(`/properties/getCondominiumsList`, {
      params
    })
  },
  getPropertyTags(params){
    return axios.get(`/properties/getTags`, {
      params
    })
  },
  saveMapMarkersProperty(params){
    const formData = new FormData()
    formData.append('lat', params.data[0].position.lat)
    formData.append('lng', params.data[0].position.lng)
    formData.append('radius', params.data[0].radius)
    formData.append('concelho', params.gmapRoutes.concelho)
    formData.append('distrito', params.gmapRoutes.distrito)
    formData.append('political', params.gmapRoutes.political)
    formData.append('al2', params.gmapRoutes.administrative_level_2)
    formData.append('al3', params.gmapRoutes.administrative_level_3)
    formData.append('routeLocation', params.gmapRoutes.routeLocation)
    return axios.post(`/property/${params.id}/location`, formData,
       {
         headers: {
           'Content-Type': 'multipart/form-data'
         }
       }
     )
  },
  removeMapMarkerProperty(params){
    return axios.post(`/property/${params.id.id}/location/remove`, getFormDataMultidimensional(params.id.data),
       {
         headers: {
           'Content-Type': 'multipart/form-data'
         }
       }
     )
  },  
  reorderImageProperty(params){
    return axios.post(`/property/location/reorder`,getFormDataMultidimensional(params.list),
       {
         headers: {
           'Content-Type': 'multipart/form-data'
         }
       }
     )
  },
  removingAllImages(params){
    return axios.post(`/property/removeAllMedia/${params.id}`,
       {
         headers: {
           'Content-Type': 'multipart/form-data'
         }
       }
     )
  },  
  saveAttachmentProperty(params) {
     //console.log(params);
     return axios.post(`/property/${params.data.id}/attachment/upload`, params.data.data,
       {
         headers: {
           'Content-Type': 'multipart/form-data'
         }
       }
     )
   },
   removeAttachmentProperty(params) {
    //console.log(params);
    return axios.post(`/property/${params.data.id}/attachment/remove`, params.data.data,
      {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }
    )
  },
  saveMediaFilesProperty(params) {
    //console.log(params);
    return axios.post(`/property/${params.data.id}/media/upload`, params.data.data,
      {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }
    )
  },
  saveFileGeneratedProperty(params) {
    //console.log(params);
    return axios.post(`/property/${params.data.id}/${params.data.media_id}/media/replace`, params.data.data,
      {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }
    )
  },
  changeCoverImageProperty(params) {
    return axios.get(`/property/${params.data.id}/${params.data.media_id}/media/cover`, getFormDataMultidimensional(params.data),
      {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }
    )
  },
  attributePlant(params) {
    return axios.get(`/property/${params.id}/${params.media_id}/media/plant`, getFormDataMultidimensional(params.data),
      {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }
    )
  },
  removeImageProperty(params) {
    //console.log(params);
    return axios.post(`/property/${params.data.id}/media/remove`, params.data.data,
      {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }
    )
  },
  archiveOneProperty(params) {
    //console.log(params);
    return axios.post(`/property/${params.data.id}/archive`, params.data.data,
      {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }
    )
  },
  unArchiveOneProperty(params) {
    //console.log(params);
    return axios.post(`/property/${params.data.id}/unArchive`, params.data.data,
      {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }
    )
  },
  removePermanentlyProperty(params) {
    //console.log(params);
    return axios.delete(`/property/${params.data.id}/delete`, 
      {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }
    )
  },
  


  
  
  
  
  
}
