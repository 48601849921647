export default [
    {
      path: '/whoweare',
      name: 'Who We Are',
      component: () => import('@/pages/WhoWeAre'),
      meta: {
        layout: 'main',
        requiresAuth: true,
      },
      children: [
        {
          path: '/whoweare/description',
          name: 'WhoWeAreDescription',
          meta: {
            title: 'Who We Are DESCRIPTION',
            subtitle: 'Who we are text appearing at the Front Office',
            fluid: true
          },
          component: () =>
            import(/* webpackChunkName: "home" */ '@/pages/whoweare/SetWhoWeAre'),
        },
    ]
  }
  
]