<template>
  <v-menu v-model="showMenu" offset-y>
    <template v-slot:activator="{ on }">
      <v-btn class="btnLocaleActivation" v-on="on" text>
        <v-icon class="mr-2">mdi-earth</v-icon>
        &nbsp;{{ displayLocale.toUpperCase() }}
      </v-btn>
    </template>
    <v-list>
      <v-list-item
        active-class="white--text"
        v-for="(item, i) in langs"
        :key="`Lang${i}`"
        :value="item.lang"
        @click="switchLocale(item.lang)"
        :class="[item.class]"
      >
        <country-flag :country="item.flag" size="small" />
        <v-list-item-title class="ml-3">{{
          item.lang.toUpperCase()
        }}</v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
import CountryFlag from 'vue-country-flag'

export default {
  data() {
    return {
      showMenu: false,
      langs: [
        {
          lang: 'en',
          flag: 'gb',
          class: 'btnEN'
        },
        {
          lang: 'pt',
          flag: 'pt',
          class: 'btnPT'
        },
      ]
    }
  },
  components: {
    CountryFlag
  },
  methods: {
    switchLocale(lang) {
      this.$store.dispatch('setLocale', lang)
      this.$emit('language-changed')
    }
  },
  computed: {
    displayLocale() {
      return ' ' + this.$i18n.locale
    },
    getFlag(){
      return this.flag;
    }
  }
}
</script>

<style>
.flag {
  width: 118px !important;
}

.v-list-item {
  min-width: 185px !important;
}
</style>
