<template>
    <div>
        <v-checkbox
                v-for="(item, index) in items"
                :key="index"
                :class="customClass"
                :label="getLabel(item)"
                v-model="checkbox"
                :color="color"
                :value="item.id"
                @input="updateValue"
                @change="updateValue"
        ></v-checkbox>
    </div>
</template>

<script>
  export default {
    name: 'InputCheckboxMultiple',

    props: {
      items: {
        type: Array,
        default: null
      },
      label: {
        type: String,
        default: 'Checkbox'
      },
      color: {
        type: String,
        default: 'secondary'
      },
      status: {
        type: Number,
        default: 1
      },
      customClass: String,
      fill: Array
    },

    data() {
      return {
        checkbox: []
      }
    },

    computed: {
      fillData: {
        get() {
          return this.fill
        },
        set(value) {
          this.$emit('update:prop', value)
        }
      }
    },

    watch: {
      fill: {
        handler: function(val) {
          if (typeof val !== 'undefined') {
            this.checkbox = val
          }
        },
        deep: true,
        immediate: true
      }
    },

    methods: {
      updateValue(e) {
        this.$emit('input', e)
      },
      getLabel(item){
        return this.$processLanguage(item.name)
      }
    }
  }
</script>
