export class Condominium {
  constructor(object = null)  
  {
    this.id = object.id
    this.starting_price = object.starting_price
    this.tag_id = object.tag_id
    this.agent_id = object.agent_id
    this.district_id  = object.district_id
    this.condo_where  = object.condo_where ?? 'no description provided'
    this.cover = object.cover || 'https://www.keystoneproperties.net/wp-content/themes/keystoneproperties/img/property-placeholder.png'
    this.information = {
      title: object.title || '-',
      slogan: object.slogan || '-',
      description: object.description || '-'
    }
    this.archived = object.archived
    this.from_gross_area  = object.from_gross_area == -1 ? '-' : object.from_gross_area
    this.to_gross_area  = object.to_gross_area == -1 ? '-' : object.to_gross_area
    this.from_bedrooms  = object.from_bedrooms == -1 ? '-' : object.from_bedrooms
    this.to_bedrooms  = object.to_bedrooms == -1 ? '-' : object.to_bedrooms
    this.from_parking  = object.from_parking == -1 ? '-' : object.from_parking
    this.to_parking  = object.to_parking == -1 ? '-' : object.to_parking
    this.hide_price  = object.hide_price
    this.highlighted = object.highlighted
    this.agent_name  = object.name
    this.lat  = object.lat == '' || object.lat == 0 ? null : object.lat
    this.len  = object.len == '' || object.len == 0 ? null : object.len
    this.administrative_area_level_2 = object.administrative_area_level_2 || null
    this.political = object.political || null
    this.administrative_area_level_3 = object.administrative_area_level_3 || null
  }
}

export class CondominiumForm {
  constructor(
  {
    id = null,
    condo_where = String(),
    bedrooms = Number(),
    bathrooms = Number(),
    tag_id = String(),
    i18n = {
      title: Array(),
      description: Array(),
      slogan: Array()
    },
    cover = String(),
    types = Array(),
    search = Array(),
    highlighted = Number(),
    construction = String(),
    construction_area_type = String(),
    lote_size = Number(),
    lote_size_type = String(),
    land_size = Number(),
    land_size_type = String(),
    agent_id = Number(),
    starting_price = Number(),
    district_id = Number(),
    for_sale = Number(),
    hide_price = Number(),
    from_gross_area = Number(),
    to_gross_area = Number(),
    from_bedrooms = Number(),
    to_bedrooms = Number(),
    from_parking = Number(),
    to_parking = Number(),
  }) {
    this.id = id || null
    this.condo_where = condo_where || null
    this.tag_id = tag_id || null
    this.i18n = {
      title: { en: i18n.title.en || '', pt: i18n.title.pt || '' },
      description: { en: i18n.description.en || '', pt: i18n.description.pt || '' },
      slogan: { en: i18n.slogan.en || '', pt: i18n.slogan.en || '' }
    }
    this.starting_price = starting_price || null
    this.bedrooms = bedrooms || 0
    this.bathrooms = bathrooms || 0
    this.cover = cover || null
    this.types = types || null
    this.search = search || null
    this.agent_id = agent_id || null
    this.construction = construction
    this.construction_area_type = construction_area_type
    this.lote_size = lote_size
    this.lote_size_type = lote_size_type
    this.land_size = land_size
    this.land_size_type = land_size_type
    this.district_id  = district_id
    this.for_sale  = for_sale
    this.hide_price  = hide_price
    this.from_gross_area  = from_gross_area == -1 ? '-' : from_gross_area
    this.to_gross_area  = to_gross_area == -1 ? '-' : to_gross_area
    this.from_bedrooms  = from_bedrooms == -1 ? '-' : from_bedrooms
    this.to_bedrooms  = to_bedrooms == -1 ? '-' : to_bedrooms
    this.from_parking  = from_parking == -1 ? '-' : from_parking
    this.to_parking  = to_parking == -1 ? '-' : to_parking
    this.highlighted = highlighted || 0
  }
} 

export class CondominiumSEOForm {
  constructor(
    {
      id = String(),
      title = String(),
      slug = String(),
      description = String()
    }) {
    this._id = id || null
    this.title = title
    this.slug = slug
    this.description = description
  }
}

export default { Condominium, CondominiumForm, CondominiumSEOForm }
