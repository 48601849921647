<template>
    <v-card
              flat
              color="transparent"
            >
              <v-subheader>{{title}}</v-subheader>

              <v-card-text>
                <v-row>
                  <v-col class="px-4">
                    <v-range-slider
                      v-model="rangeSliderRange"
                      :max="max"
                      :min="min"
                      hide-details
                      class="align-center"
                    >
                      <template v-slot:prepend>
                        <v-text-field
                          :value="rangeSliderRange[0]"
                          class="mt-0 pt-0"
                          hide-details
                          single-line
                          type="number"
                          style="width: 35px"
                          @change="$set(rangeSliderRange, 0, $event)"
                        ></v-text-field>
                      </template>
                      <template v-slot:append>
                        <v-text-field
                          :value="rangeSliderRange[1]"
                          class="mt-0 pt-0"
                          hide-details
                          single-line
                          type="number"
                          style="width: 35px"
                          @change="$set(rangeSliderRange, 1, $event)"
                        ></v-text-field>
                      </template>
                    </v-range-slider>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
</template>

<script>
  export default {
    name: 'RangeSlider',
    props: {
      title:{
        type: String,
        default: ''
      },
      range: {
        type: Array,
        default: function() {
          return [0, 100]
        }
      },
      max: {
        type: Number,
        default: 10
      },
      min: {
        type: Number,
        default: 0
      }
    },
    data(){
      return{
        rangeSliderRange: null
      }
    },
    created(){
      this.rangeSliderRange = this.range
    },
    watch:{
      rangeSliderRange(){
        this.$emit('change', this.rangeSliderRange)
      }
    },
  }
</script>