<template>
    <v-chip
            class="ma-1 left-top-side text-capitalize"
            small
            v-if="tag"
            :color="colors[tag].bg"
            :text-color="colors[tag].text"
    >
        {{ tag }}
    </v-chip>
</template>

<script>
  export default {
    name: 'Tag',
    props: {
      tag: {
        default: '',
        type: String
      }
    },
    data: () => ({
      colors: {
        'sold': {
          bg: 'red lighten-1',
          text: 'white'
        },
        'new': {
          bg: 'green lighten-1',
          text: 'white'
        },
        'reserved': {
          bg: 'orange darken-1',
          text: 'white'
        }
      }
    }),
  }
</script>
