<template>
    <div > 
        <div v-for="(location, index) in locationDividers" :key="index">
            <label  class="subtitle-2 master-chip font-weight-bold pt-2 px-0">{{ $processLanguage(location.name) }}</label>
            <v-chip-group               
                    column                    
                    multiple
            >
            <template v-for="(chip, index) in chips">
            <v-chip
                    outlined
                    :key="index"
                    v-if="chip.master == location.id"
                    :style="insideArray(chip.id) ? 'background-color: #BE5E3B !important; color: white; border: none;' : ''"
                    :active-class="chip.activeClass"  
                    @click="updateMasterChip(chip.id)" 
            > 
                    {{ $processLanguage(chip.name) }}
                </v-chip>
            </template>
            </v-chip-group>
        </div>
    </div>
</template>

<script>
  import LocationDividersOrder from '@/data/location_dividers_order'
  import FeatureKey from '@/store/structs/featureKey'
  import { buildPayloadPagination } from '@/utils/utils'
  import mixin from '@/models/keys/KeyModel'
  export default {
    name: 'SelectMasterChips',
    mixins: [mixin],
    data(){
        return{
            pagination: {},
            targetLocation: 'master',
            locationDividers: [],
            newSelectedChipds: []
        }
    },
    props: {
      label: String,
      chips: {
        type: Array,
        default: null
      },
      selectedValues: Array
    },
    async created(){
        // Get master keys
        await this.getKeyItems(
            buildPayloadPagination(this.pagination, this.buildSearch(), this.targetLocation),
        )
        // Create the master keys and save them
        let retrievedData = this.$store.state.keysModule.key.master.data
        let locationDividersHelper = []
        retrievedData.forEach(element => {
            locationDividersHelper.push(new FeatureKey(element))
        });

        if(LocationDividersOrder != null){
            LocationDividersOrder.forEach(locationElement => {
                locationDividersHelper.forEach(dividerElement => {
                    if(dividerElement.name.en == locationElement){
                        this.locationDividers.push(dividerElement)
                    }
                });            
            });
        }else this.locationDividers = locationDividersHelper

    },
    
    methods: {
        buildSearch() {
            return this.search
                ? { query: this.search, fields: this.fieldsToSearch.join(',') }
                : {}
        },
        insideArray(id){
            // se undefined é porque estamos a criar algo novo
            if(this.selectedValues == undefined)
                return this.newSelectedChipds.includes(id)

            return this.selectedValues.includes(id)
        },
        updateMasterChip(e){
            // se undefined é porque estamos a criar algo novo, então é só adicionar lá para dentro ou remover
            if(this.selectedValues == undefined){
                if(this.newSelectedChipds.includes(e)){
                    let index = this.newSelectedChipds.indexOf(e);
                    if(index > -1){
                        this.newSelectedChipds.splice(index, 1);
                    }
                }else
                    this.newSelectedChipds.push(e)
                    
                    
                this.$emit('input', this.newSelectedChipds)
            }else{
                let selecionados = this.selectedValues
                if(selecionados.includes(e)){
                    let index = selecionados.indexOf(e);
                    if(index > -1){
                        selecionados.splice(index, 1);
                    }
                }else
                    selecionados.push(e)
                
                this.$emit('input', selecionados)
            }
        }
    }
  }
</script>