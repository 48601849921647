export class Agent {
  constructor(object = null) {
    this.id = object.id
    this.name = object.first_name + ' ' + object.last_name
    this.avatar = object.avatar || 'https://eu.ui-avatars.com/api/?font-size=0.25&bold=true&name=' + this.name
    this.description = object.description || 'no description provided'
    this.role = object.role || '-'
    this.email = object.email
    this.total_properties = object.total_properties
  }
}

export class AgentForm {
  constructor({
                id = null,
                first_name = '',
                last_name = '',
                avatar = '',
                role = '',
                phone = '',
                email = '',
                description = ''
              }) {
    this.id = id || null
    this.first_name = first_name
    this.last_name = last_name
    this.avatar = avatar
    this.role = {
      pt: role.pt,
      en: role.en
    }
    this.phone = phone
    this.email = email
    this.description = {
      pt: description.pt,
      en: description.en
    }
  }
}


export default { Agent, AgentForm }