import i18n from '@/plugins/i18n'
import * as types from '@/store/mutation-types'
import { isPast, format, parseISO } from 'date-fns'
import { store } from '@/store'
import { es, zhCN } from 'date-fns/locale'

const localesDateFns = {
  es,
  cn: zhCN
}

export const getFormat = (date, formatStr) => {
  return format(parseISO(date), formatStr, {
    locale: localesDateFns[window.__localeId__]
  })
}

export const formatErrorMessages = (translationParent, msg) => {
  const errorArray = []
  // Check for error msgs
  if (msg !== null) {
    const json = JSON.parse(JSON.stringify(msg))
    // If error message is an array, then we have mutiple errors
    if (Array.isArray(json)) {
      json.map((error) => {
        errorArray.push(i18n.t(`${translationParent}.${error.msg}`))
      })
    } else {
      // Single error
      errorArray.push(i18n.t(`${translationParent}.${msg}`))
    }
    return errorArray
  }
  // all good, return null
  return null
}

export const getFormData = (object) => Object.keys(object).reduce((formData, key) => {
  formData.append(key, object[key])
  return formData
}, new FormData())


export const getFormDataMultidimensional = (data, property) => {  
  function appendFormData(_data, _property, _formData) {
    const excluded = [File, Date, Blob].reduce((acc, cls) => {
      return acc || _data instanceof cls
    }, false)
    if (_data && typeof _data === 'object' && !excluded) {
      for (const [k, value] of Object.entries(_data)) {
        appendFormData(value, _property ? `${_property}[${k}]` : k, _formData)
      }
    } else {
      _formData.append(
        _property,
        _data !== undefined && _data !== null ? _data : ''
      )
    }

    return _formData
  }

  return appendFormData(data, property, new FormData())
}

export const buildPayloadPagination = (pagination, search, extra = null) => {
  const { page, itemsPerPage } = pagination
  let { sortDesc, sortBy } = pagination

    // When sorting you always get both values
  if (Object.keys(pagination).length > 0) {
    if (sortBy.length === 1 && sortDesc.length === 1) {
      // Gets order
      sortDesc = sortDesc[0] === true ? -1 : 1
      // Gets column to sort on
      sortBy = sortBy ? sortBy[0] : ''
    }
  }

  let query = {}
  // If search and fields are defined
  if (search) {
    query = {
      sort: sortBy,
      order: sortDesc,
      page,
      limit: itemsPerPage,
      filter: search.query,
      fields: search.fields,
      extra: extra
    }
  } else {
    // Pagination with no filters
    query = {
      sort: sortBy,
      order: sortDesc,
      page,
      limit: itemsPerPage,
      extra: extra
    }
  }

  return query
}

// Catches error connection or any other error (checks if error.response exists)
export const handleError = (error, commit, reject) => {
  let errMsg = ''
  // Resets errors in store
  commit(types.SHOW_LOADING, false)
  commit(types.ERROR, null)

  // Checks if unauthorized
  if (error.response.code === 401) {
    store.dispatch('userLogout')
  } else {
    // Any other error
    errMsg = error.response
      ? error.response.data.errors.msg
      : 'SERVER_TIMEOUT_CONNECTION_ERROR'
    setTimeout(() => {
      return errMsg
        ? commit(types.ERROR, errMsg)
        : commit(types.SHOW_ERROR, false)
    }, 0)
  }
  reject(error)
}

export const handleInfo = (msg) => {
  store.commit(types.SHOW_LOADING, false)
  store.commit(types.INFO, null)

  setTimeout(() => {
    return msg ? store.commit(types.INFO, msg) : store.commit(types.SHOW_INFO, false)
  }, 0)
}

export const buildSuccess = (
  msg,
  commit,
  resolve,
  resolveParam = undefined
) => {
  commit(types.SHOW_LOADING, false)
  commit(types.SUCCESS, null)
  setTimeout(() => {
    return msg ? commit(types.SUCCESS, msg) : commit(types.SHOW_SUCCESS, false)
  }, 0)
  commit(types.ERROR, null)
  resolve(resolveParam)
}

// Checks if tokenExpiration in localstorage date is past, if so then trigger an update
export const checkIfTokenNeedsRefresh = () => {
  // Checks if time set in localstorage is past to check for refresh token
  if (
    window.localStorage.getItem('token') !== null &&
    window.localStorage.getItem('tokenExpiration') !== null
  ) {
    if (
      isPast(
        new Date(
          parseISO(JSON.parse(window.localStorage.getItem('tokenExpiration'))) *
          1000
        )
      )
    ) {
      store.dispatch('refreshToken')
    }
  }
}
