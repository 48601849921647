import * as types from '@/store/mutation-types'

const getters = {
  showInfoMessage: (state) => state.infoMessage,
  infoMessage: (state) => state.infoMessage,
  infoMessageParams: (state) => state.infoMessageParams,
  infoMessageTimeout: (state) => state.infoMessageTimeout
}

const mutations = {
  [types.INFO](state, payload) {
    if (payload === null) {
      state.showInfoMessage = false
      state.infoMessage = null
      state.infoMessageParams = []
    } else {
      state.showInfoMessage = true
      state.infoMessageTimeout =
        payload.timeout === 0 ? 0 : payload.timeout || 6000
      state.infoMessage = payload.msg
      if (payload.params) {
        state.infoMessageParams = payload.params
      }
    }
  },
  [types.SHOW_INFO](state, payload) {
    state.showInfoMessage = !!payload
  }
}

const state = {
  showInfoMessage: false,
  infoMessage: null,
  infoMessageParams: [],
  infoMessageTimeout: -1
}

export default {
  state,
  getters,
  mutations
}
