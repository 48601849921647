import axios from 'axios'
//import { getFormData, getFormDataMultidimensional } from '@/utils/utils'


export default {
  getCSV(params) {
    return axios.get('/administration/contacts/downloadCSV', {
      params
    })
  },
}
