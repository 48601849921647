export class District {
  constructor(object) {
    this.id = object.id
    this.name = object.name.en ?? object.name.pt
    this.description = object.description.en ?? 'no description provided'
    this.cover = object.cover || 'http://placehold.jp/20/ffffff/202225/900x250.png?text=' + this.name
    this.cover2 = object.cover2 || 'http://placehold.jp/20/ffffff/202225/900x250.png?text=' + this.name
    this.cover3 = object.cover3 || 'http://placehold.jp/20/ffffff/202225/900x250.png?text=' + this.name
    this.cover4 = object.cover4 || 'http://placehold.jp/20/ffffff/202225/900x250.png?text=' + this.name
    
    this.kpis = [
      this.population = object.population > 0 ? { 'name': 'Population', 'value': object.population } : null,
      this.properties = object.properties > 0 ? { 'name': 'Properties', 'value': object.properties } : null,
      this.golf_club = object.golf_club > 0 ? { 'name': 'Golf Club', 'value': object.golf_club } : null
    ]
    this.districts_title1 = object.districts_title1.en ?? object.districts_title1.pt
    this.districts_title2 = object.districts_title2.en ?? object.districts_title2.pt
   
    this.districts1 = object.districts1.en ?? object.districts1.pt
    this.districts2 = object.districts2.en ?? object.districts2.pt
    this.districts3 = object.districts3.en ?? object.districts3.pt
    this.districts4 = object.districts4.en ?? object.districts4.pt
    this.districts5 = object.districts5.en ?? object.districts5.pt

    this.key_point1 = object.key_point1.en ?? object.key_point1.pt
    this.key_point2 = object.key_point2.en ?? object.key_point2.pt
    this.key_point3 = object.key_point3.en ?? object.key_point3.pt
    this.key_point4 = object.key_point4.en ?? object.key_point4.pt

  }
}

export class DistrictTranslated {
  constructor(object) {
    this.id = object.id
    this.name = object.name.en ?? object.name.pt
    this.description = {
      en: object.description.en,
      pt: object.description.pt
    }
    
    this.cover = object.cover || 'http://placehold.jp/20/ffffff/202225/900x250.png?text=' + this.name
    this.cover2 = object.cover2 || 'http://placehold.jp/20/ffffff/202225/900x250.png?text=' + this.name
    this.cover3 = object.cover3 || 'http://placehold.jp/20/ffffff/202225/900x250.png?text=' + this.name
    this.cover4 = object.cover4 || 'http://placehold.jp/20/ffffff/202225/900x250.png?text=' + this.name
    
    this.kpis = [
      this.population = object.population > 0 ? { 'name': {en: 'Population', pt: 'População'}, 'value': object.population } : null,
      this.properties = object.properties > 0 ? { 'name': {en: 'Properties', pt: 'Propriedades'}, 'value': object.properties } : null,
      this.golf_club = object.golf_club > 0 ? { 'name': {en: 'Golf Club', pt:"Clubes de Golf"}, 'value': object.golf_club } : null
    ]



  }
}

export class DistrictForm {
  constructor({
                id = null,
                name = '',
                description = '',
                cover = '',
                cover2 = '',
                cover3 = '',
                cover4 = '',
                kpis = Array,
                districts_title1 = '',
                districts_title2 = '',
                districts1 = '',
                districts2 = '',
                districts3 = '',
                districts4 = '',
                districts5 = '',
                key_point1 = '',
                key_point2 = '',
                key_point3 = '',
                key_point4 = '',
                did_you_know = '',
                to_visit = '',
                map = '',
                presents = ''
              }) {
    this.id = id || null
    this.name = {
      en: name.en || '',
      pt: name.pt || ''
    }    
    this.description = {
      en: description.en || '',
      pt: description.pt || ''
    }   
    this.cover = cover
    this.cover2 = cover2
    this.cover3 = cover3
    this.cover4 = cover4
    this.kpis = {
      population: kpis.population,
      properties: kpis.properties,
      golf_club: kpis.golf_club
    }

    this.districts_title1 = {
      en: districts_title1.en || '',
      pt: districts_title1.pt || ''
    }
    this.districts_title2 = {
      en: districts_title2.en || '',
      pt: districts_title2.pt || ''
    }

    this.districts1 = {
      en: districts1.en || '',
      pt: districts1.pt || ''
    }
    this.districts2 = {
      en: districts2.en || '',
      pt: districts2.pt || ''
    }
    this.districts3 = {
      en: districts3.en || '',
      pt: districts3.pt || ''
    }
    this.districts4 = {
      en: districts4.en || '',
      pt: districts4.pt || ''
    }
    this.districts5 = {
      en: districts5.en || '',
      pt: districts5.pt || ''
    }

    this.key_point1 = {
      en: key_point1.en || '',
      pt: key_point1.pt || ''
    }
    this.key_point2 = {
      en: key_point2.en || '',
      pt: key_point2.pt || ''
    }
    this.key_point3 = {
      en: key_point3.en || '',
      pt: key_point3.pt || ''
    }
    this.key_point4 = {
      en: key_point4.en || '',
      pt: key_point4.pt || ''
    }
    this.did_you_know = {
      en: did_you_know.en || '',
      pt: did_you_know.pt || ''
    }
    this.to_visit = {
      en: to_visit.en || '',
      pt: to_visit.pt || ''
    }
    this.map = map
    this.presents = {
      en: presents.en || '',
      pt: presents.pt || ''
    }
  }
}

export default { District, DistrictForm }