import * as types from '@/store/mutation-types'
import api from '@/services/api/properties/properties'
import { buildSuccess, handleError } from '@/utils/utils.js'
import router from '@/router'

const getters = {
  properties: (state) => state.properties,
  block: (state) => state.block
}

const actions = {
  getProperties({ commit }, payload) {
    return new Promise((resolve, reject) => {
      api
        .getProperties(payload)
        .then((response) => {
          if (response.status === 200) {
            commit(types.PROPERTIES, response.data.properties)
            resolve()
          }
        })
        .catch((error) => {
          handleError(error, commit, reject)
        })
    })
  },
  getPropertyBlock({ commit }, payload) {
    return new Promise((resolve, reject) => {
      api
        .getPropertyBlock(payload)
        .then((response) => {
          if (response.status === 200) {
            commit(types.PROPERTY_BLOCK, response.data.block)
            resolve()
          }
        })
        .catch((error) => {
          handleError(error, commit, reject)
        })
    })
  },
  saveProperty({ commit }, payload) {
    
    return new Promise((resolve, reject) => {
      api
        .saveProperty(payload)
        .then((response) => {
          if (response.status === 201) {
            buildSuccess(
              {
                msg: 'common.SAVED_SUCCESSFULLY'
              },
              commit,
              resolve,
              router.push({
                path:`/properties/edit/${response.data.property_id}/general`
              })
            )
          }
        })
        .catch((error) => {
          handleError(error, commit, reject)
        })
    })
  },
  savePropertyBlock({ commit }, payload) {
    return new Promise((resolve, reject) => {
      api
        .savePropertyBlock(payload)
        .then((response) => {
          if (response.status === 202) {
            buildSuccess(
              {
                msg: 'common.SAVED_SUCCESSFULLY'
              },
              commit,
              resolve
            )
          }
        })
        .catch((error) => {
          handleError(error, commit, reject)
        })
    })
  },
  editProperty({ commit }, payload) {
    return new Promise((resolve, reject) => {
      api
        .editProperty(payload)
        .then((response) => {
          if (response.status === 202) {
            buildSuccess(
              {
                msg: 'common.SAVED_SUCCESSFULLY'
              },
              commit,
              resolve
            )
          }
        })
        .catch((error) => {
          handleError(error, commit, reject)
        })
    })
  },
  getPropertiesFromCondominium({ commit }, payload) {
    return new Promise((resolve, reject) => {
      api
        .getPropertiesFromCondominium(payload)
        .then((response) => {
          if (response.status === 200) {
            commit(types.PROPERTIES, response.data.properties)
            resolve()
          }
        })
        .catch((error) => {
          handleError(error, commit, reject)
        })
    })
  },
  getSelectListCondominiums({ commit }, payload) {
    return new Promise((resolve, reject) => {
      api
        .getSelectListCondominiums(payload)
        .then((response) => {
          if (response.status === 200) {
            commit(types.PROPERTIES, response.data.properties)
            resolve()
          }
        })
        .catch((error) => {
          console.log(error)
          handleError(error, commit, reject)
        })
    })
  },
  getPropertyTags({ commit }, payload) {
    return new Promise((resolve, reject) => {
      api
        .getPropertyTags(payload)
        .then((response) => {
          if (response.status === 200) {
            //console.log(response.data.tags);
            commit(types.TAGS, response.data.tags)
            resolve()            
          }
        })
        .catch((error) => {
          handleError(error, commit, reject)
        })
    })
  },
  saveMapMarkersProperty({ commit }, payload) {
    return new Promise((resolve, reject) => {
      api
        .saveMapMarkersProperty(payload)
        .then((response) => {
          if (response.status === 202) {
            buildSuccess(
              {
                msg: 'common.SAVED_SUCCESSFULLY'
              },
              commit,
              resolve
            )
          }
        })
        .catch((error) => {
          handleError(error, commit, reject)
        })
    })
  },
  removeMapMarkerProperty({ commit }, payload) {
    return new Promise((resolve, reject) => {
      api
        .removeMapMarkerProperty(payload)
        .then((response) => {
          if (response.status === 202) {
            buildSuccess(
              {
                msg: 'common.SAVED_SUCCESSFULLY'
              },
              commit,
              resolve
            )
          }
        })
        .catch((error) => {
          handleError(error, commit, reject)
        })
    })
  },  
  reorderImageProperty({ commit }, payload) {
    return new Promise((resolve, reject) => {
      api
        .reorderImageProperty(payload)
        .then((response) => {
          if (response.status === 202) {
            buildSuccess(
              {
                msg: 'common.SAVED_SUCCESSFULLY'
              },
              commit,
              resolve
            )
          }
        })
        .catch((error) => {
          handleError(error, commit, reject)
        })
    })
  },  

  
  saveAttachmentProperty({ commit }, payload) {
    return new Promise((resolve, reject) => {
      api
        .saveAttachmentProperty(payload)
        .then((response) => {
          if (response.status === 201) {
            buildSuccess(
              {
                msg: 'common.SAVED_SUCCESSFULLY'
              },
              commit,
              resolve
            )
          }
        })
        .catch((error) => {
          handleError(error, commit, reject)
        })
    })
  },
  removeAttachmentProperty({ commit }, payload) {
    return new Promise((resolve, reject) => {
      api
        .removeAttachmentProperty(payload)
        .then((response) => {
          if (response.status === 201) {
            buildSuccess(
              {
                msg: 'common.SAVED_SUCCESSFULLY'
              },
              commit,
              resolve
            )
          }
        })
        .catch((error) => {
          handleError(error, commit, reject)
        })
    })
  },
  saveMediaFilesProperty({ commit }, payload) {
    return new Promise((resolve, reject) => {
      api
        .saveMediaFilesProperty(payload)
        .then((response) => {
          if (response.status === 201) {
            buildSuccess(
              {
                msg: 'common.SAVED_SUCCESSFULLY'
              },
              commit,
              resolve
            )
          }
        })
        .catch((error) => {
          handleError(error, commit, reject)
        })
    })
  },
  saveFileGeneratedProperty({ commit }, payload) {
    return new Promise((resolve, reject) => {
      api
        .saveFileGeneratedProperty(payload)
        .then((response) => {
          if (response.status === 201) {
            buildSuccess(
              {
                msg: 'common.SAVED_SUCCESSFULLY'
              },
              commit,
              resolve
            )
          }
        })
        .catch((error) => {
          handleError(error, commit, reject)
        })
    })
  },
  propertyQuickChange({ commit }, payload) {
    return new Promise((resolve, reject) => {
      api
        .propertyQuickChange(payload)
        .then((response) => {
          if (response.status === 202) {
            buildSuccess(
             {
                msg: 'common.SAVED_SUCCESSFULLY'
              },
              commit,
              resolve
            )
          }
        })
        .catch((error) => {
          handleError(error, commit, reject)
        })
    })
  },
  changeCoverImageProperty({ commit }, payload) {
    return new Promise((resolve, reject) => {
      api
        .changeCoverImageProperty(payload)
        .then((response) => {
          if (response.status === 201) {
            buildSuccess(
             {
                msg: 'common.SAVED_SUCCESSFULLY'
              },
              commit,
              resolve
            )
          }
        })
        .catch((error) => {
          handleError(error, commit, reject)
        })
    })
  },
  attributePlant({ commit }, payload) {
    return new Promise((resolve, reject) => {
      api
        .attributePlant(payload)
        .then((response) => {
          if (response.status === 201) {
            buildSuccess(
             {
                msg: 'common.SAVED_SUCCESSFULLY'
              },
              commit,
              resolve
            )
          }
        })
        .catch((error) => {
          handleError(error, commit, reject)
        })
    })
  },
  removeImageProperty({ commit }, payload) {
    return new Promise((resolve, reject) => {
      api
        .removeImageProperty(payload)
        .then((response) => {
          if (response.status === 201) {
            buildSuccess(
              {
                msg: 'common.SAVED_SUCCESSFULLY'
              },
              commit,
              resolve
           )
         }
        })
        .catch((error) => {
          handleError(error, commit, reject)
        })
    })
  },
  removingAllImages({ commit }, payload) {
    return new Promise((resolve, reject) => {
      api
        .removingAllImages(payload)
        .then((response) => {
          if (response.status === 201) {
            buildSuccess(
              {
                msg: 'common.SAVED_SUCCESSFULLY'
              },
              commit,
              resolve
           )
         }
        })
        .catch((error) => {
          handleError(error, commit, reject)
        })
    })
  },
  archiveOneProperty({ commit }, payload) {
    return new Promise((resolve, reject) => {
      api
        .archiveOneProperty(payload)
        .then((response) => {
          if (response.status === 202) {
            buildSuccess(
              {
                msg: 'common.SAVED_SUCCESSFULLY'
              },
              commit,
              resolve
           )
         }
        })
        .catch((error) => {
          handleError(error, commit, reject)
        })
    })
  },
  unArchiveOneProperty({ commit }, payload) {
    return new Promise((resolve, reject) => {
      api
        .unArchiveOneProperty(payload)
        .then((response) => {
          if (response.status === 202) {
            buildSuccess(
              {
                msg: 'common.SAVED_SUCCESSFULLY'
              },
              commit,
              resolve
           )
         }
        })
        .catch((error) => {
          handleError(error, commit, reject)
        })
    })
  },
  removePermanentlyProperty({ commit }, payload) {
    return new Promise((resolve, reject) => {
      api
        .removePermanentlyProperty(payload)
        .then((response) => {
          if (response.status === 200) {
            buildSuccess(
              {
                msg: 'common.SAVED_SUCCESSFULLY'
              },
              commit,
              resolve
           )
         }
        })
        .catch((error) => {
          handleError(error, commit, reject)
        })
    })
  },
  
  
  
  
}

const mutations = {
  [types.PROPERTIES](state, properties) {
    state.properties = properties
  },
  [types.TAGS](state, tags) {
    state.tags = tags
  },
  [types.PROPERTY_BLOCK](state, block) {
    state.block = block
  },
  [types.AGENT](state, list) {
    state.agent = list
  },
  [types.TOTAL_AGENTS](state, value) {
    state.totalAgents = value
  }
}

const state = {
  properties: [],
  block: [],
  totalAgents: 0,
  agent: [],
  tags: []
}

export default {
  state,
  getters,
  actions,
  mutations
}