<template>
    <div :class="customClass"
         :style="style" style="width: 100%">
        <v-chip
                class="ma-2"
                small
                absolute
                color="orange"
        >
            Not available at the moment
        </v-chip>
    </div>
</template>

<script>
  export default {
    name: 'NotAvailable',

    props: {
      height: {
        type: String,
        default: 'auto'
      },
      customClass: {
        type: String,
        default: ''
      }
    },

    computed: {
      style () {
        return 'height: ' + this.height + 'px';
      }
    }
  }
</script>