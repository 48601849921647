<template>
    <v-checkbox
            :class="customClass"
            :label="label"
            v-model="statusData"
            :color="color"
            @input="updateValue"
            @change="updateValue"
    ></v-checkbox>
</template>

<script>
  export default {
    name: 'InputCheckbox',

    props: {
      label: {
        type: String,
        default: 'Checkbox'
      },
      color: {
        type: String,
        default: 'secondary'
      },
      status: {
        type: Number,
        default: 1
      },
      customClass: String
    },

    data() {
      return {
        checkbox: status
      }
    },

    computed: {
      statusData: {
        get() {
          return this.status
        },
        set(value) {
          this.$emit('update:prop', value)
        }
      }
    },

    methods: {
      updateValue(e) {
        let value = 1
        if (!e){
          value = 0
        }
        this.$emit('input', value)
      }
    }
  }
</script>
