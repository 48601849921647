import axios from 'axios'
//import { getFormData, getFormDataMultidimensional } from '@/utils/utils'


export default {
    getInbox(params) {
    return axios.get('/inbox', {
      params
    })
  },
}