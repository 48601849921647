<template>
    <div>
        <v-progress-circular v-if="dataLoading"
                             indeterminate
                             color="primary"
        ></v-progress-circular>

        <!-- Types -->
        <input-select
                v-model="formData" 
                :fill="parseInt(form)"
                @input="$emit('on-blur-event')"
                :label="$t('PROPERTIES.TYPES')" :items="items" :item-text="'name.'+$i18n.locale" item-value="id">
        </input-select>
        <!-- end -->
    </div>
</template>

<script>
  import { buildPayloadPagination } from '@/utils/utils'
  import mixin from '@/models/keys/KeyModel'

  export default {
    name: 'Types',

    mixins: [mixin],

    data: () => ({
      dataLoading: true,
      pagination: {},
      target: 'types',
      formData: null
    }),

    props: {
      form: {}
    },

    methods: {
      buildSearch() {
        return this.search
          ? { query: this.search, fields: this.fieldsToSearch.join(',') }
          : {}
      }
    },

    watch: {
      formData: {
        handler: function(val) {
          this.$emit('get-types', val)
        },
        deep: true
      },
      pagination: {
        async handler() {
          try {
            this.dataLoading = true
            await this.getKeyItems(
              buildPayloadPagination({}, this.buildSearch(), this.target)
            )
            this.dataLoading = false
          } catch (error) {
            this.dataLoading = false
          }
        },
        deep: true,
        immediate: true
      }
    },

    computed: {
      items() {
        return this.$store.state.keysModule.key.types.data
      }
    }
  }
</script>