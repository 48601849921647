export class Property {
  constructor(object = null) {
    this.id = object.id || null,
    this.collection = object.collection
    this.tag_id = object.tag_id || null,
    this.price = object.price || 0,
    this.condominium_id = object.condominium_id || null
    this.reference = object.reference || ''
    this.archived = object.archived || 0
    this.information = {
      title: object.title || '-',
      description: object.description || '-',
      location: {
        district: object.district.name || '',
        type: object.type.name || ''
      }
    }
    this.kpis = {
      beds: object.bedrooms || '-',
      baths: object.bathrooms || '-',
      parking: object.parking || '-',
      m2: object.gross_area || '-',
      exterior: object.exterior_area || '-',
      living_area: object.living_area || '-',
    }
    this.media = {
      id: object.id || null,
      cover: object.media.cover || 'https://www.keystoneproperties.net/wp-content/themes/keystoneproperties/img/property-placeholder.png'
    }
    this.agent = {
      name: {
        first: object.agent.first_name || '',
        last: object.agent.last_name || ''
      },
      avatar: object.agent.avatar || 'http://placehold.jp/20/ffffff/202225/900x250.png?text='
    }
    this.floor = object.floor || 0
    this.exterior_area = object.exterior_area || 0
    this.tag = object.tag || ''
    this.fraction = object.fraction || ''
    this.hide_price = object.hide_price
    this.highlighted = object.highlighted
    this.imovirtual_uuid = object.imovirtual_uuid
    this.imovirtual_state = object.imovirtual_state
    this.idealista_uuid = object.idealista_uuid
    this.jamesEdition_uuid = object.jamesEdition_uuid
    this.for_sale = object.for_sale
    this.low_cost = object.low_cost
    this.lat = object.lat || null
    this.len = object.len || null
    this.administrative_area_level_2 = object.administrative_area_level_2 || null
    this.political = object.political || null
    this.administrative_area_level_3 = object.administrative_area_level_3 || null
    this.type = object.type.name || null
    this.type_id = object.types_id || null
    this.construction = {
      area: object.construction || null,
      unit: object.construction_area_type || null
    }
    this.lote = {
      area: object.lote_size || null,
      unit: object.lote_size_type || null
    }
    this.land = {
      area: object.land_size || null,
      unit: object.land_size_type || null
    }
    this.route = object.route || null
  }
}

export class PropertiesListCondominiums {
  constructor(object = null) {
    this.id = object.id || null,
    this.tag_id = object.tag_id || null,
    this.price = object.price || 0,
    this.condominium_id = object.condominium_id || null
    this.reference = object.reference || ''
    this.archived = object.archived || 0
    this.information = {
      title: object.title || '-',
      description: object.description || '-',
      location: {
        district: object.district.name || '',
      }
    }
    this.kpis = {
      beds: object.bedrooms || '-',
      baths: object.bathrooms || '-',
      parking: object.parking || '-',
      m2: object.gross_area || '-',
    }
    this.media = {
      id: object.id || null,
      cover: object.media.cover || 'https://www.keystoneproperties.net/wp-content/themes/keystoneproperties/img/property-placeholder.png'
    }
    this.agent = {
      name: {
        first: object.agent.first_name || '',
        last: object.agent.last_name || ''
      },
      avatar: object.agent.avatar || 'http://placehold.jp/20/ffffff/202225/900x250.png?text='
    }
    this.floor = object.floor || 0
    this.exterior_area = object.exterior_area || 0
    this.tag = object.tag || ''
    this.fraction = object.fraction || ''
    this.hide_price = object.hide_price
    this.type = object.type
  }
}


export class PropertyForm {
  constructor(
    {
      id = String(),
      tag_id = String(),
      reference = String(),
      i18n = {
        title: Array(),
        description: Array(),
        slogan: Array()
      },
      price = Number(),
      bedrooms = Number(),
      bathrooms = Number(),
      parking = Number(),
      exterior_area = String(),
      gross_area = String(),
      living_area = String(),
      construction = String(),
      construction_area_type = String(),
      lote_size = Number(),
      lote_size_type = String(),
      land_size = Number(),
      land_size_type = String(),
      agent_id = Number(),
      district_id = Number(),
      types = Array(),
      archived = Number(),
      search = Array(),
      condominium_list = Array(),
      hide_price = Number(),
      collection = Number(),
      low_cost = Number(),
      condominium_id = Number(),
      for_sale = Number(),
      highlighted = Number(),
      imovirtual_uuid = String(),
      idealista_uuid = String()
    }) {
    this.id = id || null
    this.tag_id = tag_id || null
    this.reference = reference || null
    this.i18n = {
      title: { en: i18n.title.en || '', pt: i18n.title.pt || '' },
      description: { en: i18n.description.en || '', pt: i18n.description.pt || '' },
      slogan: { en: i18n.slogan.en || '', pt: i18n.slogan.pt || '' }
    }
    this.price = price || null
    this.bedrooms = bedrooms
    this.bathrooms = bathrooms
    this.parking = parking
    this.gross_area = gross_area
    this.exterior_area = exterior_area
    this.construction = construction
    this.construction_area_type = construction_area_type
    this.lote_size = lote_size
    this.lote_size_type = lote_size_type
    this.land_size = land_size
    this.land_size_type = land_size_type
    this.living_area = living_area
    this.agent_id = agent_id || null
    this.district_id = district_id || null
    this.types = types || null
    this.archived = archived || null
    this.search = search || null
    this.condominium_list = condominium_list || null
    this.hide_price = hide_price == 0 ? 0 : 1
    this.collection = collection || 0
    this.low_cost = low_cost || 0    
    this.condominium_id = condominium_id || null
    this.for_sale = for_sale == 0 ? 0 : 1
    this.imovirtual_uuid = imovirtual_uuid || ''
    this.idealista_uuid = idealista_uuid || ''
    this.highlighted = highlighted || 0
  }
}

export class PropertySEOForm {
  constructor(
    {
      id = String(),
      title = String(),
      slug = String(),
      description = String()
    }) {
    this._id = id || null
    this.title = title || ''
    this.slug = slug || ''
    this.description = description || ''
  }
}

export class PropertieFromCondominium{
  constructor(
    object = null
  ){
    this.reference = object.reference
    this.type_name = object.type_name
    this.bedrooms = object.bedrooms
    this.bathrooms = object.bathrooms
    this.gross_area = object.gross_area
    this.exterior_area = object.exterior_area
    this.parking = object.parking
    this.floor = object.floor
    this.price = object.price
    this.tag = object.tag
    this.archived = object.archived
    this.cover = object.cover
    this.id = object.property_id
  }
}


export class PropertyCondominiumList {
  constructor(object = null) {
    this.id = object.id
    this.title = object.title
    this.price = object.price
    this.condo_where = object.condo_where
    this.description = object.description
  }
}

export class PropertyLocation {
  constructor(object = null){
    this.address = object.address
    this.lat = object.lat
    this.len = object.len
    this.drawer = object.drawer
  }
}



export default { PropertiesListCondominiums, PropertyLocation, Property, PropertyForm, PropertySEOForm, PropertieFromCondominium, PropertyCondominiumList }
