<template>
    <v-card flat class="pb-1" v-if="dataLoading">
        <v-card-title class="subtitle-2 font-weight-bold">{{ block == 'condominiums' ? $t('FILTER_CONDOMINIUMS.TITLE') : $t('FILTER_PROPERTIES.TITLE') }}</v-card-title>
        <v-card-subtitle class="caption font-weight-light text-left mb-2 black--text">{{ countingProperties }} {{filterTitle}}</v-card-subtitle>

        <!-- Filters -->
        <div class="px-4 v-filters" :key="reloadDivFilter">
           

            <!-- Price
            <range-slider label="Price" class="mb-3" id="price"></range-slider>
             end -->

             <v-row justify="center">
                <v-btn @click="cleanFilter()" color="primary" min-width="100%">
                  <v-icon color="white">mdi-delete</v-icon>
                  {{$t('FILTER_PROPERTIES.RESET')}}
                </v-btn>
               <v-expansion-panels accordion v-model="panel" multiple :disabled="checkExpansionPanelsAvailability">
                <v-expansion-panel 
                >
              
                <v-expansion-panel-header>{{$t('EXPANSION_PANELS.FIELD1')}}</v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <!-- Price -->  
                    <v-subheader class="pl-0">
                    {{$t('PROPERTIES.PRICE')}}
                    </v-subheader> 
                    <div class="v-input v-input-text v-input--is-label-active v-input--is-dirty theme--dark v-text-field v-text-field--prefix v-text-field--single-line v-text-field--filled v-text-field--is-booted v-text-field--enclosed v-text-field--placeholder"><div class="v-input__control"><div class="v-input__slot"><div class="v-text-field__slot"><div class="v-text-field__prefix">€</div>
                        <currency-input
                            class="v-input-text"
                            v-model="formData.price" 
                            name="price"
                            :placeholder="$t('PROPERTIES.PRICE')"
                            prefix="€"
                            :fill="formData.price"
                            autocomplete="off"
                            rules="numeric"
                            @blur="priceUpdate"
                          >
                          </currency-input>
                        </div></div>
                      <div class="v-text-field__details">
                        <div class="v-messages theme--dark">
                          <div class="v-messages__wrapper">
                          </div>
                        </div>
                      </div></div></div>
                    
                      <!-- end -->

                  

                    <!-- LOCATION -->
                    <div :class="onFocusVariableMap ? 'v-input v-input--is--focused v-input-text theme--light v-text-field v-text-field--filled v-text-field--is-booted v-text-field--enclosed mb-4 primary--text' : 'v-input v-input-text theme--light v-text-field v-text-field--filled v-text-field--is-booted v-text-field--enclosed mb-4'">
                      <div class="v-input__control">
                        <div class="v-input__slot">
                          <div class="v-text-field__slot">
                            <label for="ref" :class="onFocusVariableMap ? 'v-label v-label--active theme--light primary--text' : 'v-label theme--light'" style="left: 0px; right: auto; position: absolute;">{{$t('FILTER_PROPERTIES.LOCATION')}}</label>
                              <gmap-autocomplete
                                @focus="onFocusMap"
                                @focusout="onFocusOut"
                                id="gmapComplete"
                                placeholder=''
                                :value="searchFilter.localization"
                                @place_changed="locationUpdateSet">
                              </gmap-autocomplete>
                          </div>
                        </div>
                      </div>
                    </div>
                    <!-- end -->
                    

                    <!-- Reference -->
                    <input-text 
                      v-if="block!='condominiums'" 
                      @input="referenceUpdate" 
                      :label="$t('FILTER_PROPERTIES.REFERENCE')" 
                      type="text" 
                      id="ref" 
                      :fill="formData.reference"
                      name="ref"></input-text>
                    <!-- end -->

                    <!-- Name -->
                    <input-text 
                      @input="nameUpdate" 
                      :label="$t('FILTER_PROPERTIES.NAME')" 
                      type="text"                       
                      :fill="formData.name"
                      id="name" 
                      name="name"></input-text>
                    <!-- end -->

                    <!-- Name -->
                    <input-text v-if="false" @input="condominiumWhereUpdate" :label="$t('FILTER_CONDOMINIUMS.WHERE')" type="text" id="where" name="where"></input-text>
                    <!-- end -->

                    
                    <range-slider 
                      v-if="block=='condominiums'" 
                      :title="$t('FILTER_CONDOMINIUMS.BEDROOMS')" 
                      @change="rangeChange" 
                      :range="range"
                      :min="min" 
                      :max="max"></range-slider>

                    <range-slider v-if="block=='condominiums'" :title="$t('FILTER_CONDOMINIUMS.PARKING')" @change="parkingRangeChange" :range="parkingRange" :min="min" :max="max"></range-slider>

                    <simple-slider 
                      v-if="block!='condominiums'" 
                      :max="6" 
                      :model="this.formData.beds"
                      @change="bedsUpdate" 
                      v-model="formData.beds"
                      :title="$t('FILTER_PROPERTIES.BEDROOMS')"></simple-slider>
                    <input-number v-if="false" @input="bedsUpdate" :label="$t('FILTER_PROPERTIES.BEDROOMS')" id="beds" :fill="formData.beds"></input-number>

                    <simple-slider 
                    v-if="block!='condominiums'" 
                    :max="6" 
                    :model="this.formData.baths"
                    @change="bathsUpdate" 
                    :title="$t('FILTER_PROPERTIES.BATHROOMS')"></simple-slider>
                    <input-number v-if="false" @input="bathsUpdate" :label="$t('FILTER_PROPERTIES.BATHROOMS')" id="baths" :fill="formData.baths"></input-number>
                  </v-expansion-panel-content>


                </v-expansion-panel>
                <v-expansion-panel >

                <v-expansion-panel-header>{{$t('EXPANSION_PANELS.FIELD2')}}</v-expansion-panel-header>
                  <v-expansion-panel-content v-if="!$route.path.includes('sold') && !$route.path.includes('archived')">
                     <!-- Collection -->
                    <input-checkbox 
                      v-if="block!='condominiums' && !$route.path.includes('low_cost')" 
                      :status="formData.collectionPrive" 
                      @input="checkUpdate" 
                      :label="$t('FILTER_PROPERTIES.COLLECTION')" 
                      color="primary" 
                      class="mb-2 mt-0"></input-checkbox>
                    <!-- end -->

                    <!-- Highlighted -->
                    <input-checkbox 
                      v-if="!$route.path.includes('low_cost')" 
                      :status="formData.highlighted" 
                      @input="showHighlighted" 
                      :label="$t('TOOLTIPS.HIGHLIGHT_DESCRIPTION')" color="primary" class="mb-2 mt-0"></input-checkbox>
                    <!-- end -->

                    <!-- With problems -->
                    <input-checkbox 
                      :status="formData.problems" 
                      @input="showProblems" :label="$t('FILTER_PROPERTIES.ERRORS')" color="primary" class="mb-2 mt-0"></input-checkbox>
                    <!-- end -->

                    <!-- New Development Properties -->
                    <input-checkbox 
                      v-if="!$route.path.includes('low_cost') && block != 'condominiums'" 
                      :status="formData.newDevelopment" 
                      @input="newDevelopmentUpdate" :label="$t('FILTER_PROPERTIES.NEW_DEVELOPMENTS')" color="primary" class="mb-2 mt-0"></input-checkbox>
                    <!-- end -->

                    <v-select v-if="block != 'condominiums'"
                      :items="itemKeys"
                      :item-text="this.$i18n.locale == 'en' ? 'en' : 'pt'"
                      item-value='id'
                      :label="$t('FILTER_PROPERTIES.TYPE')"
                      v-model="formData.type"
                      @change="processData"
                      outlined
                    ></v-select>

                    
                  </v-expansion-panel-content>
                </v-expansion-panel>
                
                <v-expansion-panel v-if="block != 'condominiums'">

                <v-expansion-panel-header>{{$t('EXPANSION_PANELS.FIELD3')}}</v-expansion-panel-header>
                <v-expansion-panel-content v-if="!$route.path.includes('sold') && !$route.path.includes('archived')">


                    <!-- New Imovirtual -->
                    <input-checkbox :status="formData.imovirtual" @input="imovirtualUpdate" :label="$t('FILTER_PROPERTIES.IMOVIRTUAL')" color="primary" class="mb-2 mt-0"></input-checkbox>
                    <!-- end -->

                    <!-- New Idealista -->
                    <input-checkbox :status="formData.idealista" @input="idealistaUpdate" :label="$t('FILTER_PROPERTIES.IDEALISTA')" color="primary" class="mb-2 mt-0"></input-checkbox>
                    <!-- end -->

                    <!-- New James edition -->
                    <input-checkbox  :status="formData.james_edition" @input="jamesEditionUpdate" :label="$t('FILTER_PROPERTIES.JAMES_EDITION')" color="primary" class="mb-2 mt-0"></input-checkbox>
                    <!-- end -->
                    
                  </v-expansion-panel-content>
                </v-expansion-panel>
            
              </v-expansion-panels>
            </v-row>

            <!-- Keys Items 
            <KeyItems />
             end -->

            <v-btn v-if="false" color="primary" depressed min-width="100%" class="mb-3">{{$t('FILTER_PROPERTIES.FILTER')}}</v-btn>
            
        </div>
    </v-card>
</template>

<script>
  import { buildPayloadPagination } from '@/utils/utils'
  import mixin from '@/models/keys/KeyModel'
  export default {
    name: 'PropertiesFilters',
    props: ["countingProperties", 'block'],
    mixins: [mixin],
    data() {
      return {
        itemKeys: [],
        dataLoading: false,
        panel: [0, 0],
        range: [0, 10],
        parkingRange: [0, 10],
        min:0,
        max: 10,
        rangePrice: [0, 90000000000],
        minPrice:0,
        maxPrice: 90000000000,
        foo: 1,
        onFocusVariableMap: false,
        reloadDivFilter: 0,
        increment: 0,
        decrement: 10,
        searchFilter:{
          localization: ''
        },
        formData: {
          type: 0,
          imovirtual: 0,
          james_edition: 0,
          idealista: 0,
          collectionPrive: 0,
          price: 0,
          reference: "",
          name: "",
          beds: 0,
          newDevelopment: 0,
          bedrooms:{
            from: 0,
            to: 0
          },
          parking:{
            from: 0,
            to: 0
          },
          baths: 0,
          highlighted: 0,
          problems: 0,
          locationUpdate: null,
          localCookie: {},
          condominiumLocation: ''
        }
      }
    },
    async created(){
      await this.getKeyItems(
        buildPayloadPagination({}, this.buildSearch(), 'types')
      )
      let parent = this

      if(this.$store.state.keysModule.key.types.data != null || this.$store.state.keysModule.key.types.data != undefined){
        this.$store.state.keysModule.key.types.data.forEach(element => {
          parent.itemKeys.push({id: element.id, en: element.name.en, pt: element.name.pt})
        });
      }
      

      // get form data cookie
      let getCookie = this.$cookies.get(this.block + '_FORM_DATA');
      console.log(getCookie)

      // if different null, update variable
      if(getCookie != null){
        this.formData = getCookie
        this.range = [this.formData.bedrooms.from, this.formData.bedrooms.to]
        this.parkingRange = [this.formData.parking.from, this.formData.parking.to]

        // Obter nome da rua
        if(this.$cookies.get(this.block + '_FORM_LOCATION_STRING') != null){
          this.searchFilter.localization = this.$cookies.get(this.block + '_FORM_LOCATION_STRING')
          this.onFocusVariableMap = true
        }
        
      }



      this.dataLoading = true
    },
    watch:{
      formData: {
        handler: function(val) {
          this.$cookies.set(this.block + '_FORM_DATA', val, "1d");
          console.log("VAL: ")
          console.log(this.$cookies.get(this.block + '_FORM_DATA'))
          this.$emit('filter-selected', val)
        },
        deep: true
      }
    },
    methods: {
      buildSearch() {
        return this.search
          ? { query: this.search, fields: this.fieldsToSearch.join(',') }
          : {}
      },
      parkingRangeChange(r){
        this.formData.parking.from = r[0]
        this.formData.parking.to = r[1]

      },
      rangeChange(range){        
        this.formData.bedrooms.from = range[0]
        this.formData.bedrooms.to = range[1]
      },
       onFocusMap(){
         if(this.formData.locationUpdate == null)
          this.onFocusVariableMap = !this.onFocusVariableMap
       },
       onFocusOut(){
        if(this.onFocusVariableMap && document.getElementById('gmapComplete').innerHTML != '')
          this.onFocusVariableMap = false
       },
       cleanFilter(){
         this.searchFilter = {
           localization: ''
         }
         
         this.$cookies.set(this.block + '_FORM_LOCATION_STRING', this.searchFilter.localization, "1d");
         this.formData = {
          type: 0,
          imovirtual: 0,
          james_edition: 0,
          idealista: 0,
          collectionPrive: 0,
          price: 0,
          reference: "",
          name: "",
          beds: 0,
          newDevelopment: 0,
          bedrooms:{
            from: 0,
            to: 0
          },
          parking:{
            from: 0,
            to: 0
          },
          baths: 0,
          highlighted: 0,
          problems: 0,
          locationUpdate: null,
          condominiumLocation: ''
        }
        this.onFocusVariableMap = false
        this.reloadDivFilter++
       },       
       idealistaUpdate(e){
        this.formData.idealista = e
       },
       imovirtualUpdate(e){
        this.formData.imovirtual = e
       },
       jamesEditionUpdate(e){
        this.formData.james_edition = e
       },
       condominiumWhereUpdate(e){
        this.formData.condominiumLocation = e.toUpperCase()
       },
       newDevelopmentUpdate(e){
        this.formData.newDevelopment = e
       },
       priceUpdate(){
        this.$emit('filter-selected', this.formData)
       },
       showProblems(e){
        this.formData.problems = e
       },
       showHighlighted(e){
        this.formData.highlighted = e
       },
       checkUpdate(e){
        this.formData.collectionPrive = e
       },
       bedsUpdate(e){
        this.formData.beds = e
       },
       locationUpdateSet(e){
        this.formData.locationUpdate = e.address_components
        this.$cookies.set(this.block + '_FORM_LOCATION', e, "1d");
        this.searchFilter.localization = document.getElementById('gmapComplete').value
        this.$cookies.set(this.block + '_FORM_LOCATION_STRING', this.searchFilter.localization, "1d");
       },
       bathsUpdate(e){
        this.formData.baths = e
       },
       referenceUpdate(e){
        this.formData.reference = e
       },
       nameUpdate(e){
        this.formData.name = e.toUpperCase()
       },
      processData() {
        this.$emit('filter-selected', this.formData)
      },
     },
     computed:{
       filterTitle(){
         console.log(this.block)
        if(this.block == 'condominiums')
          return this.$t('FILTER_CONDOMINIUMS.ON_MARKET')
        else return this.$t('FILTER_PROPERTIES.ON_MARKET')
       },
       checkExpansionPanelsAvailability(){
         
        if(this.$route.path.includes('sold'))
          return true

        if(this.$route.path.includes('archived'))
          return true

          return false
       }
     }
  }
</script>