import Vue from 'vue'
import InputFile from '@/components/form/InputFile'
import InputCheckbox from '@/components/form/InputCheckbox'
import InputText from '@/components/form/InputText'
import i18nInputText from '@/components/form/i18n/InputText'
import RangeSlider from '@/components/form/RangeSlider'
import SimpleSlider from '@/components/form/SimpleSlider'
import InputSelect from '@/components/form/InputSelect'
import InputNumber from '@/components/form/InputNumber'
import SpecialInputNumber from '@/components/form/SpecialInputNumber'
import InputTextarea from '@/components/form/InputTextarea'
import BrowseFiles from '@/components/media/BrowseFiles'
import BrowseFilesAll from '@/components/media/BrowseFilesAll'
import FilesList from '@/components/media/FilesList'
import DraggableGrid from '@/components/media/DraggableGrid'
import AttachedFilesList from '@/components/media/AttachedFilesList'
import SelectChips from '@/components/form/special/SelectChips'
import SelectMasterChips from '@/components/form/special/SelectMasterChips'
import Keys from '@/components/properties/form/general/Keys' 
import CondominiumsKey from '@/components/condominiums/form/general/CondominiumKey'
import CondominiumSearch from '@/components/condominiums/form/general/CondominiumSearch'
import CondominiumAgents from '@/components/condominiums/form/general/CondominiumAgents'
import Search from '@/components/properties/form/general/Search'
import Agents from '@/components/properties/form/general/Agents'
import InputRadio from '@/components/form/InputRadio'
import Translatable from '@/components/properties/form/general/Translatable'
import PropertyTags from '@/components/properties/form/general/PropertyTags'
import CondominiumTags from '@/components/condominiums/form/general/CondominiumTags'
import CondominiumsSearchList from '@/components/properties/form/general/CondominiumsSearchList'
import CondominiumsTranslatable from '@/components/condominiums/form/general/CondominiumsTranslatable'
import CondominiumsListProperties from '@/components/condominiums/form/general/CondominiumsListProperties'
import Avatar from '@/components/media/Avatar'
import Cover from '@/components/media/Cover'
import AvatarMedia from '@/components/media/AvatarMedia'
import InputCheckboxMultiple from '@/components/form/InputCheckboxMultiple'

// Common uses
Vue.component('input-file', InputFile)
Vue.component('input-text', InputText)
Vue.component('input-checkbox', InputCheckbox)
Vue.component('input-checkbox-multiple', InputCheckboxMultiple)
Vue.component('range-slider', RangeSlider)
Vue.component('simple-slider', SimpleSlider)
Vue.component('input-select', InputSelect)
Vue.component('input-radio', InputRadio)
Vue.component('input-number', InputNumber)
Vue.component('special-input-number', SpecialInputNumber)
Vue.component('input-textarea', InputTextarea)

// i18n
Vue.component('i18n-input-text', i18nInputText)

// Media
Vue.component('browse-files', BrowseFiles)
Vue.component('browse-files-all', BrowseFilesAll)
Vue.component('avatar-file', Avatar)
Vue.component('cover-file', Cover)
Vue.component('avatar-media', AvatarMedia)
Vue.component('list-files', FilesList)
Vue.component('drag-grid', DraggableGrid)
Vue.component('attached-list-files', AttachedFilesList)

// Special
Vue.component('select-chips', SelectChips)
Vue.component('select-master-chips', SelectMasterChips)

// Groups 
Vue.component('group-keys', Keys)
Vue.component('group-search', Search)
Vue.component('group-agents', Agents)
Vue.component('group-condominiums-keys', CondominiumsKey)
Vue.component('group-condominiums-search', CondominiumSearch)
Vue.component('group-condominiums-agents', CondominiumAgents)
Vue.component('group-propertytags', PropertyTags)
Vue.component('group-condominiumtags', CondominiumTags)
Vue.component('group-translatable', Translatable)
Vue.component('group-search-list', CondominiumsSearchList)
Vue.component('group-condominiums-translatable', CondominiumsTranslatable)
Vue.component('group-condominiums-list-properties', CondominiumsListProperties)