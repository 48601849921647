<template>
    <v-card flat class="mt-3">
        <div class="py-3 px-4">
            <v-card-title class="subtitle-2 font-weight-medium">{{$t('DASHBOARD.RECENTLY_PROPERTIES')}}</v-card-title>
            <v-card-subtitle class="v-card__subtitle caption text-left font-weight-medium secondaryCard">
            </v-card-subtitle>

            <div class="px-5 pb-4">
                <v-data-table
                        class="table__minimal recent-properties elevation-0"
                        :loading="dataTableLoading"
                        :items="items"
                        :options.sync="pagination"
                        :headers="headers"
                        loading-text="Loading... Please wait"
                        :hide-default-footer="true"
                        :calculate-widths="true"
                        :items-per-page="5"
                >
                    <template v-slot:[`item.image`]="{ item }">
                        <v-img
                                :src="$mediaFile('properties', item.id, item.media.cover)"
                                :lazy-src="$mediaFile('properties', item.id, item.media.cover)"
                                height="125"
                                width="125"
                                class="grey lighten-2 d-block rounded"
                        ></v-img>
                    </template>
                    <template v-slot:[`item.information`]="{ item }">
                        <div class="main__info">
                            <p class="mb-0">{{ item.reference }}</p>
                            <p class="mb-0 font-weight-medium black--text listing-sub-sub-title">{{ item.information.title }}</p>
                            <p class="listing-kpis"><p class="listing-kpis">{{ item.kpis.beds }} {{$t('DASHBOARD.BEDS')}} <span class="span-bullet">&#11044;</span> {{ item.kpis.baths }} {{$t('DASHBOARD.BATHS')}} <span class="span-bullet">&#11044;</span> {{ item.kpis.parking }} {{$t('DASHBOARD.PARKING')}}</p>
                        </div>
                    </template>
                    <template v-slot:[`item.description`]="{ item }">
                        <div class="main__info" style="width: auto;">
                            <p class="mb-0 caption">
                                <span class="black--text">{{$t('DASHBOARD.TYPE')}}</span>
                            </p>
                            <p v-if="item.information.location.type == ''">-</p>
                            <p v-else class="mb-0 text-limit-liner black--text">{{ item.information.location.type }}</p>
                        </div>
                    </template>
                    <template v-slot:[`item.price`]="{ item }">
                          <div class="main__info" style="width: auto;">
                            <p class="mb-0 caption">
                                <span class="black--text">{{$t('DASHBOARD.PRICE')}}</span>
                            </p>
                            <p class="mb-0 text-limit-liner black--text">€ {{ $currency(item.price)  }}</p>
                        </div>
                    </template>
                    <template v-slot:[`item.view`]="{ item }">
                        <v-btn :to="`properties/edit/${item.id}/general`" class="secondary pa-0 ma-0" depressed small>
                            <i class="zwicon-pencil"></i>
                        </v-btn>
                    </template>
                </v-data-table>
            </div>
        </div>
    </v-card>
</template>

<script>
  import mixin from '@/models/properties/PropertiesModel'
  import { Property } from '@/store/structs/properties'

  export default {
    name: 'LastProperties',

    mixins: [mixin],

    data: () => ({
      dataTableLoading: true,
      pagination: {},
      headers: [
        { value: 'image', width: 50, align: 'img' },
        { value: 'information', align: 'left'  },
        { value: 'description', align: 'start' },
        { value: 'price', align: 'left' },
        { value: 'view', width: 30, align: 'img' }
      ]
    }),

    watch: {
      pagination: {
        async handler() {
          try {
            this.dataTableLoading = true
            await this.getProperties()
            this.dataTableLoading = false
          } catch (error) {
            this.dataTableLoading = false
          }
        },
        deep: true
      }
    },

    computed: {
      items() {
        let retrievedData = this.$store.state.propertiesModule.properties
        return Object.keys(retrievedData).map(k =>
          new Property(retrievedData[k])
        )
      }
    }
  }
</script>
