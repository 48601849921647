export class TagsForm {
  constructor(
    {            
      i18n = {
        tag: Array()
      },
      id = String()
    }) {        
    this.i18n = {
      tag: { en: i18n.tag.en || '', pt: i18n.tag.pt || '' }
    }
    this.id = id || null    
  }
}


export default { TagsForm }
