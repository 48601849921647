<template>
    <v-expansion-panels flat :disabled="disabled" v-if="form != undefined">
        <v-expansion-panel>
            <v-expansion-panel-header class="v-expansion-panel-header-box">
                <div class="d-block">
                    <v-card-title class="subtitle-2 font-weight-bold pa-0 pb-4">{{$t('PROPERTIES.FEATURES')}}
                    </v-card-title>
                    <v-card-subtitle class="caption font-weight-light text-left mb-0 pa-0" :class="disabled ? 'darkgray--text' : 'black--text'">{{$t('PROPERTIES.SECONDARY')}}
                    </v-card-subtitle>
                </div>

            </v-expansion-panel-header>
            <v-expansion-panel-content class="v-expansion-panel-body-box">
                <v-row>
                    <v-col cols="8" class="text-left">
                        <!-- Types -->
                        <Types @get-types="getTypes" :form="form['type_id']" @on-blur-event="onBlurAction"/>
                        <!-- end -->
                        <!-- Features -->
                        <Features @get-features="getFeatures" :form="form['features_id']" @on-blur-event="onBlurAction"/>
                        <!-- end -->
                    </v-col>
                    <v-col cols="4" class="text-left">
                        <!-- Floor -->
                        <special-input-number 
                                      v-model="formData['floor']" 
                                      :fill="formData['floor'] == undefined ? '' : String(formData['floor'])"
                                      :label="$t('PROPERTIES.FLOOR')"
                                      @on-blur-event="onBlurAction"
                        ></special-input-number>
                        <!-- end -->
                        <!-- Fraction -->
                        <input-text
                                    :placeholder="$t('PROPERTIES.FRACTION')"
                                    :label="$t('PROPERTIES.FRACTION')"
                                    v-model="formData['fraction']" 
                                    :fill="form['fraction']"
                                    @blur="onBlurAction"
                            >
                            </input-text>
                        <!-- end -->
                        <!-- Construction -->
                        <special-input-number 
                                name="construction"
                                :label='form["construction_area_type"] == undefined ? $t("PROPERTIES.CONSTRUCTION") : $t("PROPERTIES.CONSTRUCTION") +"(" + form["construction_area_type"] + ")"'
                                id="construction"
                                v-model="formData['construction']" 
                                :fill="formData['construction'] == undefined ? '' : String(formData['construction'])"
                                @on-blur-event="onBlurAction"
                        >
                        </special-input-number>
                        <!-- end -->
                        <!-- Construction Type -->
                        <input-select
                                v-model="formData['construction_area_type']"                                 
                                :fill="form['construction_area_type']"
                                @input="onBlurAction"
                                :items="allConstructionType" :label='$t("PROPERTIES.CONSTRUCTION_UNIT")'>
                        </input-select>
                        <!-- end -->
                        <!-- Lote Size -->
                        <special-input-number
                                name="lote_size"
                                :label='form["lote_size_type"] == undefined ? $t("PROPERTIES.LOTE_SIZE") : $t("PROPERTIES.LOTE_SIZE") +"("+ + form["lote_size_type"] + ")"'
                                id="lote_size"
                                v-model="formData['lote_size']" 
                                :fill="formData['lote_size'] == undefined ? '' : String(formData['lote_size'])"
                                @on-blur-event="onBlurAction"
                        >
                        </special-input-number>
                        <!-- end -->
                        <!-- Lote Type -->
                        <input-select :width="170"
                                v-model="formData['lote_size_type']" :fill="form['lote_size_type']"
                                @input="onBlurAction"
                                :items="allConstructionType" :label='$t("PROPERTIES.LOTE_SIZE_UNIT")'>
                        </input-select>
                        <!-- end -->
                        <!-- Land Size -->
                        <special-input-number
                                name="land_size"
                                :label='form["land_size_type"] == undefined ? $t("PROPERTIES.LAND_SIZE") : $t("PROPERTIES.LAND_SIZE") + "("+ + form["land_size_type"] + ")"'
                                id="land_size"
                                v-model="formData['land_size']" 
                                :fill="formData['land_size'] == undefined ? '' : String(formData['land_size'])"
                                @on-blur-event="onBlurAction"
                        >
                        </special-input-number>
                        <!-- end -->
                        <!-- Lote Type -->
                        <input-select :width="170"
                                @input="onBlurAction"
                                v-model="formData['land_size_type']" :fill="form['land_size_type']"
                                @on-blur-event="onBlurAction"
                                :items="allConstructionType" :label='$t("PROPERTIES.LAND_SIZE_UNIT")'>
                        </input-select>
                        <!-- end -->
                        <!-- Year -->
                        <special-input-number
                                name="year"
                                :label='$t("PROPERTIES.YEAR")'
                                id="year"
                                v-model="formData['year']" 
                                :fill="formData['year'] == undefined ? '' : String(formData['year'])"
                                @on-blur-event="onBlurAction"
                        >
                        </special-input-number>
                        <!-- end -->
                        <!-- Energy -->
                        <input-select
                                v-model="formData['energy']" :fill="form['energy']"
                                @input="onBlurAction"
                                :label='$t("PROPERTIES.ENERGY")' :items="getEnergy">
                        </input-select>
                        <!-- end -->
                        <!-- Elevator -->
                        <label class="d-block v-label theme--light mb-2 black--text">{{$t('PROPERTIES.ELEVATOR')}}:</label>
                        <input-radio
                                v-model="formData['elevator']" :fill="form['elevator']"
                                :items="[
                                  {
                                    id: $t('PROPERTIES.ND'),
                                    name: { 
                                      en: $t('PROPERTIES.ND'),
                                      pt: $t('PROPERTIES.ND')
                                    } 
                                  },
                                  {
                                    id: $t('PROPERTIES.YES'), 
                                    name: { 
                                      en: $t('PROPERTIES.YES'),
                                      pt: $t('PROPERTIES.YES')
                                    }
                                  },
                                  {
                                    id: $t('PROPERTIES.NO'), 
                                    name: { 
                                      en: $t('PROPERTIES.NO'),
                                      pt: $t('PROPERTIES.NO')
                                    }
                                  }
                                ]"
                                :mandatory="true"
                                @blur="onBlurAction"
                        ></input-radio>
                        <!-- end -->
                    </v-col>
                </v-row>
            </v-expansion-panel-content>
        </v-expansion-panel>
    </v-expansion-panels>
</template>

<script>
  import EnergyEfficiency from '@/data/energy-efficiency'
  import Features from '@/components/properties/form/general/Keys/Features'
  import Types from '@/components/properties/form/general/Keys/Types'
  import { PropertyForm } from '@/store/structs/properties'

  let options = ["m2", "a", "ha"];
  let myFractions = ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M", "N", "O", "P", "Q", "R", "S", "T", "U", "V", "W", "X", "Y", "Z"];
  export default {

    name: 'Keys',

    props: {
      form: {},
      disabled: {
        type: Boolean,
        default: false
      }
    },

    components: { Types, Features },

    data: () => ({
      formData: PropertyForm,
      allEnergy: EnergyEfficiency
    }),

    created() {
      this.formData = new PropertyForm({}).types
    },

    methods: {
      getTypes(e) {
        this.formData['type_id'] = e;
      },
      getFeatures(e) {
        this.formData['features_id'] = e;
      },
      onBlurAction(){
        this.$emit('blur')
      }
    },

    watch: {
      form: {
        handler: function(val) {
          this.formData = val
        },
        deep: true
      },
      formData: {
        handler: function(val) {
          this.$emit('form-types', val)
        },
        deep: true
      }
    },
     computed: {
        allConstructionType() {
          return options;
      },
      allFractionTypes(){
        return myFractions;
      },
      getEnergy(){
        let isento = {
          pt: 'Isento',
          en: 'Exempt'
        }
        let processo = {
          pt: "Em Processo",
          en: "In Process"
        }
        
        let returner = ['A+','A','B','B-','C','D','E','F']

        

        returner.push(this.$processLanguage(isento))
        returner.push(this.$processLanguage(processo))
        return returner
      }
     }

  }
</script>
