export default [
  {
    path: '/condominiums',
    name: 'Condominiums',
    component: () => import('@/pages/Condominiums'),
    meta: {
      layout: 'main',
      requiresAuth: true
    },
    children: [
      {
        path: '/condominiums/listings',
        name: 'CondominiumsListings',
        meta: {
          title: 'ACTIVE CONDOMINIUMS',
          subtitle: 'All condominiums currently displayed on your website',
          fluid: true
        },
        component: () =>
          import(/* webpackChunkName: "home" */ '@/pages/condominiums/Listings')
      },
      {
        path: '/condominiums/archived',
        name: 'CondominiumsArchived',
        meta: {
          title: 'LIST OF CONDOMINUMS',
          subtitle: 'All condominiums added around de Portugal map',
          fluid: true
        },
        component: () =>
          import(/* webpackChunkName: "home" */ '@/pages/condominiums/Archived')
      }
    ]
  },
  {
    path: '/condominiums/new',
    meta: {
      layout: 'main',
      title: 'NEW CONDOMINIUM',
      subtitle: 'Add new condominium to your platform',
      fluid: false
    },
    component: () =>
      import(/* webpackChunkName: "home" */ '@/pages/condominiums/actions/AddCondominium'),
    children: [
      {
        path: '/',
        name: 'condominium-general',
        component: () =>
          import(/* webpackChunkName: "home" */ '@/components/condominiums/form/CondominiumGeneral')
      }
    ]
  },
  {
    path: '/condominiums/edit/:slug/',
    meta: {
      layout: 'main',
      title: 'EDIT CONDOMINIUM',
      subtitle: 'Edit a CONDOMINIUM named ´Cascais Village House´ to your platform',
      fluid: false
    },
    component: () =>
      import(/* webpackChunkName: "home" */ '@/pages/condominiums/actions/AddCondominium'),
    children: [
      {
        path: 'general',
        name: 'condominium-general-edit',
        component: () =>
          import(/* webpackChunkName: "home" */ '@/components/condominiums/form/CondominiumGeneral')
      },
      {
        path: 'attachments',
        name: 'condominium-attachments-edit/:slug',
        component: () =>
          import(/* webpackChunkName: "home" */ '@/components/condominiums/form/Attachments')
      },
      {
        path: 'media',
        name: 'condominium-media-edit',
        component: () =>
          import(/* webpackChunkName: "home" */ '@/components/condominiums/form/CondominiumMedia')
      },
      {
        path: 'location',
        name: 'condominium-location-edit/:slug',
        component: () =>
          import(/* webpackChunkName: "home" */ '@/components/condominiums/form/CondominiumLocation')
      },
      {
        path: 'seo',
        name: 'condominium-seo-edit/:slug',
        component: () =>
          import(/* webpackChunkName: "home" */ '@/components/condominiums/form/CondominiumSEO')
      },
    ]
  }
]
