function extensionsFile(extension) {
  if (extension.length > 0) {
    if(extension != 'csv' || extension != 'docx' || extension != 'pdf' || extension != 'png' || extension != 'txt')
      extension = 'all_format'
      
    if (extension.startsWith('http')) {
      return extension 
    }
    return `${process.env.VUE_APP_MEDIA_URL}/file_icons/${extension}`
  }
} 

export default extensionsFile