import * as types from '@/store/mutation-types'
import api from '@/services/api/agents/agents'
import { buildSuccess, handleError } from '@/utils/utils.js'
import router from '@/router'

const getters = {
  list: (state) => state.list,
  agent: (state) => state.agent,
  totalAgents: (state) => state.totalAgents
}

const actions = {
  getAgents({ commit }, payload) {
    return new Promise((resolve, reject) => {
      api
        .getAgents(payload)
        .then((response) => {
          if (response.status === 200) {
            commit(types.AGENTS, response.data.agents)
            commit(types.TOTAL_AGENTS, response.data.total)
            resolve()
          }
        })
        .catch((error) => {
          handleError(error, commit, reject)
        })
    })
  },
  getAgent({ commit }, payload) {
    return new Promise((resolve, reject) => {
      api
        .getAgent(payload)
        .then((response) => {
          if (response.status === 200) {
            commit(types.AGENT, response.data.agent)
            resolve()
          }
        })
        .catch((error) => {
          handleError(error, commit, reject)
        })
    })
  },
  saveAgent({ commit }, payload) {
    return new Promise((resolve, reject) => {
      api
        .saveAgent(payload)
        .then((response) => {
          if (response.status === 201) {
            buildSuccess(
              {
                msg: 'common.SAVED_SUCCESSFULLY'
              },
              commit,
              resolve,
              router.push({
                path: '/agents/listings'
              })
            )
          }
        })
        .catch((error) => {
          handleError(error, commit, reject)
        })
    })
  },
  editAgent({ commit }, payload) {
    return new Promise((resolve, reject) => {
      api
        .editAgent(payload)
        .then((response) => {
          if (response.status === 202) {
            buildSuccess(
              {
                msg: 'common.SAVED_SUCCESSFULLY'
              },
              commit,
              resolve,
              
            )
          }
        })
        .catch((error) => {
          handleError(error, commit, reject)
        })
    })
  },
  deleteAgent({ commit }, payload) {
    return new Promise((resolve, reject) => {
      api
        .deleteAgent(payload)
        .then((response) => {
          if (response.status === 200) {
            buildSuccess(
              {
                msg: 'common.DELETED_SUCCESSFULLY'
              },
              commit,
              resolve
            )
          }
        })
        .catch((error) => {
          handleError(error, commit, reject)
        })
    })
  }
}

const mutations = {
  [types.AGENTS](state, list) {
    state.list = list
  },
  [types.AGENT](state, list) {
    state.agent = list
  },
  [types.TOTAL_AGENTS](state, value) {
    state.totalAgents = value
  }
}

const state = {
  list: [],
  totalAgents: 0,
  agent: []
}

export default {
  state,
  getters,
  actions,
  mutations
}