import { mapActions } from 'vuex'
import { buildPayloadPagination } from '@/utils/utils'

const mixin = {

  created: function() {
    console.debug(`Agents action called.`)
  },

  methods: {
    ...mapActions(['getAgents', 'getAgent', 'editAgent', 'saveAgent', 'deleteAgent']), 

    async deleteItem(item) {
      try {
        const response = await this.$confirm(
          this.$t('common.DO_YOU_REALLY_WANT_TO_DELETE_THIS_ITEM'),
          {
            title: this.$t('common.WARNING'),
            buttonTrueText: this.$t('common.DELETE'),
            buttonFalseText: this.$t('common.CANCEL'),
            buttonTrueColor: 'custom-primary-delete',
            buttonFalseColor: 'custom-cancel',
            color: 'warning'
          }
        )

        if (response) {
          this.dataLoading = true

          await this.deleteAgent(item)
          await this.getAgents()

          this.dataLoading = false
        }

      } catch (error) {
        this.dataLoading = false
      }

      return true
    },
    async save(form) {
      try {
        // Updating item
        if (form.id !== null) {
          await this.editAgent({ 'data': form })
          await this.getAgents()
        } else {
          // Creating new item
          await this.saveAgent({ 'data': form })
          await this.getAgents()
        }
      } catch (error) {
        this.dataTableLoading = false
      }
    }
  }
}

export default mixin