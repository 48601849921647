<template>
    <div class="p-menu__right">
        <v-card class="top-header custom-color text-left mt-10" flat color="#ffffff">
            <v-card-title class="subtitle-2 font-weight-bold">{{$t('INBOX.AGENT.TITLE')}}</v-card-title>
            <v-card-subtitle class="caption font-weight-light text-left black--text">{{$t('INBOX.AGENT.DESCRIPTION')}}
            </v-card-subtitle>

            <div class="d-flex flex-column mx-4">
                <v-row>
                    <v-col
                            v-for="(agent, index) in items"
                            :key="index"
                            cols="12"
                            class="pb-1"
                    >
                        <v-item-group active-class="primary">
                            <v-item v-slot="{ toggle }">
                                <v-card
                                        class="d-inline-flex align-center"
                                        dark
                                        flat
                                        height="55"
                                        @click="toggle"
                                        v-on:click="selectAgent(agent.name)"
                                        min-width="235"
                                >
                                    <v-img
                                            :src="$mediaFile('agents', agent.id, agent.avatar)"
                                            :lazy-src="$mediaFile('agents', agent.id, agent.avatar)"
                                            height="40"
                                            max-width="40"
                                            class="rounded ml-2"
                                    ></v-img>
                                    <div>
                                        <v-card-title
                                                class="caption font-weight-bold text-left black--text">
                                            {{ agent.name }}
                                        </v-card-title>
                                        <v-card-subtitle
                                                class="caption justify-center font-weight-light text-left black--text">
                                            {{ agent.email }}
                                        </v-card-subtitle>
                                        <!-- end -->
                                    </div>
                                </v-card>
                            </v-item>
                        </v-item-group>
                    </v-col>
                </v-row>
            </div>
        </v-card>
    </div>
</template>

<script>
  import { Agent } from '@/store/structs/agent'
  import mixin from '@/models/agents/AgentsModel'

  export default {
    name: 'Agents',

    mixins: [mixin],
    data(){
      return{
        selected: []
      }
    },
    watch: {
      agents: {
        async handler() {
          try {
            await this.getAgents()
          } catch (error) {
            console.error('Not possible to load agents')
          }
        },
        immediate: true,
        deep: true
      }
    },
    methods:{
      selectAgent(name){
        if(this.selected.includes(name)){
          let index = this.selected.indexOf(name);
          this.selected.splice(index, 1);
        }
        else
          this.selected.push(name)
        
        
        this.$emit('agent-change', this.selected)
      }
    },

    computed: {
      items() {
        let retrievedData = this.$store.state.agentsModule.list
        return Object.keys(retrievedData).map(k =>        
          new Agent(retrievedData[k])
        )
      }
    }
  }
</script>
