
<template>
<div class="special-div-table">
  <v-card-title style="" class="subtitle-2 font-weight-bold pt-2 px-0">{{$t('DEVELOPMENTS.PROPERTIES_BELONGING')}}
                    </v-card-title>
                    <v-card-subtitle class="caption font-weight-light text-left mb-2 pb-4 px-0 black--text">{{$t('DEVELOPMENTS.PROPERTIES_BELONGING_DESCRIPTION')}}
                    </v-card-subtitle>
    <v-data-table
          :key="reloadDataTable"
          class="table__minimal recent-properties elevation-0 td-data-table black--text"
          :loading="dataLoading"
          :items="items"
          :options.sync="pagination"
          :headers="headers"
          loading-text="Loading... Please wait"
          :hide-default-footer="false"
          :hide-default-header="false"
          :calculate-widths="true"
          :items-per-page="5"
  >

    <template v-slot:[`item.image`]="{ item }">
        <v-img
                :src="$mediaFile('properties', item.id, item.media.cover)"
                :lazy-src="$mediaFile('properties', item.id, item.media.cover)"
                height="75"
                width="75"
                class="grey lighten-2 d-block rounded"
        ></v-img>
    </template>

    <template v-slot:[`item.information`]="{ item }" >
        <div class="main__info">
            <div class="main__info">
                            <p class="mb-0">{{item.reference}}</p>
                            <p class="mb-0 font-weight-medium black--text listing-sub-sub-title">{{ item.information.title }}</p>
                            <p class="listing-kpis">{{ item.kpis.beds }} {{$t('Listings.beds')}} <span class="span-bullet">&#11044;</span> {{ item.kpis.baths }} {{$t('Listings.baths')}} <span class="span-bullet">&#11044;</span> {{ item.kpis.parking }} {{$t('Listings.parking')}} </p>
                        </div>
        </div>
    </template>

    <template v-slot:[`item.type_name`]="{ item }">
        <div class="main__info" style="width: auto;">
            <p v-if="$processLanguage(item.type) == ''">-</p>
            <p v-else class="mb-0 text-limit-liner secondaryCard listing-secondary-objects">{{ $processLanguage(item.type) }}</p>
        </div>
    </template>

    <template v-slot:[`item.fraction`]="{ item }">
        <div class="main__info" style="width: auto;">
            <p v-if="item.fraction == ''">-</p>
            <p v-else class="mb-0 text-limit-liner secondaryCard listing-secondary-objects">{{ item.fraction }}</p>
        </div>
    </template>
    
    <template v-slot:[`item.floor`]="{ item }">
        <div class="main__info" style="width: auto;">
            <p v-if="item.information.location.type == ''">-</p>
            <p v-else class="mb-0 text-limit-liner secondaryCard listing-secondary-objects">{{ item.floor }}</p>
        </div>
    </template>

    <template v-slot:[`item.gross_area`]="{ item }">
        <div class="main__info" style="width: auto;">
            <p v-if="item.information.location.type == ''">-</p>
            <p v-else class="mb-0 text-limit-liner secondaryCard listing-secondary-objects">{{ item.kpis.m2 }} {{$t('DEVELOPMENTS.SQ_FT')}}</p>
        </div>
    </template>

    <template v-slot:[`item.exterior_area`]="{ item }">
        <div class="main__info" style="width: auto;">
            <p v-if="item.information.location.type == ''">-</p>
            <p v-else class="mb-0 text-limit-liner secondaryCard listing-secondary-objects">{{ item.exterior_area }} {{$t('DEVELOPMENTS.SQ_FT')}}</p>
        </div>
    </template>

    <template v-slot:[`item.price`]="{ item }">
          <div class="main__info" style="width: auto;">
            <p class="mb-0 text-limit-liner secondaryCard listing-secondary-objects">{{ $currency(item.price)  }}</p>
        </div>
    </template>

    <template v-slot:[`item.tag`]="{ item }">
          <div class="main__info" style="width: auto;">
            <p class="mb-0 text-limit-liner secondaryCard listing-secondary-objects">{{ item.tag  }}</p>
        </div>
    </template>
      
  </v-data-table>
</div>
</template>


 <script>
  import mixin from '@/models/properties/PropertiesModel'
  import { PropertiesListCondominiums } from '@/store/structs/properties'
  import '@/assets/scss/plugins/datatable.css'


  export default {
    name: 'Listings',
    
    mixins: [mixin],


    data: () => ({
      myData: [],
      dataLoading: true,
      dataTableLoading: true,
      pagination: {},
      headers: null, 
      reloadDataTable: 0
    }),
    created(){
      this.headers = [
        {
          value: "image", width: '5%', text: this.$t('DEVELOPMENTS.COVER')
        },
        {
          value: "information", width: '25%', text: this.$t('DEVELOPMENTS.INFORMATION')
        },
        {
          value: "type_name", width: '13%', text: this.$t('DEVELOPMENTS.TYPE')
        },
        {
          value: "fraction", width: '5%',  text: this.$t('DEVELOPMENTS.FRACTION')
        },
        {
          value: "floor", width: '5%', text: this.$t('DEVELOPMENTS.FLOOR')
        },
        {
          value: "gross_area", width: '8%', text: this.$t('DEVELOPMENTS.GROSS')
        },
        {
          value: "exterior_area", width: '8%', text: this.$t('DEVELOPMENTS.EXTERIOR')
        },
        {
          value: "price", width: '13%', text: this.$t('DEVELOPMENTS.SOLO_PRICE')
        },
        {
          value: "tag", width: '10%', text: this.$t('DEVELOPMENTS.SOLO_TAG')
        },
      ]
    },
    watch: {
      pagination: {
        async handler() {
          try {
            this.dataLoading = true
            await this.getPropertiesFromCondominium({ id: this.$route.params.slug })
            this.dataLoading = false
          } catch (error) {
            this.dataLoading = false
          }
        },
        immediate: true,
        deep: true
      },
      items(val) {
        this.myData = val
      }
    },

    computed: {
      items() {
        let retrievedData = this.$store.state.propertiesModule.properties
        console.log("ITEMS")
        console.log(retrievedData)
        return Object.keys(retrievedData).map(k =>
          new PropertiesListCondominiums(retrievedData[k])
        )
      }
    }

  }
</script>
