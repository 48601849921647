<template>
    <div style="color: black !important;" class="v-chart-donut">
        <apexchart type="donut" :options="chartOptions" :series="series"></apexchart>
        <div style="text-align: left;">
          <p class="black--text">
            Lisbon
          </p>
          <p class="black--text">
            Belém
          </p>
          <p class="black--text">
            Cascais
          </p>
        </div>
    </div>
</template>

<script>
  import VueApexCharts from 'vue-apexcharts'

  export default {
    name: 'ChartDistricts',

    components: {
      'apexchart': VueApexCharts
    },

    data: function() {
      return {
        series: [20, 12, 2],
        chartOptions: {
          chart: {
            width: 10,
            type: 'donut'
          },
          plotOptions: {
            pie: {
              startAngle: -90,
              endAngle: 270
            }
          },
          dataLabels: {
            enabled: false
          },
          stroke: {
            show: false
          },
          fill: {
            type: 'gradient'
          },
          xaxis: {
            categories: [
              'Lisbon',
              'Belem',
              'Cascais',
            ]
          },
          colors: [
            '#7289da', '#43b581', '#FFF176'],
          tooltip: {
            custom: function({ series, seriesIndex, w }) {
              return w.globals.labels[seriesIndex] + ': ' + series[seriesIndex];
            }
          },
          legend: {
            formatter: function(val, opts) {
              return val + ' - ' + opts.w.globals.series[opts.seriesIndex]
            }
          },
          responsive: [{
            breakpoint: 480,
            options: {
              chart: {
                width: 200
              },
              legend: {
                position: 'bottom'
              }
            }
          }]
        }
      }
    }
  }
</script>
