<template>
    <v-card flat class="pt-6  text-center v-card__team">
        <!-- Properties Number -->
        <v-chip
                class="ma-1 left-top-side"
                small
                color="primary"
        >
            {{ agentData.total_properties }} {{$t('AGENTS.PROPERTIES')}}
        </v-chip>
        <!-- end -->
        

        <!-- Menu -->
        <CardMenu @delete-action="emitDelete" :agent-id="agentData.id" />
        <!-- end -->

        <!-- Basic Information -->
        <v-avatar width="100" height="100">
            <v-img
                    :src="this.$mediaFile('agents', agentData.id, agentData.avatar)"
                    :lazy-src="this.$mediaFile('agents', agentData.id, agentData.avatar)"
            />
        </v-avatar>

        <v-card-title class="subtitle justify-center font-weight-medium text-uppercase">{{ agentData.name }}</v-card-title>
        <v-card-subtitle class="justify-center font-weight-medium secondaryCard listing-information">
            {{ agentData.role }}
        </v-card-subtitle>

        <p class="px-8 mt-2 mb-7 v-description-data secondaryCard">{{ getDescription }}</p>
        <!-- end -->

        <!-- Actions -->
        <v-row>
          <v-col cols="8">
            <v-row>
              <p style="float:left;" class="ml-8">{{ getAgentEmail[0] }}</p>
            </v-row>
            <v-row>
              <p style="float:left;margin-top: -20px;" class="ml-8" >@{{ getAgentEmail[1] }}</p>
            </v-row>
          </v-col>
          <v-col cols="2">
            <v-btn depressed color="primary" small fab class="mx-2" form="type-form" type="submit" :to="'/agents/edit/' + agentData.id">
              <v-icon light>
                mdi-eye
              </v-icon>
            </v-btn>
          </v-col>
        </v-row>
        <v-row></v-row>
        <!-- end -->
    </v-card>
</template>

<script>
  import CardMenu from '@/components/agents/card/CardMenu'
  import mixin from '@/models/agents/AgentsModel'

  export default {
    name: 'Card',
    mixins: [mixin],
    props: {
      agentData: {
        type: Object,
        default: null
      }
    },

    components: { CardMenu },

    methods: {
      emitDelete(id){
        this.$emit('delete-action', id)
      }
    },
    computed:{
      getDescription(){
        let lastChar = 0

        for (let i = 100; i < this.agentData.description.length; i++) {
          if(this.agentData.description[i] == ' ' || this.agentData.description[i] == '.' || this.agentData.description[i] == '!' || this.agentData.description[i] == '?'){
            lastChar = i
            break
          }
        }

        return this.agentData.description.slice(0, lastChar) + ' (...)'
      },
      getAgentEmail(){
        return [ this.agentData.email.split("@")[0], this.agentData.email.split("@")[1] ]
      }
    }
    
  }
</script>
