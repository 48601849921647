export default [
  {
    path: '/districts',
    name: 'Districts',
    component: () => import('@/pages/Districts'),
    meta: {
      layout: 'main',
      requiresAuth: true
    },
    children: [
      {
        path: '/districts/listings',
        name: 'DistrictsListings',
        meta: {
          title: 'LIST OF DISTRICTS',
          subtitle: 'All districts added around de Portugal map',
          fluid: true
        },
        component: () =>
          import(/* webpackChunkName: "home" */ '@/pages/districts/Listings')
      },
    ]
  },
  {
    path: '/districts/add',
    name: 'newDistrict',
    meta: {
      layout: 'main',
      title: 'ADD NEW DISTRICT',
      subtitle: 'Form to add new district to your platform',
      fluid: false
    },
    component: () =>
      import(/* webpackChunkName: "home" */ '@/pages/districts/SetDistrict')
  },
  {
    path: '/districts/edit/:id',
    name: 'editDistrict',
    meta: {
      layout: 'main',
      title: 'SAVE A DISTRICT',
      subtitle: 'Form to update an district registered',
      fluid: false
    },
    component: () =>
      import(/* webpackChunkName: "home" */ '@/pages/districts/SetDistrict')
  }
]
