import * as types from '@/store/mutation-types'
import api from '@/services/api/condominiums/condominiums'
import { buildSuccess, handleError } from '@/utils/utils.js'
import router from '@/router'

const getters = {
  condominiums: (state) => state.condominiums,
}

const actions = {
  getCondominiums({ commit }, payload) {
    return new Promise((resolve, reject) => {
      api
        .getCondominiums(payload)
        .then((response) => {
          if (response.status === 200) {
            commit(types.CONDOMINIUMS, response.data.condominiums)
            resolve()
          }
        })
        .catch((error) => {
          handleError(error, commit, reject)
        })
    })
  },
  removingAllImages({ commit }, payload) {
    return new Promise((resolve, reject) => {
      api
        .removingAllImages(payload)
        .then((response) => {
          if (response.status === 201) {
            buildSuccess(
              {
                msg: 'common.SAVED_SUCCESSFULLY'
              },
              commit,
              resolve
           )
         }
        })
        .catch((error) => {
          handleError(error, commit, reject)
        })
    })
  },
  getCondominiumBlock({ commit }, payload) {
    return new Promise((resolve, reject) => {
      api
        .getCondominiumBlock(payload)
        .then((response) => {
          if (response.status === 200) {
            commit(types.CONDOMINIUM_BLOCK, response.data.block)
            resolve()
          }
        })
        .catch((error) => {
          handleError(error, commit, reject)
        })
    })
  },
  getCondominiumTags({ commit }, payload) {
    return new Promise((resolve, reject) => {
      api
        .getCondominiumTags(payload)
        .then((response) => {
          if (response.status === 200) {
            //console.log(response.data.tags);
            commit(types.TAGS, response.data.tags)
            resolve()            
          }
        })
        .catch((error) => {
          handleError(error, commit, reject)
        })
    })
  },
  saveCondominium({ commit }, payload) {
    
    return new Promise((resolve, reject) => {
      api
        .saveCondominium(payload)
        .then((response) => {
          if (response.status === 201) {
            buildSuccess(
              {
                msg: 'common.SAVED_SUCCESSFULLY'
              },
              commit,
              resolve,
              router.push({
                path:`/condominiums/edit/${response.data.condominium_id}/general`
              })
            )
          }
        })
        .catch((error) => {
          handleError(error, commit, reject)
        })
    })
  },
  condominiumQuickChange({ commit }, payload) {
    return new Promise((resolve, reject) => {
      api
        .condominiumQuickChange(payload)
        .then((response) => {
          if (response.status === 202) {
            buildSuccess(
             {
                msg: 'common.SAVED_SUCCESSFULLY'
              },
              commit,
              resolve
            )
          }
        })
        .catch((error) => {
          handleError(error, commit, reject)
        })
    })
  },
  reorderImageCondominium({ commit }, payload) {
    return new Promise((resolve, reject) => {
      api
        .reorderImageCondominium(payload)
        .then((response) => {
          if (response.status === 202) {
            buildSuccess(
              {
                msg: 'common.SAVED_SUCCESSFULLY'
              },
              commit,
              resolve
            )
          }
        })
        .catch((error) => {
          handleError(error, commit, reject)
        })
    })
  },  
  editCondominium({ commit }, payload) {
    return new Promise((resolve, reject) => {
      api
        .editCondominium(payload)
        .then((response) => {
          if (response.status === 202) {
            buildSuccess(
              {
                msg: 'common.SAVED_SUCCESSFULLY'
              },
              commit,
              resolve
            )
          }
        })
        .catch((error) => {
          handleError(error, commit, reject)
        })
    })
  },
  saveCondominiumBlock({ commit }, payload) {
    return new Promise((resolve, reject) => {
      api
        .saveCondominiumBlock(payload)
        .then((response) => {
          if (response.status === 202) {
            buildSuccess(
              {
                msg: 'common.SAVED_SUCCESSFULLY'
              },
              commit,
              resolve
            )
          }
        })
        .catch((error) => {
          handleError(error, commit, reject)
        })
    })
  },
  changeCoverImageCondominium({ commit }, payload) {
    return new Promise((resolve, reject) => {
      api
        .changeCoverImageCondominium(payload)
        .then((response) => {
          if (response.status === 201) {
            buildSuccess(
              {
                msg: 'common.SAVED_SUCCESSFULLY'
              },
              commit,
              resolve
            )
          }
        })
        .catch((error) => {
          handleError(error, commit, reject)
        })
    })
  },
  attributeCondominiumPlant({ commit }, payload) {
    return new Promise((resolve, reject) => {
      api
        .attributeCondominiumPlant(payload)
        .then((response) => {
          if (response.status === 201) {
            buildSuccess(
              {
                msg: 'common.SAVED_SUCCESSFULLY'
              },
              commit,
              resolve
            )
          }
        })
        .catch((error) => {
          handleError(error, commit, reject)
        })
    })
  },
  removeImageCondominium({ commit }, payload) {
    return new Promise((resolve, reject) => {
      api
        .removeImageCondominium(payload)
        .then((response) => {
          if (response.status === 201) {
            buildSuccess(
              {
                msg: 'common.SAVED_SUCCESSFULLY'
              },
              commit,
              resolve
            )
          }
        })
        .catch((error) => {
          handleError(error, commit, reject)
        })
    })
  },
  saveFileGeneratedCondominium({ commit }, payload) {
    return new Promise((resolve, reject) => {
      api
        .saveFileGeneratedCondominium(payload)
        .then((response) => {
          if (response.status === 201) {
            buildSuccess(
              {
                msg: 'common.SAVED_SUCCESSFULLY'
              },
              commit,
              resolve
            )
          }
        })
        .catch((error) => {
          handleError(error, commit, reject)
        })
    })
  },
  saveMediaFilesCondominium({ commit }, payload) {
    return new Promise((resolve, reject) => {
      api
        .saveMediaFilesCondominium(payload)
        .then((response) => {
          if (response.status === 201) {
            buildSuccess(
              {
                msg: 'common.SAVED_SUCCESSFULLY'
              },
              commit,
              resolve
            )
          }
        })
        .catch((error) => {
          handleError(error, commit, reject)
        })
    })
  },
  saveAttachmentCondominium({ commit }, payload) {
    return new Promise((resolve, reject) => {
      api
        .saveAttachmentCondominium(payload)
        .then((response) => {
          if (response.status === 201) {
            buildSuccess(
              {
                msg: 'common.SAVED_SUCCESSFULLY'
              },
              commit,
              resolve
            )
          }
        })
        .catch((error) => {
          handleError(error, commit, reject)
        })
    })
  },
  removeAttachmentCondominium({ commit }, payload) {
    return new Promise((resolve, reject) => {
      api
        .removeAttachmentCondominium(payload)
        .then((response) => {
          if (response.status === 201) {
            buildSuccess(
              {
                msg: 'common.SAVED_SUCCESSFULLY'
              },
              commit,
              resolve
            )
          }
        })
        .catch((error) => {
          handleError(error, commit, reject)
        })
    })
  },
  archiveOneCondominium({ commit }, payload) {
    return new Promise((resolve, reject) => {
      api
        .archiveOneCondominium(payload)
        .then((response) => {
          if (response.status === 202) {
            buildSuccess(
              {
                msg: 'common.SAVED_SUCCESSFULLY'
              },
              commit,
              resolve
           )
         }
        })
        .catch((error) => {
          handleError(error, commit, reject)
        })
    })
  },
  unArchiveOneCondominium({ commit }, payload) {
    return new Promise((resolve, reject) => {
      api
        .unArchiveOneCondominium(payload)
        .then((response) => {
          if (response.status === 202) {
            buildSuccess(
              {
                msg: 'common.SAVED_SUCCESSFULLY'
              },
              commit,
              resolve
           )
         }
        })
        .catch((error) => {
          handleError(error, commit, reject)
        })
    })
  },
  removePermanentlyCondominium({ commit }, payload) {
    return new Promise((resolve, reject) => {
      api
        .removePermanentlyCondominium(payload)
        .then((response) => {
          if (response.status === 200) {
            buildSuccess(
              {
                msg: 'common.SAVED_SUCCESSFULLY'
              },
              commit,
              resolve
           )
         }
        })
        .catch((error) => {
          handleError(error, commit, reject)
        })
    })
  },
  saveMapMarkersCondominium({ commit }, payload) {
    return new Promise((resolve, reject) => {
      api
        .saveMapMarkersCondominium(payload)
        .then((response) => {
          if (response.status === 202) {
            buildSuccess(
              {
                msg: 'common.SAVED_SUCCESSFULLY'
              },
              commit,
              resolve
            )
          }
        })
        .catch((error) => {
          handleError(error, commit, reject)
        })
    })
  },
  removeMapMarkerCondominium({ commit }, payload) {
    return new Promise((resolve, reject) => {
      api
        .removeMapMarkerCondominium(payload)
        .then((response) => {
          if (response.status === 202) {
            buildSuccess(
              {
                msg: 'common.SAVED_SUCCESSFULLY'
              },
              commit,
              resolve
            )
          }
        })
        .catch((error) => {
          handleError(error, commit, reject)
        })
    })
  },
}

const mutations = {
  [types.CONDOMINIUMS](state, condominiums) {
    state.condominiums = condominiums
  },
  [types.CONDOMINIUM_BLOCK](state, block) {
    state.block = block
  }
}

const state = {
  condominiums: [],
  block: []
}

export default {
  state,
  getters,
  actions,
  mutations
}