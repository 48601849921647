export class User {
  constructor(object = null) {
    this.id = object.id
    this.name = object.name
    this.email = object.email
    this.avatar = object.avatar
    this.initials = object.initials
  }
}

export default { User }
