import Vue from 'vue'
import Vuetify from 'vuetify'
import 'vuetify/src/styles/main.sass'
import '@mdi/font/css/materialdesignicons.css'

// Custom styles
import '@/assets/scss/style.app.css'

Vue.use(Vuetify)

const theme = JSON.parse(localStorage.getItem('dark')) || false
const opts = {
  theme: {
    dark: false,
    themes: {
      light: {
        primary: '#BE5E3B',
        secondary: '#BE5E3B',
        'light-grey': '#b9bbbe',
        'light-bg': '#ffffff',
        'light-bg-light': '#ffffff',
        'danger': '#d11a2a'
      },
      dark: {
        primary: '#7289da',
        secondary: '#43b581',
        'light-grey': '#b9bbbe',
        'dark-bg': '#36393f',
        'dark-bg-light': '#464b52',
        'danger': '#d11a2a'
      },
    },
  },
  iconfont: 'mdi'
}

export default new Vuetify(opts)
