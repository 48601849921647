import { buildPayloadPagination } from '@/utils/utils'
import { mapActions } from 'vuex'

const mixin = {

  created: function() {
    console.debug(`${this.target} action called.`)
  },

  methods: {
    ...mapActions(['getKeyItems', 'saveKey', 'editKey', 'deleteKey']),

    async deleteItem(item) {
      try {
        const response = await this.$confirm(
          this.$t('common.DO_YOU_REALLY_WANT_TO_DELETE_THIS_ITEM'),
          {
            title: this.$t('common.WARNING'),
            buttonTrueText: this.$t('common.DELETE'),
            buttonFalseText: this.$t('common.CANCEL'),
            buttonTrueColor: 'custom-primary-delete',
            buttonFalseColor: 'custom-cancel',
            color: 'primary'
          }
        )

        if (response) {
          this.dataTableLoading = true

          await this.deleteKey({ 'id': item.id, 'target': this.target })
          await this.getKeyItems(
            buildPayloadPagination(this.pagination, this.buildSearch(), this.target)
          )

          this.dataTableLoading = false
        }

      } catch (error) {
        this.dataTableLoading = false
      }

      return true
    },

    async save(form) {
      try {
        this.dataTableLoading = true
        // Updating item
        if (form.id !== null) {
          await this.editKey({'data': form, 'target': this.target})
          await this.getKeyItems(
            buildPayloadPagination(this.pagination, this.buildSearch(), this.target)
          )
          this.dataTableLoading = false
        } else {
          // Creating new item
          await this.saveKey({'data': form, 'target': this.target})
          await this.getKeyItems(
            buildPayloadPagination(this.pagination, this.buildSearch(), this.target)
          )
          this.dataTableLoading = false
        }
        //this.close()
        // eslint-disable-next-line no-unused-vars
      } catch (error) {
        this.dataTableLoading = false
        this.close()
      }
    }
  }

}

export default mixin