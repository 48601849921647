export default [
  {
    path: '/key-items',
    name: 'KeyItems',
    component: () => import('@/pages/Keys.vue'),
    redirect: '/key-items/types',
    meta: {
      layout: "main",
      requiresAuth: true,
    },
    children: [
      {
        path: '/key-items/types',
        name: 'types',
        meta: {
          title: 'PROPERTY TYPES',
          subtitle: 'Filter to be applied on your website',
          fluid: true
        },
        component: () =>
          import(/* webpackChunkName: "home" */ '@/pages/keys/Types')
      },
      {
        path: '/key-items/lifestyles',
        name: 'lifestyles',
        meta: {
          title: 'LIFESTYLES OF PROPERTIES',
          subtitle: 'List of lifestyles for property filtering.',
          fluid: true
        },
        component: () =>
          import(/* webpackChunkName: "home" */ '@/pages/keys/Lifestyles')
      },
      {
        path: '/key-items/features',
        name: 'features',
        meta: {
          title: 'FEATURES OF PROPERTIES',
          subtitle: 'List of features for property filtering.',
          fluid: true
        },
        component: () =>
          import(/* webpackChunkName: "home" */ '@/pages/keys/Features')
      },
      {
        path: '/key-items/listings',
        name: 'Listings',
        meta: {
          title: 'LISTINGS OF PROPERTIES',
          subtitle: 'List of listings for property filtering.',
          fluid: true
        },
        component: () =>
          import(/* webpackChunkName: "home" */ '@/pages/keys/Listings')
      },
      {
        path: '/key-items/master',
        name: 'master',
        meta: {
          title: 'MASTER OF PROPERTIES',
          subtitle: 'List of master keys',
          fluid: true
        },
        component: () =>
          import(/* webpackChunkName: "home" */ '@/pages/keys/Master')
      },
    ]
  }
]
