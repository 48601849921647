<template>
    <v-navigation-drawer permanent color="white" class="main__navigation" >
        
        <div class="headline ml-0 redefiningRealEstate" >
            <div class="logo" >
                <router-link
                        :to="{ name: 'home' }"
                        tag="div"
                >
                    {{ $t('navigationMenu.TITLE') }}
                    <small class="d-block appSloganColor">{{ $t('navigationMenu.SUBTITLE') }}</small>
                </router-link>
            </div>
        </div>
        <!-- Quick Action -->
        <QuickAction/>
        <!-- end -->

        <v-list style="overflow:hidden;"
                dense
                nav
                v-for="type in navigation"
                :key="type.id"
                :class="[type.title.length > 0 ? 'mt-3' : 'mt-0']"
        >
            <p class="overline text-left" v-if="type.title.length > 0">{{ type.title }}</p>

            <tag 
                    v-for="item in type.items"
                    :is="item.tag"
                    :key="item.title"
                    link
                    :to="checkDisabled(item) || typeof item.emit !== 'undefined' ? '' : '/' + item.link"
                    @click="disableMenu(checkDisabled(item)); sendEmit(item)"
                    :active-class="'font-weight-bold ' + item.color + '--text'"
            >
                <!-- Template with Group -->
                <template v-if="item.sub" v-slot:activator>
                    <v-list-item-icon>
                        <i :class="'zwicon-' + item.icon + ' ' + item.color + '--text'"></i>
                    </v-list-item-icon>

                    <v-list-item-content>
                        <v-list-item-title
                                :class="[activeRoute.includes(item.title.toLowerCase()) ? 'v-list-item--active secondary--text' : '']">
                            {{ item.title }}
                        </v-list-item-title>
                    </v-list-item-content>
                </template>

                <template v-if="item.sub">
                    <v-list-item
                            class="child--link"
                            v-for="child in item.items"
                            :key="child.title"
                            :to="checkDisabled(child) ? '' : '/' + child.link"
                            @click="disableMenu(checkDisabled(child))"
                    >
                        <v-list-item-content>
                            <v-list-item-title v-text="child.title"></v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </template>
                <!-- end -->

                <!-- Template without Group -->
                <template v-else>
                    <v-list-item-icon>
                        <i :class="'zwicon-' + item.icon + ' ' + item.color + '--text'"></i>
                    </v-list-item-icon>

                    <v-list-item-content>
                        <v-list-item-title>{{ item.title }}</v-list-item-title>
                    </v-list-item-content>
                </template>
                <!-- end -->

            </tag>
        </v-list>
    </v-navigation-drawer>
</template>

<script>
  import NavigationData from '@/data/core/navigation'
  import { handleInfo } from '@/utils/utils'
  import { mapGetters } from 'vuex'

  export default {
    name: 'Navigation',
    
    data() {
      return {
        navigation: NavigationData.getNavigationList(this)
      }
    },

    methods: {
      sendEmit(menu) {
        if (typeof menu.emit !== 'undefined'){
          this.$emit(menu.emit, true)
        }
      },
      checkDisabled(menu) {
        return typeof menu.disabled !== 'undefined' && menu.disabled
      },
      disableMenu(state) {
        // If disabled
        if (state) {
          handleInfo(
            {
              msg: 'errors.UNDER_CONSTRUCTION',
              params: [],
              timeout: 5000
            }
          )
        }

      }
    },

    computed: {
      ...mapGetters(['appTitle', 'appSlogan', 'isTokenSet', 'user']),
      activeRoute() {
        return this.$route.fullPath
      }
    }

  }
</script>
