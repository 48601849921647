 export default class UserForm {
  constructor({
                id = null,
                name = '',
                email = '',
                password = '',
                initials= '',
                avatar=''
              }) {
    this.id = id || null
    this.name = name || ''
    this.email = email || null
    this.password = password || null
    this.initials = initials || null
    this.avatar = avatar || null
  }
}

