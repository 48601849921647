<template>
    <v-row>
        <v-col cols="12">
        <v-subheader class="pl-0">
            {{title}}
        </v-subheader>
        <v-slider
            :min="min"
            :max="max"
            :thumb-size="15"
            v-model="slider"
            thumb-label="always"
        ></v-slider>
        </v-col>
    </v-row>
</template>

<script>
  export default {
    name: 'SimpleSlider',
    props:{
        title:{
        type: String,
        default: ''
        },
        max: {
          type: Number,
          default: 20
        },
        min: {
          type: Number,
          default: 0
        },
        model:{
          type: Number,
          default: -1
        }
    },
    data(){
        return{
            slider: 0
        }
    },
    created(){
      if(this.model != -1)
        this.slider = this.model

      console.log("SLIDER:" + this.slider)
    },
    watch:{
      slider(){
        this.$emit('change', this.slider)
      }
    },
  }
</script>