import * as types from '@/store/mutation-types'
import api from '@/services/api/whoweare/whoweare'
import { buildSuccess, handleError } from '@/utils/utils.js'
//import router from '@/router'


const getters = {
  whoWeAre: (state) => state.who_we_are,
  }
  
  const actions = {
    getWhoWeAre({ commit }, payload) {
      return new Promise((resolve, reject) => {
        api
          .getWhoWeAre(payload)
          .then((response) => {
            if (response.status === 200) {
              commit(types.WHO_WE_ARE, response.data.whoWeAre)
              resolve()
            }
          })
          .catch((error) => {
            handleError(error, commit, reject)
          })
      })
    },
    saveWhoWeAre({ commit }, payload) {
      return new Promise((resolve, reject) => {
        api
          .saveWhoWeAre(payload)
          .then((response) => {
            if (response.status === 201) {
              buildSuccess(
                {
                  msg: 'common.SAVED_SUCCESSFULLY'
                },
                commit,
                resolve                
              )
            }
          })
          .catch((error) => {
            handleError(error, commit, reject)
          })
      })
    },

    
}

const mutations = {
    [types.WHO_WE_ARE](state, who_we_are) {
      state.who_we_are = who_we_are
    }
  }
  
  const state = {
    who_we_are: [],
  }
  
  export default {
    state,
    getters,
    actions,
    mutations
  }
  