var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"special-div-table"},[_c('v-card-title',{staticClass:"subtitle-2 font-weight-bold pt-2 px-0"},[_vm._v(_vm._s(_vm.$t('DEVELOPMENTS.PROPERTIES_BELONGING'))+" ")]),_c('v-card-subtitle',{staticClass:"caption font-weight-light text-left mb-2 pb-4 px-0 black--text"},[_vm._v(_vm._s(_vm.$t('DEVELOPMENTS.PROPERTIES_BELONGING_DESCRIPTION'))+" ")]),_c('v-data-table',{key:_vm.reloadDataTable,staticClass:"table__minimal recent-properties elevation-0 td-data-table black--text",attrs:{"loading":_vm.dataLoading,"items":_vm.items,"options":_vm.pagination,"headers":_vm.headers,"loading-text":"Loading... Please wait","hide-default-footer":false,"hide-default-header":false,"calculate-widths":true,"items-per-page":5},on:{"update:options":function($event){_vm.pagination=$event}},scopedSlots:_vm._u([{key:"item.image",fn:function(ref){
var item = ref.item;
return [_c('v-img',{staticClass:"grey lighten-2 d-block rounded",attrs:{"src":_vm.$mediaFile('properties', item.id, item.media.cover),"lazy-src":_vm.$mediaFile('properties', item.id, item.media.cover),"height":"75","width":"75"}})]}},{key:"item.information",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"main__info"},[_c('div',{staticClass:"main__info"},[_c('p',{staticClass:"mb-0"},[_vm._v(_vm._s(item.reference))]),_c('p',{staticClass:"mb-0 font-weight-medium black--text listing-sub-sub-title"},[_vm._v(_vm._s(item.information.title))]),_c('p',{staticClass:"listing-kpis"},[_vm._v(_vm._s(item.kpis.beds)+" "+_vm._s(_vm.$t('Listings.beds'))+" "),_c('span',{staticClass:"span-bullet"},[_vm._v("⬤")]),_vm._v(" "+_vm._s(item.kpis.baths)+" "+_vm._s(_vm.$t('Listings.baths'))+" "),_c('span',{staticClass:"span-bullet"},[_vm._v("⬤")]),_vm._v(" "+_vm._s(item.kpis.parking)+" "+_vm._s(_vm.$t('Listings.parking'))+" ")])])])]}},{key:"item.type_name",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"main__info",staticStyle:{"width":"auto"}},[(_vm.$processLanguage(item.type) == '')?_c('p',[_vm._v("-")]):_c('p',{staticClass:"mb-0 text-limit-liner secondaryCard listing-secondary-objects"},[_vm._v(_vm._s(_vm.$processLanguage(item.type)))])])]}},{key:"item.fraction",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"main__info",staticStyle:{"width":"auto"}},[(item.fraction == '')?_c('p',[_vm._v("-")]):_c('p',{staticClass:"mb-0 text-limit-liner secondaryCard listing-secondary-objects"},[_vm._v(_vm._s(item.fraction))])])]}},{key:"item.floor",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"main__info",staticStyle:{"width":"auto"}},[(item.information.location.type == '')?_c('p',[_vm._v("-")]):_c('p',{staticClass:"mb-0 text-limit-liner secondaryCard listing-secondary-objects"},[_vm._v(_vm._s(item.floor))])])]}},{key:"item.gross_area",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"main__info",staticStyle:{"width":"auto"}},[(item.information.location.type == '')?_c('p',[_vm._v("-")]):_c('p',{staticClass:"mb-0 text-limit-liner secondaryCard listing-secondary-objects"},[_vm._v(_vm._s(item.kpis.m2)+" "+_vm._s(_vm.$t('DEVELOPMENTS.SQ_FT')))])])]}},{key:"item.exterior_area",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"main__info",staticStyle:{"width":"auto"}},[(item.information.location.type == '')?_c('p',[_vm._v("-")]):_c('p',{staticClass:"mb-0 text-limit-liner secondaryCard listing-secondary-objects"},[_vm._v(_vm._s(item.exterior_area)+" "+_vm._s(_vm.$t('DEVELOPMENTS.SQ_FT')))])])]}},{key:"item.price",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"main__info",staticStyle:{"width":"auto"}},[_c('p',{staticClass:"mb-0 text-limit-liner secondaryCard listing-secondary-objects"},[_vm._v(_vm._s(_vm.$currency(item.price)))])])]}},{key:"item.tag",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"main__info",staticStyle:{"width":"auto"}},[_c('p',{staticClass:"mb-0 text-limit-liner secondaryCard listing-secondary-objects"},[_vm._v(_vm._s(item.tag))])])]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }