<template>
    <component :is="this.appLayout + '-layout' || 'div'">
        <router-view></router-view>
    </component>
</template>

<script>
  import { store } from '@/store'
  import Footer from '@/components/core/Footer'

  export default {
    name: 'App',

    components: {
      'main-layout': () => import('@/layouts/Main'),
      'blank-layout': () => import('@/layouts/Blank'),
    },

    metaInfo() {
      return {
        title: this.appTitle,
        htmlAttrs: {
          lang: this.$i18n.locale
        },
        meta: [
          { name: 'msapplication-TileColor', content: '#ffc40d' },
          { name: 'theme-color', content: '#ffffff' },
          {
            name: 'apple-mobile-web-app-title',
            content: this.appTitle
          },
          { name: 'application-name', content: this.appTitle }
        ],
        link: [
          {
            rel: 'apple-touch-icon',
            sizes: '180x180',
            href: '/apple-touch-icon.png'
          },
          {
            rel: 'icon',
            type: 'image/png',
            sizes: '32x32',
            href: '/favicon-32x32.png'
          },
          {
            rel: 'icon',
            type: 'image/png',
            sizes: '16x16',
            href: '/favicon-16x16.png'
          },
          { rel: 'manifest', href: '/site.webmanifest' },
          { rel: 'mask-icon', color: '#5bbad5', href: '/safari-pinned-tab.svg' },
          { rel: 'favicon', href: '/favicon.ico' }
        ]
      }
    },

    computed: {
      appTitle() {
        return this.$store.getters.appTitle
      },
      appLayout() {
        return store.state.app.appLayout
      }
    }
  }
</script>

<style>
    .fade-enter-active,
    .fade-leave-active {
        transition: opacity 0.1s;
    }

    .fade-enter,
    .fade-leave-to {
        opacity: 0;
    }
</style>
