import axios from 'axios'
//import { getFormData, getFormDataMultidimensional } from '@/utils/utils'


export default {
  exportToPlatform(params) {
    ///exportProperties/{place}/{properties}
    const formData = new FormData()
    formData.append('properties', params.properties)
    formData.append('place', params.place)
    formData.append('url', params.url)
    return axios.post('/integrations/exportProperties', formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }
    )
  },  
  deleteFromPlatform(params){
    return axios.get('/integrations/removeProperty/'+params.place+'/'+params.id+'/'+params.uuid+'/'+params.url)
  },
  imovirtualGetAuthorizationCode(){
    return axios.get('/integrations/getAuthorizationCode')
  },
  // not used
  checkInsertion(params){
    return axios.get('/integrations/checkInsertion/'+params.place+'/'+params.id)
  },
  updateToPlatform(params){
    return axios.get('/integrations/updateProperty/'+params.place+'/'+params.id+'/'+params.uuid+'/'+params.url)
  },
  downloadErrorFile(params){
    return axios.get('/integrations/downloadErrorFile/'+params.block+'/'+params.uuid)
  }
}
