<template>
    <div class="v-files-list">
        <v-row>
          <v-col
                    v-for="(file, index) in files"
                    :key="index"
                    class="d-flex child-flex"
                    :cols="0 ? 12 : 4"
                    :class="{ 'v-files-list-img-cover' : 0 }"
            >              
                <v-img
                    
                        :src="$extensionsFile(file.extension)"
                        :lazy-src="$extensionsFile(file.extension)"
                        aspect-ratio="1"
                        class="grey darken-2 rounded"
                >
                    <template v-slot:default>
                        <div class="v-files-list-info">
                            <div class="header">
                                <!-- Information -->
                                <div class="pa-5 d-flex justify-space-between">
                                    <div>
                                        <p class="text-left white--text pb-0 mb-0"
                                           :class="0 ? 'body-2' : 'caption'">{{ file.file }}</p>
                                        <p class="text-left" :class="0 ? 'body-2' : 'caption'">
                                            <small class="grey--text darken-2">{{ file.extension }}</small>
                                        </p>
                                    </div>                                    
                                </div>
                                <!-- end -->
                                <!-- Overlay -->
                                <ImageOverlay dir="header"/>
                                <!-- end -->
                            </div>
                            <div class="footer pa-5">
                                <!-- Actions -->                               
                                <v-btn class="remove pa-0 ml-1 white--text" @click="removeAttachment(location, file.file, file.id)">
                                    <i class="zwicon-trash"></i>
                                </v-btn>
                                <v-btn class="remove pa-0 ml-1 white--text" @click="downloadFile(file.id, file.file, file.extension)">
                                    <i class="zwicon-file-download"></i>
                                </v-btn>
                                <!-- end -->
                            </div>
                        </div>
                    </template>
                    <template v-slot:placeholder>
                        <v-row
                                class="fill-height ma-0"
                                align="center"
                                justify="center"
                        >
                            <v-progress-circular
                                    indeterminate
                                    color="grey lighten-5"
                            ></v-progress-circular>
                        </v-row>
                    </template>
                </v-img>
              
            </v-col>    
        </v-row>


    </div>
</template>

<script>
  import ImageOverlay from '@/components/media/ImageOverlay'

  export default {
    name: 'FilesList',

    components: {
      ImageOverlay
    },
    props: ['files', 'ID', 'location'],

    data: () => ({
      dialogStatus: false,
      selectedImage: null,
      imageName: null,
      mediaID: null
    }),
    
    created() {
      
    },

    methods: {
      saveFileGenerated(file){
        this.$emit('generated-file', file, this.ID, this.mediaID, this.imageName)  
      },
      makePhotoAsCover(location, file, media_id){
        this.$emit('change-cover', file, this.ID, media_id, this.imageName) 
      },
      removeAttachment(location, fileName, media_id){
        this.$emit('remove-attachment', location, fileName, media_id) 
      },
      async downloadFile(id, pdfFile, extension) {
            if(pdfFile == null)
                return
            let localization = 'condominiums'
            if(this.$route.fullPath.includes('properties'))
              localization = 'properties'

            let myURL = this.$mediaFile(localization, 'attachments/'+this.$route.params.slug, pdfFile)
            
            await axios({
                url: myURL,
                method: 'GET',
                responseType: 'blob',
            }).then((response) => {
                    var fileURL = window.URL.createObjectURL(new Blob([response.data]));
                    var fURL = document.createElement('a');

                    fURL.href = fileURL;
                    fURL.setAttribute('download', pdfFile+'.'+extension);
                    document.body.appendChild(fURL);

                    fURL.click();
            });
              return [id, pdfFile]
          }
    }

  }
</script>