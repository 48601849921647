<template>
    <ValidationProvider :rules="rules" v-slot="{ errors }">
        <v-text-field
                class="v-input-text"
                filled
                :placeholder="placeholder"
                :id="id"
                :name="name"
                :type="type"
                :label="label"
                :persistent-hint="persistentHint"
                :hint="hint"
                :counter="counter"
                :prefix="prefix"
                :suffix="suffix"
                autocomplete="off"
                v-model="fillData"
                :error="errors.length > 0"
                :error-messages="errors[0]"
                :class="{ 'v-text-field--suffix': suffix }"
                @input="updateValue"
                @change="updateValue"
                @blur="onBlurAction"
        ></v-text-field>
    </ValidationProvider>
</template>

<script>
  export default {
    name: 'InputText',
    props: {
      id: String,
      name: String,
      label: String,
      type: {
        type: String,
        default: 'text'
      },
      prefix: {
        type: String,
        default: null
      },
      suffix: {
        type: String,
        default: null
      },
      placeholder: {
        type: String,
        default: null
      },
      hint: String,
      persistentHint: {
        type: Boolean,
        default: false
      },
      counter: Number,
      rules: String,
      fill: [Number, String, Array],
    },

    computed: {
      fillData: {
        get() {
          return this.fill
        },
        set(value) {
          this.$emit('update:prop', value)
        }
      }
    },

    methods: {
      updateValue(e) {
        this.$emit('input', e)
      },
      onBlurAction(){
        this.$emit('blur')
      }
    }
  }
</script>
