<template>
    <v-container :fluid="this.$route.meta.fluid">
        <div class="content__title d-flex align-end">
            <h2 class="text-uppercase font-weight-light">
                {{ $route.meta.title }}
                <small>{{ $route.meta.subtitle }}</small>
            </h2>
        </div>
    </v-container>
</template>

<script>
  export default {
    name: 'Header',
  }
</script>
