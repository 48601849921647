<template>
    <div class="quick__option">      
        <v-btn to="/properties/new" depressed color="primary" class="px-6" style="margin-top: -10px;">
            {{ $t('navigationMenu.QUICK_ACTION.new_property') }}
        </v-btn>
        <br>
        <v-btn to="/condominiums/new" depressed color="primary" class="px-6" style="margin-top: 5px;">
            {{ $t('navigationMenu.QUICK_ACTION.new_condominium') }}
        </v-btn>
    </div>
</template>

<script>
  export default {
    name: 'QuickAction'
  }
</script>

<style scoped>
  .px-6{
    width: 100%;
  }
</style>