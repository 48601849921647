import Vue from 'vue'
import '@/plugins/axios'
import currency from '@/plugins/currency'
import screenWidth from '@/plugins/screenWidth'
import vuetify from '@/plugins/vuetify'
import '@/plugins/veevalidate'
import '@/plugins/common'
import '@/plugins/form'
import '@/plugins/googleAnalytics'
import '@/plugins/googleMaps'
import i18n from '@/plugins/i18n'
import App from '@/App.vue'
import router from '@/router'
import { store } from '@/store'
import VuetifyConfirm from 'vuetify-confirm'
import VueCurrencyInput from 'vue-currency-input'
import ToggleButton from 'vue-js-toggle-button'
import Main from '@/layouts/Main'
import Blank from '@/layouts/Blank'
import mediaFile from '@/plugins/media'
import extensionsFile from '@/plugins/extensions'
import frontConnection from '@/plugins/frontConnection'
import processLanguage from '@/plugins/processLanguage'
import 'v-tab/dist/v-tab.css'
import DataTable from '@andresouzaabreu/vue-data-table'
import plugin from '@serializedowen/vue-img-watermark'
import VueCustomTooltip from '@adamdehaven/vue-custom-tooltip'
import VueCookies from 'vue-cookies'


import Grid from 'vue-js-grid'
Vue.config.productionTip = false
Vue.use(plugin)
Vue.use(VueCurrencyInput)
Vue.use(Grid)
Vue.use(VueCookies)
Vue.use(ToggleButton)
Vue.use(VueCustomTooltip)
Vue.use(VuetifyConfirm, { vuetify })
Vue.prototype.$mediaFile = mediaFile
Vue.prototype.$extensionsFile = extensionsFile
Vue.prototype.$frontConnection = frontConnection
Vue.prototype.$processLanguage = processLanguage
Vue.prototype.$currency = currency
Vue.prototype.$screenWidth = screenWidth

Vue.component('main-layout', Main)
Vue.component('blank-layout', Blank)
Vue.component("data-table", DataTable);

const app = new Vue({
  vuetify,
  router,
  store,
  i18n,
  render: (h) => h(App),
  created() {
    store.dispatch('setLocale', store.getters.locale)
    if (store.getters.isTokenSet) {
      store.dispatch('autoLogin') 
    }
  }
}).$mount('#app')

if (window.Cypress) {
  // Only available during E2E tests
  window.app = app
}
