<template>
    <v-navigation-drawer color="transparent" class="menu__right">
        <v-list
                class="minimal__menu"
                flat
                color="transparent">
            <v-list-item
                    v-for="(menu, i) in list" :key="i"
                    :to="checkDisabled(menu) ? '' : alternativeRoute(menu) ? menu.alternative : menu.path"
                    
                    class="text-capitalize"
                    :disabled="checkDisabled(menu)"
                    @click.native="disableMenu(checkDisabled(menu))"
            >
                {{ menu.name }}
            </v-list-item>
        </v-list>
    </v-navigation-drawer>
</template>

<script>
  import { handleInfo } from '@/utils/utils'

  function commit() {
    return true
  }

  export default {
    name: 'RightMenu',

    props: {
      menus: {
        type: Array,
        default: null
      }
    },

    methods: {
      alternativeRoute(menu) {
        return typeof this.$route.params.slug !== 'undefined' && typeof menu.alternative !== 'undefined'
      },
      checkDisabled(menu) {
        return typeof menu.match !== 'undefined' && this.activeRoute.includes(menu.match.route)
      },
      disableMenu(state) {
        if (state) {
          handleInfo(
            {
              msg: 'properties.DRAFT',
              params: [],
              timeout: 5000
            }
          )
        }
      }
    }
    ,

    computed: {
      hash() {
        return window.location.hash
      }
      ,
      list() {
        if (this.menus === null) {
          return this.$router.options.routes.find(item => item.path === this.$route.matched[0].path).children
        }
        return this.menus
      }
      ,

      activeRoute() {
        return this.$route.fullPath
      }

    }
  }
</script>
