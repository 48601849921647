<template>
    <div v-if="agent" class="d-flex align-center px-2 py-4 v-card__property__agent">
        <v-avatar width="35" height="35">
            <img
                    :src="agent.avatar"
                    :alt="agent.name.first"
            >
        </v-avatar>
        <p class="mb-0 caption font-weight-light white--text ml-1">{{ agent.name.first }} {{ agent.name.last }}</p>
    </div>
</template>

<script>
  export default {
    name: 'Agent',
    props: {
      agent: {
        type: Object,
        default: null
      }
    }
  }
</script>
