<template>
    <!-- Types -->
    <input-select
            v-model="formData"
            :items="items"
            :fill="formData != null ? parseInt(formData.price) : 0"
            :label="getLabel" 
            item-text="title" 
            item-value="id"
            @input="input"
            :addRemoveOption="true"
            >
    </input-select>
</template>

<script>
  import mixin from '@/models/properties/PropertiesModel'
  import { PropertyCondominiumList } from '@/store/structs/properties'

  export default {
    name: 'Condominiums',

    mixins: [mixin],

    data: () => ({
      dataLoading: true,
      formData: PropertyCondominiumList
    }),

    props: {
      form: {}
    },
    watch: {
      sync: {
        async handler() {
          try {
            this.dataLoading = true
            await this.getSelectListCondominiums()
            this.dataLoading = false
            // eslint-disable-next-line no-unused-vars
          } catch (error) {
            console.log(error);
            this.dataLoading = false
          }
        },
        immediate: true,
        deep: true
      }
    },

    computed: {
      items() {
        let retrievedData = this.$store.state.propertiesModule.properties        
        return Object.keys(retrievedData).map(k =>
          new PropertyCondominiumList(retrievedData[k])
        )   
      },
      getLabel(){
        if(this.form.condominium_id == null)
          return this.$t('PROPERTIES.LINK_TO_CONDO')
        
        let retrievedData = this.$store.state.propertiesModule.properties  

        let finalLabel = this.$t('PROPERTIES.LINK_TO_CONDO')
        retrievedData.forEach(element => {
         
          if(parseInt(element.id) == parseInt(this.form.condominium_id))
            finalLabel = element.title
        });
        return finalLabel
      }
    },
    methods: {
      input: function(e){
        this.$emit('condominium-input-changed', e)
      }
    }
  }
</script>
