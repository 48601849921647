<template>
    <file-pond
            name="filepond"
            class="filepond cover"
            ref="pondUploadPicture"
            :label-idle="placesToVisit ? $t('POND_DRAG.PLACESTOVISIT') : $t('POND_DRAG.DESCRIPTION_COVER')"
            allow-file-type-validation="true"
            allow-multiple=false
            :instantUpload="instantUploadOption"
            accepted-file-types="image/png, image/jpeg"
            allowImagePreview=true
            allowImageCrop=true
            allowImageEdit=true
            allowImageResize=true
            allowImageTransform=true
            allowReplace=true
            allowImageExifOrientation=true
            checkValidity=true
            maxFiles=1
            stylePanelLayout="compact square"
            styleLoadIndicatorPosition="center bottom"
            styleProgressIndicatorPosition="right bottom"
            styleButtonProcessItemPosition="right bottom"
            styleButtonRemoveItemPosition="left bottom"
            :files="myFiles"
            :onaddfile="addFile"
    />
</template>

<script>
  // Import Vue FilePond
  import vueFilePond from 'vue-filepond'

  // Import FilePond styles
  import 'filepond/dist/filepond.min.css'
  import '@/assets/scss/plugins/file.pond.css'

  // Import image preview plugin styles
  import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css'

  // Import image preview and file type validation plugins
  import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type'
  import FilePondPluginImagePreview from 'filepond-plugin-image-preview'

  // Create component
  const FilePond = vueFilePond(
    FilePondPluginFileValidateType,
    FilePondPluginImagePreview
  )

  export default {
    name: 'Avatar',

    components: {
      FilePond
    },
    props:{
      instantUploadOption:{
        type: Boolean,
        default: false
      },
      placesToVisit:{
        type:Boolean,
        default: false
      }
    },

    methods: {
      addFile() {
        let e = this.$refs.pondUploadPicture.getFiles()[0].file;
        this.$emit('input', e)
      }
    },

    data: function() {
      return {
        myFiles: []
      }
    }
  }
</script>

