export default [
    {
      path: '/services',
      name: 'Services',
      component: () => import('@/pages/Services'),
      meta: {
        layout: 'main',
        requiresAuth: true,
      },
      children: [
        {
          path: '/services/description',
          name: 'ServicesDescription',
          meta: {
            title: 'SERVICES DESCRIPTION',
            subtitle: 'Service description appearing at the Front Office',
            fluid: true
          },
          component: () =>
            import(/* webpackChunkName: "home" */ '@/pages/services/ServiceDescription'),
        },
        {
          path: '/services/listings',
          name: 'ServicesListings',
          meta: {
            title: 'LIST OF SERVICES',
            subtitle: 'All services appearing at the Front Office',
            fluid: true
          },
          component: () =>
            import(/* webpackChunkName: "home" */ '@/pages/services/Listings')
        },
      ]
    },
    {
      path: '/services/edit/:id',
      name: 'editService',
      meta: {
        layout: 'main',
        title: 'SAVE A SERVICE',
        subtitle: 'Form to update a registered service',
        fluid: false
      },
      component: () =>
        import(/* webpackChunkName: "home" */ '@/pages/services/SetService')
    },
    {
      path: '/services/new',
      name: 'newService',
      meta: {
        layout: 'main',
        title: 'ADD NEW SERVICE',
        subtitle: 'Form to add new service to your platform',
        fluid: false
      },
      component: () =>
        import(/* webpackChunkName: "home" */ '@/pages/services/SetService')
    }
]