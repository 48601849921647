<template>
     <div class="quick__option">
        <v-btn to="/districts/add" depressed color="secondary" class="px-6">
            New District
        </v-btn>
      </div>
</template>

<script>
  export default {
    name: 'NewDistrictBtn'
  }
</script>