// app
export const SET_APP_VERSION = 'SET_APP_VERSION'
// locale
export const SET_LOCALE = 'SET_LOCALE'
// error
export const SHOW_ERROR = 'SHOW_ERROR'
export const ERROR = 'ERROR'
// success
export const SHOW_SUCCESS = 'SHOW_SUCCESS'
export const SUCCESS = 'SUCCESS'
// success
export const SHOW_INFO = 'SHOW_INFO'
export const INFO = 'INFO'
// loading
export const SHOW_LOADING = 'SHOW_LOADING'
// auth
export const IS_LOGGED_IN = 'IS_LOGGED_IN'
export const SAVE_TOKEN = 'SAVE_TOKEN'
export const SAVE_USER = 'SAVE_USER'
export const LOGOUT = 'LOGOUT'
export const EMAIL_VERIFIED = 'EMAIL_VERIFIED'
export const RESET_EMAIL_SENT = 'RESET_EMAIL_SENT'
export const SHOW_CHANGE_PASSWORD_INPUTS = 'SHOW_CHANGE_PASSWORD_INPUTS'
// profile
export const FILL_PROFILE = 'FILL_PROFILE'
export const ADD_PROFILE_DATA = 'ADD_PROFILE_DATA'
// cities
export const FILL_ALL_CITIES = 'FILL_ALL_CITIES'
// Admin - Cities
export const CITIES = 'CITIES'
export const TOTAL_CITIES = 'TOTAL_CITIES'
// Admin - Users
export const USER = 'USER'
export const USERS = 'USERS'
export const TOTAL_USERS = 'TOTAL_USERS'
// Admin - Types
export const KEYS = 'KEYS'
export const KEY = 'KEY'
export const TOTAL_KEYS = 'TOTAL_KEYS'
export const KEY_TYPES = 'KEY_TYPES'
export const KEY_FEATURES = 'KEY_FEATURES'
export const KEY_LISTINGS = 'KEY_LISTINGS'
export const KEY_LIFESTYLES = 'KEY_LIFESTYLES'
export const KEY_MASTER = 'KEY_MASTER'
// Admin - Agents
export const AGENT = 'AGENT'
export const AGENTS = 'AGENTS'
export const TOTAL_AGENTS = 'TOTAL_AGENTS'
// Admin - Districts
export const DISTRICT = 'DISTRICT'
export const DISTRICTS = 'DISTRICTS'
export const TOTAL_DISTRICTS = 'TOTAL_DISTRICTS'
// Admin - Frontend
export const FRONTEND = 'FRONTEND'
// Admin - Properties
export const PROPERTIES = 'PROPERTIES'
export const PROPERTY_BLOCK = 'PROPERTY_BLOCK'
// Admin - Condominiums
export const CONDOMINIUMS = 'CONDOMINIUMS'
export const CONDOMINIUM_BLOCK = 'CONDOMINIUM_BLOCK'
// Admin - Property Tags
export const TAGS = 'TAGS'
// Admin - Services
export const SERVICES = 'SERVICES'
export const SERVICE = 'SERVICE'
export const TOTAL_SERVICES = 'TOTAL_SERVICES'
export const SERVICES_DESCRIPTION = 'SERVICES_DESCRIPTION'
// Admin - Inbox
export const INBOX = 'INBOX'
export const TOTAL_INBOX = 'TOTAL_INBOX'
// Admin - Statistics
export const STATISTICS = 'STATISTICS'
// Admin - Who We Are
export const WHO_WE_ARE = 'WHO_WE_ARE'
// Imovirtual
export const IMOVIRTUAL_CODE = "IMOVIRTUAL_CODE"
export const EXPORTER_LOG = "EXPORTER_LOG"
export const CHECK_INSERTION = "CHECK_INSERTION"
export const INSERTION_LOG = "INSERTION_LOG"
// CONTACTS
export const CSV_CONTACTS = "CSV_CONTACTS"
export const CSV_CONTACTS_LINK = "CSV_CONTACTS_LINK"
