<template>
    <div>
        <v-progress-circular v-if="dataLoading"
                             indeterminate
                             color="primary"
        ></v-progress-circular>

        <!-- Features -->
        <select-master-chips @input="$emit('on-blur-event')" :selectedValues="form" class="mb-7" :chips="items"></select-master-chips>
        
        <!-- Working but not updated. We changed to the above schema -->
        <input-checkbox-multiple
                v-if="false"
                :items="items"
                custom-class="mt-0 no-messages"
                :status="0"
                v-model="formData"
                :fill="form"
                @input="$emit('on-blur-event')"
        ></input-checkbox-multiple>
        <!-- end -->
    </div>
</template>

<script>
  import { buildPayloadPagination } from '@/utils/utils'
  import mixin from '@/models/keys/KeyModel'

  export default {
    name: 'Features',

    mixins: [mixin],

    data: () => ({
      dataLoading: true,
      pagination: {},
      target: 'features',
      formData: []
    }),

    props: {
      form: {}
    },

    methods: {
      buildSearch() {
        return this.search
          ? { query: this.search, fields: this.fieldsToSearch.join(',') }
          : {}
      }
    },

    watch: {
      form: {
        handler: function(val) {
          this.formData = val
        },
        deep: true,
        immediate: true
      },
      formData: {
        handler: function(val) {
          this.$emit('get-features', val)
        },
        deep: true
      },
      pagination: {
        async handler() {
          try {
            this.dataLoading = true
            await this.getKeyItems(
              buildPayloadPagination({}, this.buildSearch(), this.target)
            )
            this.dataLoading = false
          } catch (error) {
            this.dataLoading = false
          }
        },
        deep: true,
        immediate: true
      }
    },

    computed: {
      items() {
        return this.$store.state.keysModule.key.features.data
      }
    }
  }
</script>