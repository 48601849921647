<template>
    <div class="v-files-list">
        <v-row>
            <v-col
                    v-for="(file, index) in items"
                    :key="index"
                    class="d-flex child-flex"
                    :cols="file.cover==1 ? 16 : 4"
                    :class="file.cover==1 ? 'v-files-list-img-cover col-12' : 'd-flex child-flex'"
            >
                <v-img
                    
                        :src="$mediaFile(location, ID , file.file)"
                        :lazy-src="$mediaFile(location, ID, file.file)"
                        aspect-ratio="1"
                        class="grey darken-2 rounded"
                >
                    <template v-slot:default>
                        <div class="v-files-list-info">
                            <div class="header">
                                <!-- Information -->
                                <div class="pa-3 d-flex justify-space-between">
                                    <div>
                                        <p class="text-left white--text pb-0 mb-0"
                                           :class="file.cover==1 ? 'body-2' : 'caption'">{{ file.file }}</p>
                                        <p class="text-left" :class="file.cover ? 'body-2' : 'caption'">
                                            <small class="grey--text darken-2">{{ file.extension }}</small>
                                        </p>
                                    </div>
                                    <div v-on:click="makePhotoAsCover(location, file.file, file.id)">
                                        <v-chip v-if="file.cover == 1" color="secondary" label>
                                            Cover
                                        </v-chip>
                                        <v-tooltip v-if="file.cover == 0" bottom color="gray lighten-4">
                                            <template v-slot:activator="{ on, attrs }">
                                                <i
                                                        class="zwicon-camera-alt2 primary--text"
                                                        v-bind="attrs"
                                                        v-on="on"
                                                >
                                                </i>
                                            </template>
                                            <span class="caption">Make this photo as cover</span>
                                        </v-tooltip>
                                    </div>
                                </div>
                                <!-- end -->
                                <!-- Overlay -->
                                <ImageOverlay dir="header"/>
                                <!-- end -->
                            </div>
                            <div class="footer pa-5">
                                <!-- Actions -->
                                <v-btn @click="launchDialog(true, location, file.file, file.id)" class="edit pa-0 mr-1">
                                    <i class="zwicon-pencil"></i>
                                </v-btn>
                                <v-btn class="remove pa-0 ml-1" @click="removeImage(location, file.file, file.id)">
                                    <i class="zwicon-trash"></i>
                                </v-btn>
                                <!-- end -->
                            </div>
                        </div>
                    </template>
                    <template v-slot:placeholder>
                        <v-row
                                class="fill-height ma-0"
                                align="center"
                                justify="center"
                        >
                            <v-progress-circular
                                    indeterminate
                                    color="grey lighten-5"
                            ></v-progress-circular>
                        </v-row>
                    </template>
                </v-img>
            </v-col>
        </v-row>

        <ImageEditor @generated-file="saveFileGenerated" v-model="dialogStatus" :image-data="selectedImage"></ImageEditor>

    </div>
</template>

<script>
  import ImageOverlay from '@/components/media/ImageOverlay'
  import '@/plugins/media'

  export default {
    name: 'FilesList',

    components: {
      ImageOverlay
    },
    props: ['files', 'ID', 'location'],

    data: () => ({
      dialogStatus: false,
      selectedImage: null,
      imageName: null,
      mediaID: null
    }),
       created() {
    },

    methods: {
      launchDialog(mode, path, file, media_id) {
        this.dialogStatus = mode
        this.imageName = file
        this.mediaID = media_id
        this.selectedImage = `${process.env.VUE_APP_MEDIA_URL}/` + path + `/` + this.$route.params.slug +`/`+file;
      },
      saveFileGenerated(file){
        this.$emit('generated-file', file, this.ID, this.mediaID, this.imageName)  
      },
      makePhotoAsCover(location, file, media_id){
        this.$emit('change-cover', file, this.ID, media_id, this.imageName) 
      },
      removeImage(location, fileName, media_id){
        this.$emit('remove-image', location, fileName, media_id) 
      }
    },
    computed: {
        items() {
            let organizedList = []
            let myActualFiles = this.files
            if(myActualFiles == null)
                return null
            
            if(!Array.isArray(myActualFiles)){
                organizedList.push(myActualFiles)
                return organizedList
            }

            myActualFiles.forEach(element => {
                if(element.cover == 1)
                    organizedList.push(element)
            });
            myActualFiles.forEach(element => {
                if(element.cover == 0)
                    organizedList.push(element)
            });

            return organizedList
        }
    }

  }
</script>