<template>
  <div  ref="draggable_files_list">
      <CoverImg
        v-if="getCoverItem != null"
        :coverFile="getCoverItem"
        :ID="ID"
        :location="location"
        @remove-image="removeCoverImage"
      />
      <v-card-title class="pl-0 text-left subtitle-1 font-weight-bold">{{ $t('PROPERTIES.IMAGE_OPTIONS') }}</v-card-title>
      <v-card-subtitle class="pl-0 text-left caption mb-2 black--text">{{ $t('PROPERTIES.IMAGE_EDIT') }}
      </v-card-subtitle>
      <!-- Delete All images button -->
      <v-row>
        <v-col>
          <v-btn
            style="float: left;"
            depressed
            color="primary"
            @click="$emit('remove-all-media')"
          >
            {{$t('BUTTONS_GENERAL.DELETE_ALL')}}
            <v-icon
              right              
            >
              mdi-delete
            </v-icon>
          </v-btn>
        </v-col>
      </v-row>

    <!-- End btn delete all -->
      <Grid
        :key="reloadDiv"
        :draggable="true"
        :sortable="true"
        :items="getItemsWithoutCover"
        :cellHeight="230"
        :cellWidth="230"
        :rowCount="getScreenWidth"
        @sort="changeAction"
      
        >
        <template slot="cell" slot-scope="props"  style="transform: none;"  >
          <div @click="change" >
        <v-row> 
            <v-col
                    class="d-flex child-flex col-12"          
            >
                <v-img
                    
                        :src="$mediaFile(location, ID , props.item.file)"
                        :lazy-src="$mediaFile(location, ID, props.item.file)"
                        aspect-ratio="1"
                        class="grey darken-2 rounded"
                >
                    <template v-slot:default>
                        <div class="v-files-list-info">
                            <div class="header">
                                <!-- Information -->
                                <div class="pa-3 d-flex justify-space-between">
                                    <div>
                                        <p class="text-left white--text pb-0 mb-0"
                                           :class="'caption'">{{ props.item.file }}</p>
                                        <p class="text-left" :class="'caption'">
                                            <small class="grey--text darken-2">{{ props.item.extension }}</small>
                                        </p>
                                    </div>
                                    <div v-on:click="makePhotoAsCover(location, props.item.file, props.item.id)">
                                       <v-tooltip bottom color="gray lighten-4">
                                            <template v-slot:activator="{ on, attrs }">
                                                <i
                                                        class="zwicon-camera-alt2 primary--text"
                                                        v-bind="attrs"
                                                        v-on="on"
                                                >
                                                </i>
                                            </template>
                                            <span class="caption font-weight-bold">{{$t('PHOTO_COVER.MAKE')}}</span>
                                        </v-tooltip>
                                    </div>
                                    <div v-on:click="makePhotoAsPlant(location, props.item.file, props.item.id)">
                                       <v-tooltip bottom color="gray lighten-4">
                                            <template v-slot:activator="{ on, attrs }">
                                                <i
                                                  v-if="props.item.plant == 0"
                                                  class="zwicon-design-studio primary--text"
                                                  v-bind="attrs"
                                                  v-on="on"
                                                >
                                                </i>
                                                <i
                                                  v-else
                                                  class="zwicon-window-delete primary--text"
                                                  v-bind="attrs"
                                                  v-on="on"
                                                >
                                                </i>
                                            </template>
                                            <span v-if="props.item.plant == 0" class="caption font-weight-bold">{{$t('PHOTO_COVER.PLANT') }} </span>
                                            <span v-else class="caption font-weight-bold">{{$t('PHOTO_COVER.REMOVE_PLANT') }} </span>
                                        </v-tooltip>
                                    </div>
                                </div>
                                <!-- end -->
                                <!-- Overlay -->
                                <ImageOverlay dir="header"/>
                                <!-- end -->
                            </div>
                            <div class="footer pa-5">
                                <!-- Actions -->
                                <v-btn @click="launchDialog(true, location, props.item.file, props.item.id)" class="edit pa-0 mr-1">
                                    <i class="zwicon-pencil white--text"></i>
                                </v-btn>
                                <v-btn class="remove pa-0 ml-1" @click="removeImage(location, props.item.file, props.item.id)">
                                    <i class="zwicon-trash white--text"></i>
                                </v-btn>
                                <!-- end -->
                            </div>
                        </div>
                    </template>
                    <template v-slot:placeholder>
                        <v-row
                                class="fill-height ma-0"
                                align="center"
                                justify="center"
                        >
                            <v-progress-circular
                                    indeterminate
                                    color="grey lighten-5"
                            ></v-progress-circular>
                        </v-row>
                    </template>
                </v-img>
            </v-col>
        </v-row>
          </div>
        <ImageEditor @generated-file="saveFileGenerated" v-model="dialogStatus" :image-data="selectedImage"></ImageEditor>

    
        </template>
      </Grid>
  </div>
</template>

<script>
//https://github.com/euvl/vue-js-grid

import Grid from '@/components/media/Grids/Grid'
import CoverImg from '@/components/media/Grids/CoverImg'
import ImageOverlay from '@/components/media/ImageOverlay'
import mixin from '@/models/properties/PropertiesModel'
import '@/plugins/media'


export default {
  mixins: [mixin],
  components: {Grid, ImageOverlay, CoverImg},
  props: {
    ID: Number,
    location: String,
    items: {
      type: Array,
      default: () => []
    },
    gridWidth: {
      type: Number,
      default: -1
    },
    draggable: {
      type: Boolean,
      default: false
    },
    dragDelay: {
      type: Number,
      default: 0
    },
    sortable: {
      type: Boolean,
      default: false
    },
    center: {
      type: Boolean,
      default: false
    }
},
  data: () => ({
      lastChange: null,
      height: 0,
      rowCount: 1,
      loadGrid: 0,
      reloadDiv: 0,
      dialogStatus: false,
      selectedImage: null,
      imageName: null,
      mediaID: null,
      totalRowsLine: 3
    }),
  mounted() {
    
    this.height = this.$refs.draggable_files_list.clientHeight;
    //console.log("V:" + this.height)
    this.rowCount = (this.height/2)/100
    this.reloadDiv+=1
  },
  created(){
    // Check window resize event
    window.addEventListener("resize", this.myEventHandler);

    this.organizeTotalRowsLine()
  },
  destroyed() {
    window.removeEventListener("resize", this.myEventHandler);
  },
  watch: {
    rowCount(){
      this.reloadDiv+=1
    }
  },
  computed:{
    getScreenWidth(){
      return this.totalRowsLine
    },
    getCoverItem(){
      let returner = null
      this.items.forEach(element => {
        if(element.cover == 1)
          returner = element
      });
      
      return returner
    },
    getItemsWithoutCover(){
      let returner = []
      this.items.forEach(element => {
        if(element.cover == 0)
          returner.push(element)
      });

      return returner
    },
  },
  methods: {    
    myEventHandler(e){
      this.organizeTotalRowsLine()
    },
    organizeTotalRowsLine(){
      if(screen.width <= 1366)
        this.totalRowsLine = 3
      else
        this.totalRowsLine = 5
    },
    launchDialog(mode, path, file, media_id) {
        this.dialogStatus = mode
        this.imageName = file
        this.mediaID = media_id
        this.selectedImage = `${process.env.VUE_APP_MEDIA_URL}/` + path + `/` + this.$route.params.slug +`/`+file;
      },
      saveFileGenerated(file){
        this.$emit('generated-file', file, this.ID, this.mediaID, this.imageName)  
      },
      makePhotoAsCover(l, file, media_id){
        this.$emit('change-cover', file, this.ID, media_id, this.imageName) 
      },
      makePhotoAsPlant(l, file, media_id){
        this.$emit('change-plant', file, this.ID, media_id, this.imageName) 
      },
      removeImage(l, fileName, media_id){
        this.$emit('remove-image', l, fileName, media_id) 
      },
      removeCoverImage(location, fileName, media_id){
        this.$emit('remove-image', location, fileName, media_id) 
      },
    updateGridNumbers: function(e){
      let gridItems = e.items
      let pos = 0
      gridItems.forEach(element => {
        pos++
        document.getElementById(element.index+1).innerHTML = pos
      });
    },
    changeAction: function(e){
      this.lastChange = {
        'datetime': e.datetime,
        'items': e.items
      };
    },
    change: function () {
      this.$emit('reordered', this.lastChange.items)
    },
  },
}
</script>