<template>
    <v-speed-dial right bottom fixed class="v-app-action">
        <template v-slot:activator>
            <v-btn
                    fab
                    large
                    dark
                    depressed
                    color="secondary"
                    class="ma-5 d-block"
                    :to="$route.path + '/add'"
            >
                <i class="zwicon-plus"></i>
            </v-btn>
        </template>
    </v-speed-dial>
</template>

<script>
  export default {
    name: 'FloatAction'
  }
</script>
